#solar-app .cms-page {
    .accordion-menu {
        > ul {
            > li {
                > a {
                    margin-bottom: 12px;
                }
            }
        }
        ul {
            padding-left: 0;

            p {
                margin-left: 9px !important;
            }
        }
    }
}

.cms-page {
    &.no-breadcrumbs {
        padding: 20px 0 0;
    }

    &.__actionpanel {
        .content {
            margin: 0;
        }
    }

    h1 {
        margin: 0 0 10px;
        @include font-size(22px);
        line-height: 26px;
    }
    ._orange {
        .fa.fa-arrow-right {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' d='M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3' /%3E%3C/svg%3E%0A");        width: 1.5rem;
            height: 1.5rem;
            display: inline-block;
        }
    }
    .fa.fa-arrow-right {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        margin-bottom: -3px;
    }

    .content {
        margin: 0 0 80px;

        h2, h3, h4 {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: color(base, DefaultFontColor);
            margin: 0 0 10px;
        }

        p {
            margin: 0 0 20px;
            @include font-size(14px);
        }

        a {
            display: inline-block;
        }
        ul {
            list-style: disc outside;
            margin: 0 0 20px 28px;

            li {
                @include font-size(14px);
            }

            &.list__bullet {
                list-style: none;
                margin: 0 0 20px 10px;

                li {
                    position: relative;
                    padding-left: 18px;

                    &:before {
                        position: absolute;
                        left: 0;
                        content: "•";
                        color: color(base, Primary);
                    }
                }
            }

            &.list__check {
                list-style: none;
                margin: 0 0 20px 10px;

                li {
                    position: relative;
                    padding-left: 18px;

                }
            }
        }

        ol {
            list-style: decimal outside;
            margin: 0 0 20px 28px;

            li {
                @include font-size(14px);
            }
        }

        ._content {
            background: color(Gray, Gray-200);
            padding: 20px;
        }

        .button {
            height: 36px !important;
            line-height: 34px !important;
            @include default-button;

            &._green {
                @include font-size(16px);
            }

        }

        table {
            th {
                text-align: left;
            }
        }
    }

    .cms__menu {
        padding: 0;
        max-width: 90%;
        @include breakpoint(small only) {
            max-width: 100%;
            margin: 2rem 0;
        }
        ul li {
            border-bottom: .1rem solid color(Gray, Gray-100);
            line-height: 4rem;
            display: none;
            a {
                display: flex;
                line-height: 4rem;
                color: color(Gray, Gray-600);
                @include breakpoint(medium) {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            &.title {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill="%23ec660d" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
                background-position: right 1rem center;
                background-repeat: no-repeat;
                cursor: pointer;
                font-weight: 700;
                font-size: 1.4rem;
                display: block;
                &:hover {
                    text-decoration: underline;
                }
                &.--active {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill="%23ec660d" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>');
                    background-repeat: no-repeat;
                    background-position: right 1rem center;
                }
            }
        }
    }

    &.error-page {
        h1 {
            margin: 20px 0 10px;
        }

        .std {
            strong {
                margin: 0 0 5px;
                @include font-size(14px);
            }

            ul {
                margin: 0 0 20px 0;

                li {
                    list-style: none;

                    &:before {
                        content: "-";
                        margin-right: 5px;
                    }
                }
            }

            .no-route__search {
                width: 40%;
                position: relative;
                margin: 30px 0 0;
                @include breakpoint(small only) {
                    width: 100%;
                }

                p {
                    margin: 0 0 5px;
                    font-weight: 700;
                }

                button {
                    @include default-button;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    height: 40px;
                    line-height: 38px;
                    padding: 0 30px;
                }
            }
        }

        .cat-list {
            @include breakpoint(small only) {
                margin: 0 -10px;
                padding: 5px 10px 0;
            }
            .cell.home-grid {
                -webkit-box-flex: 0;
                -ms-flex: 0 0 25%;
                flex: 0 0 25%;
                max-width: 25%;
                @include breakpoint(small only) {
                    -webkit-box-flex: 0;
                    -ms-flex: 0 0 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                }
            }
        }
    }

    .saldo__check {
        width: 60%;
        @include breakpoint(small only) {
            width: 100%;
        }
        .saldo__form {
            .input-box {
                padding: 0 0 15px;
                label {
                    @include font-size(13px);
                }
                input {
                    width: 50%;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                }
            }
        }
        .saldo__result {
            position: relative;
            .saldo__card {
                position: relative;
                span {
                    position: absolute;
                    font-weight: 700;
                    color: color(base, White);
                    padding: 20px;
                    font-size: 58px;
                    text-shadow: 0 0 10px rgba(0, 0, 0, .3);
                }
            }
        }
        .saldo__errors {
            ul {
                list-style: none;
                margin: 0;
                li {
                    span {
                        //@include messages('error');
                        @include font-size(11px);
                        padding: 5px 10px;
                        width: auto;
                        margin: 10px 0 0;
                        display: inline-block;
                        border-radius: 3px;
                    }
                }
            }
        }
    }
    .readMoreContainer {
        ._content {
            @include font-size(14px);
            overflow: hidden;
            max-height: 100px;
            position: relative;
            &._filter::after {
                content: '';
                display: block;
                height: 30px;
                width: 100%;
                position: absolute;
                background: linear-gradient(0deg, rgba(254, 254, 254, 0.8) 0%, rgba(254, 254, 254, 0.3) 100%);
                bottom: 0;
            }
            p {
                @include font-size(14px);
                text-align: left;
                line-height: 22px;
            }
        }
        ._actions {
            padding: 20px 0 10px;
            display: flex;
            justify-content: center;
            button {
                color: color(base, Primary);
                border: 1px solid color(base, Primary);
                line-height: 32px;
                border-radius: 16px;
                padding: 0 35px 0 20px;
                font-size: 13px;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(0.000000, 0.000000)' fill='%23EC660D' fill-rule='nonzero'%3E%3Cpath d='M0.292786454,0.305312993 C0.683286305,-0.085068945 1.3162866,-0.085068945 1.70678645,0.305312993 L4.99978645,3.59831299 L8.29278645,0.305312993 C8.54386092,0.0453564089 8.91566435,-0.0588996039 9.26529378,0.0326158368 C9.61492321,0.124131277 9.88796817,0.397176238 9.97948361,0.746805669 C10.0709991,1.0964351 9.96674304,1.46823852 9.70678645,1.71931299 L5.70678645,5.71931299 C5.3162866,6.10969493 4.68328631,6.10969493 4.29278645,5.71931299 L0.292786454,1.71931299 C-0.0975954846,1.32881314 -0.0975954846,0.695812845 0.292786454,0.305312993 L0.292786454,0.305312993 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 10px center;
                background-size: 11px;
                font-weight: 600;
                &:hover {
                    color: color(base, PrimaryHover);
                    border-color: color(base, PrimaryHover);
                }
                > span {
                    display: block;
                }
                &._active {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(5.006050, 3.006050) rotate(-180.000000) translate(-5.006050, -3.006050) translate(0.000000, 0.000000)' fill='%23EC660D' fill-rule='nonzero'%3E%3Cpath d='M0.292786454,0.305312993 C0.683286305,-0.085068945 1.3162866,-0.085068945 1.70678645,0.305312993 L4.99978645,3.59831299 L8.29278645,0.305312993 C8.54386092,0.0453564089 8.91566435,-0.0588996039 9.26529378,0.0326158368 C9.61492321,0.124131277 9.88796817,0.397176238 9.97948361,0.746805669 C10.0709991,1.0964351 9.96674304,1.46823852 9.70678645,1.71931299 L5.70678645,5.71931299 C5.3162866,6.10969493 4.68328631,6.10969493 4.29278645,5.71931299 L0.292786454,1.71931299 C-0.0975954846,1.32881314 -0.0975954846,0.695812845 0.292786454,0.305312993 L0.292786454,0.305312993 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
    }

}

.publitas-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 66%;
    @include breakpoint(small only) {
        padding-top: 130%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%
    }
}

.mceContentBody {
    h2, h3, h4 {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: color(base, Primary);
        margin: 0 0 10px;
    }

    p {
        margin: 0 0 20px;
        @include font-size(14px);
    }

    ul {
        list-style: disc outside;
        margin: 0 0 20px 28px;

        li {
            @include font-size(14px);
        }
    }

    ol {
        list-style: decimal outside;
        margin: 0 0 20px 28px;

        li {
            @include font-size(14px);
        }
    }
}

#review_frame iframe {
    width: 100%;
    height: 1625px;
}

.cookie__table {
    width: 100%;
    font-size: 12px;
    border-collapse: collapse;
}

.cookie__table, .cookie__table th, .cookie__table td {
    border: 1px solid #eaeaea;
}

.cookie__table tr th {
    background: #e4e4e4;
    padding: 4px 8px;
    text-align: left;
}

.cookie__table tr td {
    padding: 4px 8px;
    text-align: left;
}

.cookie__table tr:nth-child(odd) td {
    background: #fbfbfb;
}

@media only screen and (max-width: 40em) {
    .cookie__table table, .cookie__table thead, .cookie__table tbody, .cookie__table th, .cookie__table td, .cookie__table tr {
        display: block;
    }
    .cookie__table th {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    .cookie__table td {
        border: none;
        position: relative;
        padding-left: 50%;
        border-bottom: 1px solid #eaeaea;
    }
    .cookie__table td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
}

.accordion-menu {
    > ul > li {
        > a {
            line-height: 1;
            text-decoration: none;
            white-space: nowrap;
            display: block;
            padding: 0.7rem 1rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19 9l-7 7-7-7' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: right center;
            &._active {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 15l7-7 7 7' /%3E%3C/svg%3E");
                + ul {
                    display: block;
                }
            }
        }
        > ul {
            display: none;
            &._active {
                display: block;
            }
        }
    }
}

.cms-page .ContentpageModule {
    a > p {
        color: color(base, DefaultFontColor);
    }
}

