.content {
    margin: 40px 0;
    ul:not(.product__usp) {
        list-style-type: disc;
        padding-left: 1.5em;
        margin: 1em 0;
        line-height: 2.2rem;
    }

    .no-route__search {
        width: 40%;
        position: relative;
        margin: 30px 0 0;
        @include breakpoint(small only) {
            width: 100%;
        }

        form {
            padding: 0;
            margin: 0;
        }

        p {
            margin: 0 0 5px;
            font-weight: 700;
        }

        button {
            @include default-button;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 40px;
            line-height: 38px;
            padding: 0 30px;
        }
    }
}

.contact-with-expert,
.thats-why-expert {
    background: color(Gray, Gray-100);
    padding: 20px;

    ul {
        list-style-type: none;
        margin: 0;
    }
}

.contact-with-expert {
    margin-bottom: 120px;

    @include breakpoint(small only) {
        margin: 20px 0 120px 0;
    }
    img {
        max-width: calc(100% + 40px);
        width: calc(100% + 40px);
        display: block;
        margin: 20px -20px -120px -20px;
    }
}
