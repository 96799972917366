.price__information {
    display: flex;
    flex-flow: column;
    position: relative;
    gap: 1.2rem;

    .product__usp {
        @include list("checked-icon");
    }

    .product__energy_label-container {
        .product__energy-label {
            display: inline-block;
        }
    }

    .product__price-block {
        .--old {
            position: relative;
            color: color(Gray, Gray-600);
            font-size: 18px;
            &::before {
                content: '';
                height: 1px;
                top: 50%;
                left: 0;
                width: 100%;
                background: color(Gray, Gray-600);
                position: absolute;
            }
        }
        span {
            display: block;
            color: color(base, DefaultFontColor);
            font-weight: 700;
            font-size: 25px;
            &._old-price {
                display: inline-block;
                position: relative;
                font-size: 17px;
                color: color(Gray, Gray-400);
                font-weight: normal;
                &::before {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 50%;
                    right: 0;
                    border-top: 1px solid;
                    border-color: inherit;
                    transform: rotate(-5deg);
                }
            }
        }

        .klarna__message {
            display: flex;
            align-items: center;
            gap: 5px;
            margin: 10px 0 0;
            p {
                color: color(Gray, Gray-600);
            }
            .tooltip {
                @include tooltip;
            }
        }

        .extra__fee {
            font-size: 13px;
            color: color(Gray, Gray-600);
        }
    }

    .product__shippingnote {
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 1.8rem;
        line-height: 2.6rem;
        color: color(Gray, Gray-600);

        a {
            font-size: 1.2rem !important;
        }

        &.--free {
            padding: 0 0 0 2.2rem;
            color: color(base, Green);
            font-weight: 700;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='%2338B000'%3E%3Cpath fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z' clip-rule='evenodd' /%3E%3C/svg%3E");
        }
    }
}
