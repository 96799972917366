.homepage__info-block--block-wrapper {
    .block__container {
        display: flex;
        gap: 3rem;
        grid-template-columns: repeat(var(--info-mobile-rows), 1fr);
        @include breakpoint(small only) {
            overflow-x: scroll;
            gap: 1rem;
            padding-bottom: 10px;
        }

        @include breakpoint(medium) {
            grid-template-columns: repeat(var(--info-desktop-rows), 1fr);
        }

        > div {
            @include border-radius();
            background: color(Gray, Gray-100);
            padding: 0 3rem;
            display: flex;
            flex: 1;
            gap: 2rem;
            align-items: center;
            justify-content: space-between;
            @include breakpoint(small only) {
                flex: 0 0 90vw;
                max-width: 350px;
            }

            .--content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1rem;
                height: 100%;
                padding: 2rem 0;
                flex: 0 0 60%;

                p {
                    flex: 1;
                }
            }
        }
    }
}
