.__form-mailing-list {
    form .field__container {
        padding: 0;
    }

    position: relative;

    input {
        border: none;
        padding-right: 5.5rem;
    }

    &._bordered {
        input {
            border: .1rem solid color(Gray, Gray-200);
        }
    }

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: calc(4rem + 1px);
        height: 4rem;
        border-left: .1rem solid color(Gray, Gray-200);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: .8rem;
        }
    }
}
