.product__energy_label-container {
    .product__energy-label {
        //img {
        //    cursor: pointer;
        //}
        .energy__label {
            cursor: pointer;
            display: inline-block;
            border-radius: 0 4px 4px 0;
            font-weight: 800;
            min-width: 40px;
            position: relative;
            left: 1rem;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #009640;
            color: color(base, White);
            &::before {
                content: '';
                display: block;
                position: absolute;
                right: 100%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 15px 10px 15px 0;
                border-color: transparent #009640 transparent transparent;
            }
            &._b {
                background: #52ae32;
                &::before {
                    border-color: transparent #52ae32 transparent transparent;
                }
            }
            &._c {
                background: #c8d400;
                &::before {
                    border-color: transparent #c8d400 transparent transparent;
                }
            }
            &._d {
                background: #ffed00;
                &::before {
                    border-color: transparent #ffed00 transparent transparent;
                }
            }
            &._e {
                background: #fbba00;
                &::before {
                    border-color: transparent #fbba00 transparent transparent;
                }
            }
            &._f {
                background: #ec6608;
                &::before {
                    border-color: transparent #ec6608 transparent transparent;
                }
            }
            &._g {
                background: #e30613;
                &::before {
                    border-color: transparent #e30613 transparent transparent;
                }
            }
            &._ap {
                background: #7bae4a;
                &::before {
                    border-color: transparent #7bae4a transparent transparent;
                }
            }
            &._app {
                background: #63aa5a;
                &::before {
                    border-color: transparent #63aa5a transparent transparent;
                }
            }
            &._appp {
                background: #4f9c45;
                &::before {
                    border-color: transparent #4f9c45 transparent transparent;
                }
            }
        }
    }
    .modal__overlay {
        .modal__wrapper {
            @include breakpoint(medium) {
                max-width: 50vw;
            }
            .modal-content {
                img {
                    max-height: 70vh;
                }
            }
        }
    }
}
