@import 'product/price-info',
'product/media',
'product/more-info',
'product/personal-advice',
'product/product-usps',
'product/related-products',
'product/reviews',
'product/specs',
'product/back-to-top',
'product/add-to-cart',
'product/top',
'product/action-panel';

.catalog-product-page_body {
    padding-bottom: 80px;
}

.share_this_product_modal input[type="text"] {
    border-radius: 3px 0 0 3px;
}

.product__view-availability-container {
    width: 70%;
    @include breakpoint(medium down) {
        width: 100%;
    }
}

.product__view {
    .product__family {
        margin: 1.2rem 0;

        p {
            color: color(base, DefaultFontColor);
            @include font-size(12px);
            font-weight: 600;
            line-height: 24px;
        }

        select {
            max-width: 70%;
        }

        ul {
            display: flex;

            li {
                margin-bottom: 6px;
                margin-right: 6px;
                font-size: 14px;

                a {
                    min-height: 50px;
                    line-height: 22px;
                    display: flex;
                    align-items: center;
                    padding: 8px 10px;
                    border-radius: 3px;
                    font-weight: 400;
                    border: 1px solid color(Gray, Gray-300);
                    font-size: 14px;
                    color: color(base, Black);

                    &.active,
                    &:hover {
                        background: lighten(color(base, Secondary), 35%);
                        border: 1px solid color(base, Secondary);
                    }
                }
            }
        }
    }

    .product__description,
    .product__specs,
    .product__reviews-container {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include border(0 0 0.1rem, color(base, Primary));
        margin: 20px 0;

        ._content {
            max-height: 290px;
            overflow: hidden;

            &.--open {
                max-height: none;
            }
        }

        ._actions {
            text-align: center;
            padding: 10px 0 20px 0;

            button {
                > span {
                    display: block;
                    &.--less {
                        display: none;
                    }
                }
                &._active {
                    > span {
                        &.--more {
                            display: none;
                        }
                        &.--less {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    ._header .youreko-placeholder {
        margin: 1rem 0 0;
        width: 100%;
    }

    .not__available {
        margin: 2rem 0;
    }
}

.product__view {
    .product__description,
    .product__specs {
        padding-bottom: 20px;

        &:has(._actions.--show) {
            padding-bottom: 0;
        }

        ._actions {
            display: none;

            &.--show {
                display: block;
            }
        }
    }
}

#offCanvas-description .offcanvas-content,
.product__description,
.seo__block--block-wrapper {
    ul._specs {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0;

        li {
            line-height: 20px;
            display: flex;
            align-items: flex-start;
            gap: 10px;
            color: color(Gray, Gray-600);

            &._plus {
                &::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    border-radius: 20px;
                    display: inline-block;
                    background-color: color(base, Green);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M12 4.5v15m7.5-7.5h-15' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-position: center;
                    margin-top: 1px;
                }
            }

            &._min {
                &::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    min-width: 18px;
                    border-radius: 20px;
                    display: inline-block;
                    background-color: color(Gray, Gray-300);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 12h-15' /%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-size: 80%;
                    background-position: center;
                    margin-top: 1px;
                }
            }
        }
    }

    h2 {
        margin-top: 20px;
    }
}
