.flex-wrapper {
    display: flex;
    gap: 1rem;
    .button--wishlist {
        margin: 1rem 0;
    }
}
.button--wishlist {
    svg {
        margin-right: 0;
    }
}

.catalog-category-page.wishlist {
    .global__content {
        margin-top: 40px;
    }
}

.header__wishlist {
    &.--has-amount {
        position: relative;
        .--amount {
            align-items: center;
            background: #1d1d1f;
            border: 2px solid #ec660d;
            border-radius: 50%;
            color: #fff;
            display: flex
        ;
            font-size: 10px;
            font-weight: 700;
            height: 20px;
            justify-content: center;
            position: absolute;
            right: -10px;
            top: -4px;
            width: 20px;
        }
    }
    svg {
        @include breakpoint(small only) {
            width: 20px;
            height: 20px;
        }
    }
}
.product__tile {
    .product_footer_right {
        .button--wishlist {
            &.--button.--big {
                padding: 0;
            }
        }
    }
}
