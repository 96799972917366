.print__page {
    .holder {
        table {
            tr {
                border: none;
                td {
                    padding: 10px 50px;
                    img {
                        display: inline;
                    }
                    .print__name {
                        font-weight: 700;
                        color: color(base, Primary);;
                        font-size: 26px;
                        text-transform: uppercase;
                        line-height: 32px;
                    }
                    .print__sub {
                        @include font-size(16px);
                    }
                    .print__old-price {
                        color: color(Gray, Gray-400);
                        text-decoration: line-through;
                        @include font-size(18px);
                        line-height: 22px;
                    }
                    .print__special-price, .print__price {
                        @include font-size(24px);
                        line-height: 28px;
                    }
                    .print__store {
                        .title {
                            color: color(base, Primary);;
                            font-weight: 700;
                        }
                        p {
                            margin: 5px 0;
                        }
                    }
                    &._center {
                        text-align: center;
                    }
                }
            }
        }
    }
}
