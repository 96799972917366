.catalog__category-list {
    position: relative;

    > .--loading {
        z-index: 4;
        position: absolute;
        left: -1rem;
        right: -1rem;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;

        .--spinner {
            @include svg-loader();
            margin-top: 10rem;
        }
    }

    .grid-x.grid-padding-x {
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        .cell {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            display: flex;
        }
    }

    .product__tile {
        margin-bottom: 1rem;
        @include product-tile();
    }
}
