.publitas-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 66%;
    @include breakpoint(small only) {
        padding-top: 130%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%
    }
}
