.add-to-cart__buttons-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 70%;
    padding: 10px 0;

    @include breakpoint(medium down) {
        width: 100%;
    }
}

.add-to-cart-button-container {
    display: flex;
    position: relative;
    > button {
        flex: 1;
    }

    > .--error {
        position: absolute;
        top: 100%;
        width: 100%;
        min-width: 150px;
        z-index: 1;
    }
}
