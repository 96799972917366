._header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
    margin-bottom: 20px;
    @include breakpoint(small only) {
        margin-bottom: 10px;
    }

    h1 {
        margin: 0;
        flex: 0 0 100%;
    }

    .rating_count_wrapper {
        cursor: pointer;
        flex: 0 0 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        &:hover {
            text-decoration: underline;
        }
        .product_rating {
            width: 84px;
            height: 15px;
            background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nODQnIGhlaWdodD0nMTYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTggMS4xM2wyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw4IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMOCAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDQyIDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNDIgMS4xM3ptLTE3IDBsMi4wMiA0LjA5IDQuNTE0LjY1Ny0zLjI2NyAzLjE4NS43NzEgNC40OTZMMjUgMTEuNDM1bC00LjAzOCAyLjEyMy43NzEtNC40OTYtMy4yNjctMy4xODUgNC41MTUtLjY1NkwyNSAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDU5IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNTkgMS4xM3ptMTcgMGwyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw3NiAxMS40MzVsLTQuMDM4IDIuMTIzLjc3MS00LjQ5Ni0zLjI2Ny0zLjE4NSA0LjUxNS0uNjU2TDc2IDEuMTN6JyBzdHJva2U9JyNGRkM0MDAnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
            display: block;
            .product_rating_fill {
                height: 15px;
                background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nODQnIGhlaWdodD0nMTYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTggMS4xM2wyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw4IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMOCAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDQyIDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNDIgMS4xM3ptLTE3IDBsMi4wMiA0LjA5IDQuNTE0LjY1Ny0zLjI2NyAzLjE4NS43NzEgNC40OTZMMjUgMTEuNDM1bC00LjAzOCAyLjEyMy43NzEtNC40OTYtMy4yNjctMy4xODUgNC41MTUtLjY1NkwyNSAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDU5IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNTkgMS4xM3ptMTcgMGwyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw3NiAxMS40MzVsLTQuMDM4IDIuMTIzLjc3MS00LjQ5Ni0zLjI2Ny0zLjE4NSA0LjUxNS0uNjU2TDc2IDEuMTN6JyBmaWxsPScjRkZDNDAwJyBzdHJva2U9JyNGRkM0MDAnIGZpbGwtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
                display: block;
            }
        }

    }
    ._actions {
        display: flex;
        align-items: center;
        a {
            color: color(base, DefaultFontColor);
            &:hover {
                text-decoration: underline;
            }
        }
        .share_this_product {
            border-right: 2px solid color(Gray, Gray-300);
            padding-right: 1rem;
            margin-right: 1rem;
            display: flex;
            align-items: center;
            cursor: pointer;
            @include breakpoint(small only) {
                border: 0;
            }
            &:hover {
                text-decoration: underline;
            }
            &::before {
                content: '';
                width: 15px;
                height: 15px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%231d1d1f' d='M720-80q-50 0-85-35t-35-85q0-7 1-14.5t3-13.5L322-392q-17 15-38 23.5t-44 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q23 0 44 8.5t38 23.5l282-164q-2-6-3-13.5t-1-14.5q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-23 0-44-8.5T638-672L356-508q2 6 3 13.5t1 14.5q0 7-1 14.5t-3 13.5l282 164q17-15 38-23.5t44-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                display: inline-block;
                background-position: center;
                background-size: contain;
                margin-right: 6px;
            }
        }

        .share_this_product_modal {
            ._form {
                display: flex;
                margin-top: 1rem;
                .copy_product_url {
                    height: 40px;
                    flex: 0 0 40px;
                    border: 1px solid color(Gray, Gray-300);
                    border-left: 0;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3E%3Cpath d='M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z' /%3E%3Cpath d='M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z' /%3E%3C/svg%3E%0A");
                    background-position: center;
                    background-size: 50%;
                    background-repeat: no-repeat;
                    border-radius: 0 3px 3px 0;
                    transition: background .2s ease-in-out;
                    &.--copied {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' class='w-6 h-6'%3E%3Cpath fill='%2338b000' fill-rule='evenodd' d='M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z' clip-rule='evenodd' /%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }
}
