.more__information {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;

    ul {
        li {
            a {
                line-height: 2.2rem;
                color: color(base, DefaultFontColor);
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    width: 20px;
                    height: 20px;
                    display: inline-block;
                    padding-left: 20px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: 16px;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
