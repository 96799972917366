.catalog__media-container {
    .product__media {
        position: relative;

        .product__media-gallery {
            @include breakpoint(medium) {
                @include border-radius();
                position: relative;
                border: .1rem solid color(Gray, Gray-150);
            }

            .gallery__image-container {
                //@include background-image-logo();
                aspect-ratio: 1;
                background-size: 30%;

                picture {
                    display: flex;
                    justify-content: center;
                    aspect-ratio: 1;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: scale-down;
                    }
                }
            }

            .video-wrapper {
                margin: auto;
                .video-container {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }

            .blaze-pagination {
                margin: .8rem 0 0;
            }
        }

        .product__media-thumbnails {
            margin: $grid__padding 0 0;

            @include breakpoint(medium) {
                .blaze-slider {
                    --slides-to-show: 6;
                }
            }

            .blaze-track {
                button {
                    @include transition();
                    @include border-radius(.2rem);
                    //@include background-image-logo();
                    padding: .6rem;
                    display: flex;
                    overflow: hidden;
                    border: .1rem solid color(Gray, Gray-150);
                    aspect-ratio: 1;

                    picture {
                        display: flex;

                        img {
                            max-height: 8rem;
                            object-fit: scale-down;
                        }
                    }

                    &.--active {
                        border-color: color(base, Black);
                    }

                    @include hover() {
                        border-color: color(base, Black);
                    }
                }
            }

            .video__thumbnail {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: color(base, Black);
                width: 100%;

                > span {
                    display: block;
                    position: absolute;

                    &:first-of-type {
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        background-color: rgba(0, 0, 0, .4);
                        z-index: 1;
                    }

                    &:last-of-type {
                        width: 4.4rem;
                        height: 4.4rem;
                        background-image: url("data:image/svg+xml,%3Csvg width='33' height='30' viewBox='0 0 33 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M31.9481 16.4573C32.5954 15.2662 32.5954 13.8284 31.9481 12.6374L26.2164 2.09034C25.5164 0.802289 24.1679 0.000337845 22.7019 0.000308272L10.446 6.1036e-05C8.97998 3.14621e-05 7.63139 0.801991 6.93138 2.0901L1.19969 12.637C0.552416 13.8281 0.552415 15.2659 1.19969 16.4569L6.93143 27.004C7.63142 28.292 8.97992 29.094 10.4459 29.094L22.7018 29.0943C24.1678 29.0943 25.5164 28.2924 26.2164 27.0042L31.9481 16.4573ZM13.095 7.19101C12.4189 6.81962 11.5923 7.30819 11.5923 8.07982V19.5225C11.5923 19.6987 11.6382 19.8719 11.7255 20.025C11.8128 20.1781 11.9385 20.3058 12.0901 20.3955C12.2418 20.4852 12.4143 20.5339 12.5905 20.5366C12.7667 20.5394 12.9406 20.4962 13.095 20.4113L23.4974 14.69C23.6567 14.6025 23.7895 14.4739 23.882 14.3175C23.9745 14.1612 24.0233 13.9828 24.0233 13.8012C24.0233 13.6195 23.9745 13.4412 23.882 13.2848C23.7895 13.1285 23.6567 12.9998 23.4974 12.9124L13.095 7.19101Z' fill='#{color(base, Primary)}'/%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: center;
                        z-index: 2;
                    }
                }
            }
        }
    }
}
