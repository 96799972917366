.product__row {
    .catalog__category-list {
        > ul {
            > li.cell {
                width: 100%;
                @include breakpoint(medium) {
                    width: 20%;
                }
            }
        }
    }
}
