.catalog-product__bundles {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .--bundle {
        @include border-radius();
        border: .2rem solid color(base, Primary);
        position: relative;
        padding: 1rem;

        > span {
            @include border-radius();
            background-color: color(base, Primary);
            color: color(base, White);
            position: absolute;
            top: 1rem;
            right: 1rem;
            font-size: 1.2rem;
            font-weight: 600;
            padding: 0 1rem;
            line-height: 2.2rem;
            z-index: 1;
        }

        .--images {
            display: flex;
            gap: 1rem;
            align-items: center;

            .image__holder {
                display: flex;
                align-items: center;
                gap: 1rem;
                position: relative;

                > span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 2.4rem;
                    height: 2.4rem;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: color(base, White);
                    background-color: color(base, Primary);
                }

                &:not(:last-child)::after {
                    content: '+';
                    font-size: 2rem;
                    color: color(base, Primary);
                    font-weight: 700;
                }
            }
        }

        .--names {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin-top: 1rem;

            > span {
                font-size: 1.5rem;
                line-height: 2.3rem;
                color: color(Gray, Gray-600);
            }
        }

        .--prices {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 2rem;

            .--discount {
                flex: 1;
                font-size: 1.5rem;
                line-height: 2.3rem;
                color: color(Gray, Gray-600);

                strong {
                    color: color(base, DefaultFontColor);
                }
            }

            .--price-holder {
                display: flex;
                flex-direction: column;

                .--price {
                    font-size: 2rem;
                    font-weight: 700;

                    &.--old {
                        text-decoration: line-through;
                        color: color(Gray, Gray-400);
                        font-weight: 400;
                        font-size: 1.4rem;
                    }
                }
            }

            section > button {
                flex: 0 0 4rem;
                width: 4rem;
                height: 4rem;
                padding: 0;
                display: flex;
                justify-content: center;

                &::before {
                    margin: 0;
                }
            }
        }
    }
}
