.checkout-success {
    &.checkout-account-form {
        background: color(base, White);
        margin-top: 1.2rem;
        padding: 2rem;
        border-radius: 5px;

        .register__account {
            form {
                max-width: 48rem;
                width: 100%;

                .field__container {
                    input {
                        &[disabled] {
                            background: color(Gray, Gray-100);
                            color: color(Gray, Gray-500);
                        }
                    }
                }
            }
        }
        .register__account-success__content {
            margin: 1rem 0;
        }
    }
}

.checkout-box-container {
    .success__expectations {
        margin: 30px 0 5px;
        @include breakpoint(small only) {
            margin: 15px 0 0;
        }
        ul {
            overflow: hidden;
            li {
                margin-top: 12px;
                @include font-size(13px);
                line-height: 25px;
                position: relative;
                padding-left: 35px;
                span {
                    display: block;
                    height: 25px;
                    width: 25px;
                    line-height: 23px;
                    text-align: center;
                    background: color(Gray, Gray-100);
                    border: 1px solid color(Gray, Gray-350);
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &.active span {
                    border-color: color(base, Green);
                    background: color(base, Green);
                    color: color(base, White);
                }
            }
        }
    }
    .checkout-container-divider {
        height: 1px;
        background: color(Gray, Gray-350);
        margin: 25px -20px;
        @include breakpoint(small only) {
            margin: 20px -15px;
        }
    }

    .background-box {
        margin-top: 20px;
        @include breakpoint(small only) {
            margin: 10px 0 30px;
        }
        .box {
            background: color(Gray, Gray-100);
            padding: 15px;
            border-radius: 5px;
            @include breakpoint(medium) {
                max-width: 282px;
            }
            ul {
                li {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    line-height: 16px;
                    position: relative;
                    margin-top: 10px;

                    span.service-boxes, span.service-box {
                        width: 100%;
                    }

                    &:not(:last-of-type) {
                        .service-boxes {
                            border-bottom: 1px solid color(Gray, Gray-350);
                            padding-bottom: 1rem;
                        }
                    }
                }
            }
        }
        ul {
            &.second {
                margin-top: 15px;
                padding: 15px 0 0;
                border-top: 1px solid #d6d6d6;
            }
            &.address, &.pickup {
                margin-top: 10px;
                li {
                    line-height: 20px;
                    margin-top: 0;
                    strong {
                        @include font-size(12px);
                    }
                }
            }
            li {
                line-height: 16px;
                @include font-size(13px);
                position: relative;
                margin-top: 10px;
                span {
                    min-width: 50px;
                    &.service-boxes, &.service-box {
                        width: 100%;
                    }
                }
                &:not(:last-of-type) {
                    .service-boxes {
                        border-bottom: 1px solid #d6d6d6;
                        padding-bottom: 1rem;
                    }
                }
                &:first-child {
                    margin-top: 0;
                }
                &.grand-total {
                    font-weight: 700;
                }
                span.price {
                    position: relative;
                    right: 0;
                    top: 0;
                    &._free {
                        color: color(base, Green);
                    }
                }
            }
        }
        .free, .today, .green {
            color: color(base, Green);
            font-weight: 700;
        }
        .gray {
            color: color(Gray, Gray-700);
        }
    }
    .store-box {
        margin-top: 20px;
    }
}
.checkout-footer {
    padding: 12px 0;
    text-align: right;
}
