.header__navigation {
    background: color(Gray, Gray-100);
    overflow: hidden;

    nav {
        > ul {
            display: flex;
            margin-right: -1.5rem;
            margin-left: -1.5rem;
            flex-wrap: wrap;
            > li {
                > a,
                > span {
                    display: block;
                    @include font-size(14px);
                    color: color(base, DefaultFontColor);
                    cursor: pointer;
                    padding: 15px;
                }

                &.--special {
                    > a,
                    > span {
                        color: color(base, Primary);
                        font-weight: 700;
                    }
                }
                .--dropdown {
                    display: none;
                    position: absolute;
                    left: 0;
                    right: 0;
                    background: color(Gray, Gray-50);
                    padding: 0 0 20px;
                    box-shadow: 0 0 .2px 1px #e0e0e0;

                    &::before {
                        content: '';
                        background: color(Gray, Gray-50);
                        height: 3px;
                        width: calc(100% + 15px);
                        display: block;
                        position: absolute;
                        top: -3px;
                        left: -15px;
                    }
                    .nav-dropdown {
                        padding: 0 20px;
                        a {
                            display: inline-block;
                            padding: 0 8px;
                        }
                    }

                    ul {
                        margin: 2rem 0 0;

                        li:first-child > a:first-child {
                            //font-weight: 700;
                        }

                        li a {
                            display: block;
                            @include font-size(13px);
                            line-height: 2.3rem;
                            color: color(base, DefaultFontColor);

                            @include hover() {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                &.--active {
                    background: color(Gray, Gray-50);

                    .--dropdown {
                        display: block;
                    }
                }
            }
        }

        .navigation__group {
            &.menu-column-1 {
                .--dropdown {
                    > .grid-container {
                        > ul {
                            width: 100%;
                        }
                    }
                }
            }
            &.menu-column-2 {
                .--dropdown {
                    > .grid-container {
                        > ul {
                            width: 50%;
                        }
                    }
                }
            }
            &.menu-column-3 {
                .--dropdown {
                    > .grid-container {
                        > ul {
                            width: 33.3333333%;
                        }
                    }
                }
            }

            &.menu-column-4 {
                .--dropdown {
                    > .grid-container {
                        > ul {
                            width: 25%;
                        }
                    }
                }
            }

            &.menu-column-5 {
                .--dropdown {
                    > .grid-container {
                        > ul {
                            width: 20%;
                        }
                    }
                }
            }
            &.menu-column-6 {
                .--dropdown {
                    > .grid-container {
                        > ul {
                            width: 16.6666667%;
                        }
                    }
                }
            }
            .--dropdown {
                > .grid-container {
                    display: flex;
                    flex-flow: row wrap;
                    > ul {
                        width: 16.6666667%;

                        > .navigation__group {
                            > a {
                                font-weight: 700;
                                color: color(base, Primary);
                                font-size: 1.5rem;
                            }

                            ul {
                                margin: 0;
                                li {
                                    &.--cta-link {
                                        > a,
                                        > span {
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
