.homepage__category--block-wrapper {
    .category__container {
        display: flex;
        flex-wrap: wrap;
        @include breakpoint(small only) {
            overflow-x: scroll;
            padding-bottom: 10px;
            flex-wrap: nowrap;
        }

        > a {
            padding: 1rem;
            display: flex;
            flex-flow: column;
            gap: 1rem;
            justify-content: center;
            align-items: center;
            flex: 0 0 16.66667%;
            @include breakpoint(small only) {
                flex: 0 0 90vw;
                max-width: 200px;
            }
            @include breakpoint(medium only) {
                flex: 0 0 33.3333334%;
            }

            .--image {
                display: block;
                width: 15rem;
                height: 15rem;
                margin: 0 auto;

                > img {
                    @include transition();
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .--title {
                display: flex;
                font-weight: 700;
                align-items: center;
                gap: 1rem;
                color: color(base, DefaultFontColor);
                position: relative;
                justify-content: center;
                margin-top: 1rem;

                > span {
                    @include transition();
                    width: 1.5rem;
                    height: 1.5rem;
                    display: flex;
                    background: color(base, Primary);
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;

                    svg {
                        width: 1.1rem;
                        height: 1.1rem;
                        fill: color(base, White);
                    }
                }
            }

            @include hover() {
                .--image {
                    > img {
                        transform: scale(1.1);
                    }
                }
                .--title {
                    > span {
                        transform: translateX(.5rem);
                    }
                }
            }
        }
    }
}
