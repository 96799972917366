.position-left {
    box-shadow: none;
    span.title {
        > span {
            display: block;
            @include font-size(16px);
            color: color(base, Primary);
            font-weight: 700;
        }
    }
    .is-drilldown {
        a, span {
            padding: 0 20px;
            color: color(base, DefaultFontColor);
            line-height: 44px;
            @include font-size(14px);
            border-bottom: 1px solid color(Gray, Gray-100);
        }
        li.js-drilldown-back a {
            @include font-size(13px);
            font-weight: 700;
        }
    }
}

.drilldown .is-drilldown-submenu-parent > a:after,
.drilldown .is-drilldown-submenu-parent > span:after{
    right: 20px;
    content: '';
    width: 7px !important;
    height: 10px !important;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="6.25" viewBox="0 0 320 512"><path fill="%23ec660d" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>');
    border: none;
    color: color(base, Primary);
}

.drilldown .js-drilldown-back > a:before {
    content: '';
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="10" width="6.25" viewBox="0 0 320 512"><path fill="%23ec660d" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg>');
    border: none;
    color: color(base, Primary);
    position: relative;
    width: 7px !important;
    height: 10px !important;
    top: -7px;
}

#offCanvas-menu {
    .drilldown {
        li {
            a, span {
                padding: 0 20px;
                color: color(base, DefaultFontColor);
                line-height: 44px;
                @include font-size(14px);
                border-bottom: 1px solid color(Gray, Gray-100);
                font-weight: normal;
                display: flex;
                align-items: center;
                &::after {
                    margin-left: auto;
                }
            }
            &.js-drilldown-back {
                a {
                    font-weight: 600;
                    &::before {
                        top: initial;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
