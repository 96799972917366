body #omnistock-bootstrap-modal___BV_modal_outer_ .modal-content {
    width: 100%;
    margin-top: 10vh;
}
#solar-app #omnistock-single-product-widget {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 5px;
    @include breakpoint(large) {
        width: 70%;
    }

    * {
        font-size: 14px;
        font-family: Inter,sans-serif;
    }

    form#postcode-form {
        margin: 0;

        .input-group {
            display: flex;

            input {
                font-size: 14px;
                width: 100%;
                height: 40px;
            }
        }
    }

    .address-card {
        padding: 12px 25px 12px 15px;
    }

    #togglePickupStoreCollapse {
        margin-top: 20px;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}

body #omnistock-bootstrap-modal___BV_modal_outer_ .modal-body {
    padding: 2rem;
}

body #omnistock-bootstrap-modal .fs-1[data-v-2e79860c] {
    font-size: 14px !important;
}

#omnistock-bootstrap-modal___BV_modal_outer_ .pb-2, #omnistock-bootstrap-modal___BV_modal_outer_ .row {
    gap: 10px;
}

#omnistock-checkout-widget {
    h4 {
        font-size: 1.6rem !important;
    }
    .address-card {
        font-size: 13px !important;
        border-radius: 0 !important;
        .card-body {
            .row {
                position: relative !important;
                > .col-3 {
                    position: absolute !important;
                    top: -20px !important;
                    right: -15px !important;
                    .btn-more-info {
                        font-size: 1rem;
                    }
                }
            }
            .col-9 {
                flex: none !important;
                max-width: 100% !important;
                .form-check.radio-container {
                    border: 0 !important;
                    border-radius: 0 !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    label {
                        padding-left: 5rem;
                        &:has(.checkmark[style*="display"]) {
                            padding-left: 0;
                        }
                    }
                    .checkmark {
                        margin: 0 !important;
                        top: 3px !important;
                    }
                }
            }
        }
    }
}
