.pagination {
    @include breakpoint(small only) {
        width: 100%;
        padding: 10px 0 !important;
    }
    .current {
        background: none;
        padding: 0;
    }
    p.toolbar-amount span {
        @include font-size(13px);
        @include breakpoint(small only) {
            @include font-size(12px);
        }
        line-height: 34px;
    }
    ol, ul {
        margin: 5px 0;
        @include clearfix;
        float: right;
        @include breakpoint(small only) {
            display: table;
            margin: 0 auto;
            float: none;
        }
    }
    li {
        float: left;
        display: inline-block;
        a, span {
            display: block;
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin-left: 5px;
            background: color(Gray, Gray-100);
            color: color(base, DefaultFontColor);
            text-align: center;
            font-size: 11px;
            padding: 0;
            border-bottom: 2px solid transparent;
            @include breakpoint(small only) {
                width: 35px;
                height: 35px;
                line-height: 35px;
                margin-left: 5px;
                font-size: 13px;
            }
            i {
                @include font-size(13px);
            }
        }
        span {
            background: color(base, White);
            border-color: color(base, Primary);
        }
        a:hover {
            background: color(base, Primary);
            color: color(base, White);
        }
    }
    .disabled {
        display: none !important;
    }
}
