.footer-reviews {
    margin: 30px 0 0;
    padding: 40px 0;
    background: color(Gray, Gray-150);

    @include breakpoint(small only) {
        padding: 20px 0 20px 10px;
    }

    .review__header {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 2rem;
        @include breakpoint(small only) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    .grid-x.grid-padding-x {
        justify-content: space-between;
        flex-wrap: nowrap;
        overflow-x: scroll;
        gap: 10px;

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(small only) {
            margin-right: 0;
        }
    }

    .footer__rating-block {
        position: relative;
        background: color(base, White);
        padding: 15px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;

        &.--large {
            margin-top: .5rem;

            .review__text {
                font-size: 1.3rem;
                line-height: 1.5rem;
            }
        }

        .bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 5px;

            .--view {
                color: color(base, Primary);
                cursor: pointer;
            }
        }

        .review__text {
            @include font-size(12px);
            line-height: 14px;
            margin-bottom: 5px;
            margin-top: 1.5rem;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            line-clamp: 4;
            -webkit-box-orient: vertical;
            @include breakpoint(small only) {
                margin-bottom: 1.5rem;
            }
        }

        .review__bottom {
            display: flex;
            flex-direction: column;
            @include font-size(12px);
            color: color(Gray, Gray-600);
            margin-top: 10px;
        }

        .review__city {
            text-transform: capitalize;
        }

        .review__rating {
            @include font-size(20px);
            line-height: 24px;
            color: color(base, Black);
            margin-right: .5rem;
        }

        .review__rating-score {
            font-weight: 700;
        }
    }

    .footer__rating-block-first {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &.footer__rating-block-summary {
            margin-bottom: 2rem;
        }

        a {
            display: block;
            text-decoration: none;
        }

        p {
            line-height: unset;
        }

        .score {
            display: block;
            text-align: center;
            font-weight: 700;
            font-size: 34px;
        }

        .stars {
            display: block;
            margin: 1rem auto;
        }

        .tagline {
            display: block;
            max-width: 80%;
            margin: 0 auto;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: color(base, Primary);

            span:nth-of-type(2n) {
                font-weight: 700;
                color: color(base, Black);
            }
        }
    }
}

.star-score {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 15px;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='90px' height='15px' viewBox='0 0 90 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(0.000000, 0.000000)' fill='%23EBEBEB'%3E%3Cpath d='M8.05152153,12.4371859 L3.07435726,15 L4.02499102,9.57286432 L0,5.72864322 L5.56370914,4.93718593 L8.05152153,0 L10.5401037,4.93718593 L16.1045825,5.72864322 L12.078052,9.57286432 L13.0286858,15 L8.05152153,12.4371859 Z M26.5253759,12.4371859 L21.5482116,15 L22.4988454,9.57286432 L18.4730846,5.72864322 L24.0367938,4.93718593 L26.5253759,0 L29.013958,4.93718593 L34.5784369,5.72864322 L30.5519064,9.57286432 L31.5025402,15 L26.5253759,12.4371859 Z M44.9992303,12.4371859 L40.022066,15 L40.9726997,9.57286432 L36.946939,5.72864322 L42.5106481,4.93718593 L44.9992303,0 L47.4878124,4.93718593 L53.0522913,5.72864322 L49.0257608,9.57286432 L49.9763945,15 L44.9992303,12.4371859 Z M63.4730846,12.4371859 L58.4959204,15 L59.4465541,9.57286432 L55.4207933,5.72864322 L60.9845025,4.93718593 L63.4730846,0 L65.9616668,4.93718593 L71.5261456,5.72864322 L67.4996151,9.57286432 L68.4502489,15 L63.4730846,12.4371859 Z M81.946939,12.4371859 L76.9697747,15 L77.9204085,9.57286432 L73.8946477,5.72864322 L79.4583569,4.93718593 L81.946939,0 L84.4355211,4.93718593 L90,5.72864322 L85.9734695,9.57286432 L86.9241032,15 L81.946939,12.4371859 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: cover;

    &--rating {
        height: 15px;
        display: block;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='90px' height='15px' viewBox='0 0 90 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(0.000000, 0.000000)' fill='%23FFC400'%3E%3Cpath d='M8.05152153,12.4371859 L3.07435726,15 L4.02499102,9.57286432 L0,5.72864322 L5.56370914,4.93718593 L8.05152153,0 L10.5401037,4.93718593 L16.1045825,5.72864322 L12.078052,9.57286432 L13.0286858,15 L8.05152153,12.4371859 Z M26.5253759,12.4371859 L21.5482116,15 L22.4988454,9.57286432 L18.4730846,5.72864322 L24.0367938,4.93718593 L26.5253759,0 L29.013958,4.93718593 L34.5784369,5.72864322 L30.5519064,9.57286432 L31.5025402,15 L26.5253759,12.4371859 Z M44.9992303,12.4371859 L40.022066,15 L40.9726997,9.57286432 L36.946939,5.72864322 L42.5106481,4.93718593 L44.9992303,0 L47.4878124,4.93718593 L53.0522913,5.72864322 L49.0257608,9.57286432 L49.9763945,15 L44.9992303,12.4371859 Z M63.4730846,12.4371859 L58.4959204,15 L59.4465541,9.57286432 L55.4207933,5.72864322 L60.9845025,4.93718593 L63.4730846,0 L65.9616668,4.93718593 L71.5261456,5.72864322 L67.4996151,9.57286432 L68.4502489,15 L63.4730846,12.4371859 Z M81.946939,12.4371859 L76.9697747,15 L77.9204085,9.57286432 L73.8946477,5.72864322 L79.4583569,4.93718593 L81.946939,0 L84.4355211,4.93718593 L90,5.72864322 L85.9734695,9.57286432 L86.9241032,15 L81.946939,12.4371859 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        background-size: cover;
        background-repeat: no-repeat;
    }
}
