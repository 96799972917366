.cart__coupon {
    margin: 12px 0;

    .toggle-coupon {
        label {
            &:before {
                margin-top: -3px;
            }
        }
    }
    .coupon-code__form {
        margin-top: 2.4rem;
        .form-holder {
            display: flex;
            gap: 1.2rem;
            max-width: 75%;
        }
        .field-success {
            color: color(alert, Success);
            display: inline-block;
            @include font-size(12px);
            padding: 0.6rem;
        }
        .field-error {
            color: color(alert, Error);
            display: inline-block;
            @include font-size(12px);
            padding: 0.6rem;
        }

    }
}
