.checkout-cart, .checkout-cart-empty {
    margin: 40px 0;
    .cart__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
            color: color(base, DefaultFontColor);
        }

        button {
            display: inline-block;
            @include font-size(13px);
            color: color(Gray, Gray-400);
            > svg {
                width: 5px;
                margin-right: 5px;
            }
            &:hover {
                color: color(Gray, Gray-500);
            }
        }
    }
    .cart__content-empty {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin: 12px 0;
    }
    .main {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;

        .cart {
            &__items {
                flex: 0 0 100%;
                align-items: flex-start;
                gap: 20px;
                @include breakpoint(medium) {
                    flex: 1;
                }

                .cart__item, .cart__items-loader {
                    border: 1px solid color(Gray, Gray-100);
                    border-radius: 3px;
                }

                .cart__items-loader {
                    padding: 30px;
                    text-align: center;
                    color: color(Gray, Gray-700);

                    i {
                        margin-right: 5px;
                    }
                }

                .cart__item {
                    align-items: center;
                    padding: 10px;
                    gap: 10px;

                    &-image {
                        flex: 0 0 80px;
                        @include breakpoint(medium) {
                            flex: 0 0 120px;
                        }
                    }

                    &-details {
                        flex: 0 0 calc(100% - 90px);
                        @include breakpoint(medium) {
                            flex: 0 0 calc(100% - 130px);
                        }
                        @include breakpoint(large) {
                            flex: 1;
                        }

                        a, span {
                            display: block;
                            font-weight: 700;
                            @include font-size(16px);
                            color: color(Gray, Gray-700);
                            line-height: 22px;
                        }

                        span {
                            line-height: 18px;
                            @include font-size(13px);
                            color: color(Gray, Gray-600);
                            font-weight: 600;
                            @include breakpoint(medium) {
                                font-weight: 700;
                            }
                        }

                        .rating {
                            margin: 5px 0;
                        }

                        &.--bundle a, &.--bundle span {
                            padding: 0 2rem;
                        }
                    }

                    &-actions {
                        flex: 1;
                        gap: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        form {
                            margin: 0;
                            padding: 0;
                        }

                        .cart__item-delete, .cart__item-delete-loader {
                            color: color(Gray, Gray-600);
                            @include font-size(13px);
                            font-weight: 500;
                        }

                        .cart__item-delete-loader {
                            @include font-size(16px);
                        }

                        .cart__item-qty {
                            display: flex;
                            align-items: center;
                            gap: 10px;

                            select:disabled {
                                background: color(Gray, Gray-100);
                            }
                        }

                        .cart__item-price {
                            color: color(Gray, Gray-700);
                            @include font-size(16px);
                            font-weight: 700;
                        }
                    }

                    &-services {
                        display: flex;
                        flex-direction: column;
                        justify-items: flex-end;
                        width: 100%;
                        @include breakpoint(large) {
                            margin-left: 130px;
                        }

                        &.--bundle {
                            margin-left: 0;

                            .--name {
                                display: block;
                                padding: 1rem 0 0 0;
                                margin: 1rem 0 0 0;
                                border-top: .1rem solid color(Gray, Gray-100);
                                font-weight: 600;
                            }

                            .--item {
                                display: flex;
                                gap: 4rem;

                                > .image__holder {
                                    flex: 0 0 12rem;
                                    width: 12rem;
                                }
                            }

                            .product__services-container {
                                flex: 1;
                            }
                        }
                    }
                    &-message {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                    }
                }
            }
        }

        .cart__summary {
            flex: 0 0 100%;
            @include breakpoint(medium) {
                max-width: 400px;
            }

            .cart__totals {
                display: flex;
                flex-direction: column;
                gap: 10px;
                background: color(Gray, Gray-100);
                padding: 20px;
                border-radius: 3px;

                .cart__totals-container {
                    > span {
                        display: block;
                        @include font-size(13px);
                        line-height: 21px;
                        color: color(Gray, Gray-600);
                        font-weight: 600;
                    }

                    .cart__price-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        line-height: 28px;
                        .mark {
                            color: color(Gray, Gray-600);
                            @include font-size(13px);
                            &._total {
                                font-weight: 700;
                                small {
                                    @include font-size(11px);
                                    font-weight: normal;
                                }
                                + .amount {
                                    font-weight: 700;
                                    @include breakpoint(large) {
                                        @include font-size(15px);
                                    }
                                }
                            }
                        }
                        .amount {
                            @include font-size(14px);
                            color: color(Gray, Gray-700);

                            ._free {
                                color: color(base, Green);
                            }
                        }
                    }

                    .cart__totals-loader {
                        @include font-size(15px);
                        color: color(Gray, Gray-600);
                        @include breakpoint(medium) {
                            text-align: right;
                            line-height: 83px;
                        }
                        i {
                            padding-right: 5px;
                        }
                    }
                }

                .cart__button {
                    .--button {
                        width: 100%;
                        justify-content: center;
                    }
                }

                .cart__terms {
                    display: flex;
                    text-align: center;
                    .cart__terms-content {
                        line-height: 1.4rem;
                        @include font-size(11px);

                        > a {
                            line-height: 1.4rem;
                            @include font-size(11px);
                        }
                    }

                }
            }

            .cart__usp {
                margin-top: 20px;
                ul {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    li {
                        position: relative;
                        display: flex;
                        gap: 10px;
                        align-items: baseline;

                        > svg {
                            margin-right: 10px;
                            width: 16px;
                            fill: color(base, Green);
                        }
                        strong, span {
                            display: block;
                            @include font-size(14px);
                            line-height: normal;
                            color: color(Gray, Gray-700);
                        }
                        span {
                            @include font-size(12px);
                            color: color(Gray, Gray-600);

                            a {
                                font-size: 1.2rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .cart__cross-sell {
        margin-top: 40px;
        h2 {
            color: color(base, DefaultFontColor);
        }
    }

    &#omnistock-b2b-view-cart {
        .main {
            flex-wrap: nowrap;
            flex-direction: column;

            .cart__item-qty {
                display: flex;
                gap: 5px;
                input {
                    max-width: 50px;
                }
                button {
                    font-weight: bold;
                    font-size: 22px;
                }
            }

            .cart__item-price {
                min-width: 100px;
                text-align: right;
            }
        }
        .cart__totals {
            display: flex;
            justify-content: flex-end;
            background: color(Gray, Gray-100);
            padding: 30px 40px;

            .mark._total {
                font-weight: 700;
                small {
                    @include font-size(11px);
                    font-weight: normal;
                }
            }
            .amount {
                min-width: 250px;
                text-align: right;

                .price {
                    color: color(base, DefaultFontColor);
                    @include font-size(16px);
                    font-weight: 700;
                }
            }
        }
        .cart__buttons {
            display: flex;
            justify-content: flex-end;
            padding: 20px 0;
            button {
                @include default-button();
            }
        }
    }
}
