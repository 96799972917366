.customer-account {
    margin-top: 24px;
    margin-bottom: 24px;
    @include breakpoint(medium) {
        margin-top: 48px;
        margin-bottom: 48px;
    }

    h1, h2, h3, h4, h5, h6 {
        color: color(base, DefaultFontColor);
        @include font-size(32px);

        .--accent-color {
            color: color(base, Primary);
        }
    }

    .customer__account-navigation_toggle {
        margin: 0 0 1.6rem 0;
    }
    .personal__details,
    .dashboard__addresses {
        margin-bottom: 5.5rem;
    }

    form {
        .field__container {
            position: relative;

            input, label {
                height: 45px;
                border: 1px solid color(Gray, Gray-200);
                border-radius: .8rem;
                width: 100%;
            }

            label {
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                border: 0;
                padding: .8rem 1.2rem;
                margin: 0.6rem;
                cursor: text;
                pointer-events: none;
            }

            input, select {
                padding: 1.4rem 1.2rem 0;
            }

            input::placeholder {
                visibility: hidden;
            }

            input + label,
            input ~ label,
            select + label,
            select ~ label{
                top: -10px;
                @include font-size(11px);
                color: color(Gray, Gray-500);

            }

        }

        .field__actions {
            &.--links {
                display: flex;
                align-items: flex-end;

                a.--forget {
                    color: color(base, Primary);
                }
            }

            &.--buttons {
                button {
                    svg {
                        margin-top: 0.1rem;
                        margin-left: 0.8rem;
                    }
                }
            }
        }

    }

    a {
        &.--cta-link {
            align-items: center;
            color: #1d1d1f;
            display: inline-flex;
            gap: 1rem;
            margin-top: 2.4rem;
        }
    }

    .login-panel {
        padding: 3.2rem 0;

        h1 {
            @include font-size(32px);
            margin-bottom: 3.2rem;
        }

        .login__description {
            h2 {
                @include font-size(20px);
                line-height: 1.2;
                color: color(base, DefaultFontColor);
                margin: 0;
            }
        }
    }
    .register__panel {
        padding: 3.2rem 0;
        h2 {
            @include font-size(20px);
            line-height: 1.2;
            color: color(base, DefaultFontColor);
            margin: 0;
        }
        .global__usp {
            margin-bottom: 3.2rem;
        }
        ul {
            li {
                line-height: 26px;
                padding: 0 0 0 20px;
            }
        }

        .--button {
            svg {
                margin-top: 0.1rem;
                margin-left: 0.8rem;
            }
        }

    }

    .--intro-block {
        max-width: 100%;
        margin: 0 0 5.5rem 0;
        @include breakpoint(medium) {
            max-width: 60%;
        }
        h1 {
            color: color(base, DefaultFontColor);
            @include font-size(32px);
            margin-bottom: 3.2rem;
            span {
                color: color(base, Primary);
            }
        }
    }
    .customer-account-container form {
        margin: 0 !important;
    }
    .customer-account-container form,
    .dashboard__addresses-container,
    .address-container {
        margin: 2.4rem 0;
        display: flex;
        flex-direction: column;
        gap: 3.2rem;

        .address__block {
            background-color: color(Gray, Gray-100);
            padding: 3.2rem;
            border-radius: 0.8rem;
            position: relative;
            @include font-size(16px);
            h3 {
                @include font-size(18px);
                margin-bottom: 2.4rem;
            }
            &.--cta {
                background-color: transparent;
                padding: 0;
                border-radius: 0;
                a.--add-new {
                    display: inline-flex;
                    align-items: center;
                    padding: 1.4rem 1.8rem;
                    gap: 1rem;
                    border: 1px solid color(Gray, Gray-200);
                    color: color(base, DefaultFontColor);
                    border-radius: .3rem;
                    svg {
                        width: 2rem;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &.customer__address {
                .title:first-child {
                    margin-top: 0;
                }
            }
        }
    }
    .--actions {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 1rem;
        @include breakpoint(medium) {
            margin-top: 0;
            position: absolute;
            top: 3.2rem;
            right: 3.2rem;
        }
        a.--button {
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: color(base, White);
            color: color(base, DefaultFontColor);
            @include font-size(14px);
            font-weight: normal;
            border-radius: 4.4rem;
            svg {
                max-width: 1.8rem;
            }
        }
    }
    .dashboard__addresses-container {
        flex-direction: row;
        margin-bottom: 0;
    }
    .personal__details-container,
    .address__block {
        width: 100%;
        .title {
            color: color(Gray, Gray-500);
            margin-top: 2.4rem;
        }
    }

    .personal__details-container,
    .customer__account-block,
    .order__row__container {
        background-color: color(Gray, Gray-100);
        padding: 3.2rem;
        border-radius: 0.8rem;
        position: relative;
        @include font-size(16px);
        @include breakpoint(small only) {
            padding: 1.6rem;
        }
    }
    .order__row__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2rem;
        > a {
          margin: 0;
        }
    }
    .personal__details-container,
    .customer__account-block {
        @include font-size(14px);
        h3 {
            @include font-size(18px);
            margin-bottom: 2.4rem;
        }

    }

    .order {
        &__row {
            margin-top: 3.2rem;
            .label--text-light {
                color: color(Gray, Gray-500);
            }
            + .order__row__container:before {
                content: "";
                height: 2.1rem;
                border: 1rem solid color(Gray, Gray-100);
                width: 100%;
                position: absolute;
                top: -1rem;
                left: 0;
                z-index: 0;
                background-color: rgba(color(Gray, Gray-500), 0.2);
            }
            &.--multiple-items {
                .item__list {
                    background-color: #f6f6f6;
                    border-radius: .8rem;
                    @include font-size(16px);
                    padding: 3.2rem;
                    @include breakpoint(small only) {
                        padding: 1.6rem;
                    }
                }
                .order__row__container {
                    padding: 0;
                    margin: 1.8rem 0;

                    img {
                        max-width: 90px;
                        @include breakpoint(small only) {
                            max-width: 60px;
                        }
                    }
                    .order__row__footer {
                        left: 0;
                    }

                }
            }
            &__container {
                display: flex;
                gap: 3.2rem;
                border-radius: 0;
                @include breakpoint(small only) {
                    gap: 1.6rem;
                }
                &:first-child {
                    border-top-left-radius: 0.8rem;
                    border-top-right-radius: 0.8rem;
                    margin-top: 0 !important;
                }
                &:last-child {
                    border-bottom-left-radius: 0.8rem;
                    border-bottom-right-radius: 0.8rem;
                    margin-bottom: 0 !important;
                    padding-top: 0;
                }
                &.--totals {
                    @include font-size(14px);
                    border-top: .1rem solid rgba(color(Gray, Gray-500), 0.2);
                    justify-content: flex-end;
                    @include breakpoint(small only) {
                        flex-direction: column;
                        gap: 0;
                    }
                }
                > a {
                    @include breakpoint(small only) {
                        align-self: flex-start;
                    }
                }
                a {
                    color: color(base, DefaultFontColor);
                }
                img {
                    max-width: 140px;
                    @include breakpoint(small only) {
                        max-width: 60px;
                    }
                }
                .customer__address {
                    margin-left: -2rem;
                    margin-right: -2rem;

                    &.address__block {
                        @include font-size(14px);

                        .title {
                            margin-top: 0;
                        }

                        .address {
                            @include font-size(14px);
                        }
                    }
                }
            }
            &__footer {
                position: absolute;
                top: 2rem;
                left: 3.3rem;
                @include breakpoint(small only) {
                    position: relative;
                    top: 0;
                    left: auto;
                }

                .--actions {
                    position: relative;
                    top: 0;
                    right: 0;
                    a {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        background-color: color(base, White);
                        color: color(base, DefaultFontColor);
                        @include font-size(14px);
                        font-weight: normal;
                        border-radius: 4.4rem;
                        @include breakpoint(small only) {
                            justify-content: center;
                        }
                        svg {
                            margin-top: 0.1rem;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            &__actions {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 2rem;
                @include breakpoint(small only) {
                    flex-direction: column;
                    gap: 1rem;
                }
            }
        }
        &__date {
            display: inline-block;
            color: color(Gray, Gray-500);
            padding-bottom: .8rem;
            @include font-size(14px);
            time {
                color: color(base, DefaultFontColor);

            }
        }
        &__info {
            display: flex;
            flex-direction: column;
            > * {
                display: inline-block;
            }
            > a.--button {
                display: inline-flex;
            }
            h3 {
                @include font-size(16px);
                margin-bottom: .4rem;
            }
            p {
                @include font-size(13px);
            }
            .button--manual {
                margin-top: .4rem;
            }
        }
        &__price {
            min-width: 25rem;
            padding: 2rem;
            @include breakpoint(small only) {
                padding: 2rem 0;
            }
            > div {
                text-align: right;
                margin-bottom: .4rem;
                > span:last-child {
                    display: inline-block;
                    min-width: 80px;
                    font-weight: bold;
                }
            }

        }
    }
    .order-overview .button--manual {
        display: none !important;
    }
}

.customer__account-navigation {
    .modal__header {
        background: color(base, Primary);
        color: color(base, White);
        padding: 9px 10px;
        @include font-size(16px);
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
            display: block;
            position: relative;
        }
    }
    ul {
        @include breakpoint(small only) {
            background: transparent;
            padding: 0;
        }
        li {
            border-bottom: 1px solid color(Gray, Gray-100);
            padding: 0.4rem 0;
            &.--active {
                a {
                    color: color(base, Primary);
                    font-weight: 700;
                    background-color: rgba(color(base, Primary), 0.1);
                    text-decoration: underline;
                    span {
                        &.--overview {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' /%3E%3C/svg%3E%0A");
                        }
                        &.--settings {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z' /%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E%0A");
                        }
                        &.--addresses {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819' /%3E%3C/svg%3E%0A");
                        }
                        &.--store-orders {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z' /%3E%3C/svg%3E%0A");
                        }
                        &.--orders {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                        }
                        &.--newsletter {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                        }
                        &.--logout {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z' /%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
            a {
                padding: 16px;
                display: flex;
                align-items: center;
                gap: 10px;
                color: color(base, DefaultFontColor);
                font-weight: 700;
                border-radius: 4.4rem;
                &:hover {
                    color: color(base, Primary);
                    span {
                        &.--overview {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' /%3E%3C/svg%3E%0A");
                        }
                        &.--settings {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z' /%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E%0A");
                        }
                        &.--addresses {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819' /%3E%3C/svg%3E%0A");
                        }
                        &.--store-orders {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z' /%3E%3C/svg%3E%0A");
                        }
                        &.--orders {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                        }
                        &.--newsletter {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                        }
                        &.--logout {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z' /%3E%3C/svg%3E%0A");
                        }
                    }
                }

                span {
                    width: 20px;
                    height: 20px;
                    display: block;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    &.--overview {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' /%3E%3C/svg%3E%0A");
                    }
                    &.--settings {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z' /%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E%0A");
                    }
                    &.--addresses {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205 3 1m1.5.5-1.5-.5M6.75 7.364V3h-3v18m3-13.636 10.5-3.819' /%3E%3C/svg%3E%0A");
                    }
                    &.--store-orders {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z' /%3E%3C/svg%3E%0A");
                    }
                    &.--orders {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                    }
                    &.--newsletter {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                    }
                    &.--logout {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338383B' stroke-linecap='round' stroke-linejoin='round' d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z' /%3E%3C/svg%3E%0A");
                    }
                }

            }
        }
    }
}
