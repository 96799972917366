.sticky__add-to-cart-holder {
    position: fixed;
    bottom: -200px;
    left: 0;
    right: 0;
    z-index: 9999;
    transition: bottom .2s ease-in-out;
    &.--open {
        bottom: 0;
    }
    .sticky__add-to-cart {
        background: color(base, White);
        border-top: 1px solid color(Gray, Gray-300);
        padding: 10px 0;
        @include breakpoint(small only) {
            padding: 5px 0;
        }
        .grid-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .product__information {
                display: flex;
                align-items: center;
                @include breakpoint(small only) {
                    max-width: calc(100% - 52px);
                }

                .image__holder {
                    padding: 4px;
                    border: 1px solid color(Gray, Gray-300);
                    border-radius: 4px;
                    @include breakpoint(small only) {
                        padding: 3px;
                        img {
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
                ._info {
                    margin-left: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    @include breakpoint(small only) {
                        margin: 0 10px 0 0;
                        max-width: 100%;
                    }
                    h2 {
                        color: color(base, DefaultFontColor);
                        font-size: 16px;
                        margin: 0;
                        @include breakpoint(small only) {
                            font-size: 12px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: 100%;
                        }
                    }
                    span {
                        font-size: 12px;
                    }

                    .rating_count_wrapper {
                        flex: 0 0 100%;
                        display: flex;
                        gap: 5px;
                        align-items: center;
                        @include breakpoint(small only) {
                            display: none;
                        }
                        .product_rating {
                            width: 84px;
                            height: 15px;
                            background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nODQnIGhlaWdodD0nMTYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTggMS4xM2wyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw4IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMOCAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDQyIDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNDIgMS4xM3ptLTE3IDBsMi4wMiA0LjA5IDQuNTE0LjY1Ny0zLjI2NyAzLjE4NS43NzEgNC40OTZMMjUgMTEuNDM1bC00LjAzOCAyLjEyMy43NzEtNC40OTYtMy4yNjctMy4xODUgNC41MTUtLjY1NkwyNSAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDU5IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNTkgMS4xM3ptMTcgMGwyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw3NiAxMS40MzVsLTQuMDM4IDIuMTIzLjc3MS00LjQ5Ni0zLjI2Ny0zLjE4NSA0LjUxNS0uNjU2TDc2IDEuMTN6JyBzdHJva2U9JyNGRkM0MDAnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
                            display: block;
                            .product_rating_fill {
                                height: 15px;
                                background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nODQnIGhlaWdodD0nMTYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTggMS4xM2wyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw4IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMOCAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDQyIDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNDIgMS4xM3ptLTE3IDBsMi4wMiA0LjA5IDQuNTE0LjY1Ny0zLjI2NyAzLjE4NS43NzEgNC40OTZMMjUgMTEuNDM1bC00LjAzOCAyLjEyMy43NzEtNC40OTYtMy4yNjctMy4xODUgNC41MTUtLjY1NkwyNSAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDU5IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNTkgMS4xM3ptMTcgMGwyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw3NiAxMS40MzVsLTQuMDM4IDIuMTIzLjc3MS00LjQ5Ni0zLjI2Ny0zLjE4NSA0LjUxNS0uNjU2TDc2IDEuMTN6JyBmaWxsPScjRkZDNDAwJyBzdHJva2U9JyNGRkM0MDAnIGZpbGwtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
                                display: block;
                            }
                        }

                    }
                }
            }
            .add-to-cart {
                margin-left: auto;
                display: flex;
                align-items: center;
                ._price {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;
                    span {
                        &._old-price {
                            position: relative;
                            color: color(base, DefaultFontColor);
                            font-size: 14px;
                            font-weight: normal;
                            margin-right: 10px;
                            &::before {
                                position: absolute;
                                content: '';
                                left: 0;
                                top: 50%;
                                right: 0;
                                border-top: 1px solid;
                                border-color: inherit;
                                transform: rotate(-5deg);
                            }
                        }
                        &:last-child {
                            color: color(base, DefaultFontColor);
                            font-weight: 600;
                            font-size: 20px;
                        }
                    }
                }
                button {
                    white-space: nowrap;
                    @include breakpoint(small only) {
                        height: 40px;
                        padding: 0 13px;
                        &::before {
                            margin: 0;
                        }
                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
body:has(.sticky__add-to-cart-holder.--open) {
    .product__compare-wrapper {
        bottom: 84px;
    }
}
