@mixin service-block {
    label {
        color: color(base, DefaultFontColor);
        @include font-size(13px);
        margin: 0;
        line-height: 20px;
        position: relative;
        display: flex;
        gap: 5px;
        @media screen and (min-width: 768px) and (max-width: 1023px) {
            line-height: 12px;
        }

        > span {
            display: block;
            flex: 1;

            > span {
                display: flex;
                gap: 20px;
                align-items: baseline;
                > span {
                    display: block;

                    &.title {
                        flex: 1;
                        line-height: normal;
                    }

                    &.price {
                        margin-left: auto;

                        &._free {
                            color: color(base, Green);
                            font-weight: 700;
                        }
                    }
                }

                .show-more {
                    color: color(base, DefaultFontColor);
                    @include font-size(12px);
                    margin-left: 5px;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
}

.product__services-container {
    ._loading {
        position: static;
        max-width: 400px;
    }

    .product__services {
        padding: 20px 0;

        h4 {
            @include font-size(14px);
            color: color(base, DefaultFontColor);
            font-weight: 600;
            margin: 0 0 10px;
        }
        &.--compact {
            &.--one-block {
                border: 1px solid color(Gray, Gray-200);
                border-radius: 5px;
                padding: 10px;

                .product__services--groups {
                    border: none;
                    margin: 0;
                    padding: 5px;
                    &:hover {
                        box-shadow: none;
                    }
                }
            }
            .product__services--groups {
                padding: 5px;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                .options__container {
                    margin: 5px 0;
                }
            }
        }

        &--groups {
            border: 1px solid color(Gray, Gray-200);
            border-radius: 5px;
            padding: 10px;

            @include breakpoint(medium) {
                &:hover {
                    border-color: color(Gray, Gray-350);
                    box-shadow: 0 0.6rem 0.8rem -0.3rem color(Gray, Gray-200);

                }
                &:not(:has(.--option)).--active, &:has(.--option.--active).--active  {
                    border-color: color(base, Primary);
                    background-color: rgba(color(base, Primary), 0.05);
                }
            }
            &:not(:last-child) {
                margin-bottom: 10px;
            }

            .--item {
                @include service-block;
            }

            .options__container {
                margin: 10px 0 10px;
                padding-left: 20px;
                padding-top: 10px;
                border-top: 1px solid color(Gray, Gray-350);

                .extra-options {
                    .--option {
                        &:not(:last-child) {
                            margin-bottom: 10px;
                        }

                        @include breakpoint(medium) {
                            &:hover {
                                border-color: #a8a8a8;
                            }
                        }

                        @include service-block;
                    }
                }
            }
        }

        .--item {
            > div {
                position: relative;
            }
        }
    }

    .modal__overlay .modal__wrapper {
        @include breakpoint(medium) {
            max-width: 640px;
            overflow-x: auto;
        }

        .modal-content {
            p, ul, li {
                color: color(base, DefaultFontColor);
                @include font-size(14px);
                line-height: 20px;
            }

            ul {
                list-style: disc outside;
                margin-left: 20px;
            }
        }
    }
}

.checkout-cart {
    .cart__item-services {
        .product__services {
            > h4 {
                color: color(base, Primary);
                display: inline-flex;
                align-items: center;
                gap: .5rem;

                &:after {
                    content: '';
                    display: inline-block;
                    width: 1.5rem;
                    height: 1.5rem;
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 320 512' transform='rotate(90)' fill='%23EC660D'%3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E");
                }

                &.is-expanded:after {
                    transform: rotate(180deg);
                    background-position-y: -.2rem;
                }
            }

            &--list {
                &.is-collapsed {
                    display: none;
                }

                &.is-expanded {
                    display: block;
                }
            }
        }
    }
}

.cart__item-element {
    .product__services-container {
        h4 {
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;
        }
    }
}
