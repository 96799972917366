.catalog__products {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
    border-bottom: 1px solid color(base, Primary);
    @include breakpoint(small only) {
        overflow: hidden;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        @include breakpoint(small only) {
            flex-wrap: initial;
            overflow-x: scroll;
        }
        li {
            position: relative;
            flex: 0 0 50%;
            padding: 10px;
            @include breakpoint(medium) {
                flex: 0 0 33.33333334%;
            }
            a {
                display: flex;
                gap: 10px;
                flex-direction: column;

                .action__label {
                    position: absolute;
                    top: 15px;
                    left: -5px;
                    padding: 3px 8px;
                    letter-spacing: -.02em;
                    font-weight: 700;
                    font-size: 12px;
                    color: color(base, White);
                    border-radius: 3px;
                    z-index: 1;
                    background: color(base, Primary);
                }

                .cat-grid__image {
                    display: block;
                    position: relative;
                    img {
                        aspect-ratio: 1;
                        width: 100%;
                        height: 100%;
                    }
                }

                h2 {
                    font-size: 14px;
                    color: color(base, DefaultFontColor);
                    display: block;
                    line-height: 20px;
                    a {
                        color: color(base, DefaultFontColor);
                        display: block;
                        line-height: 20px;
                    }
                }
                .product__category {
                    display: block;
                    font-size: 13px;
                    color: color(base, DefaultFontColor);
                }

                .rating {
                    margin: 5px 0 0;
                }

                .cat-grid__price-stock_holder {
                    display: block;
                    margin: 10px 0 0;
                    .cat-grid__price-block {
                        display: flex;
                        gap: .5rem;
                        margin-top: .5rem;
                        align-items: center;

                        > span {
                            display: block;
                            @include font-size(14px);
                            color: color(Gray, Gray-400);

                            &.--old {
                                text-decoration: line-through;
                                font-size: 12px;
                                color: #626c77;
                                position: relative;
                                font-weight: 700;
                            }

                            &.--special {
                                color: color(base, Primary);
                                font-weight: 700;
                                @include font-size(16px);
                            }
                            &.price {
                                color: color(base, Primary);
                                font-weight: 700;
                                @include font-size(16px);
                                line-height: 22px;
                            }
                        }
                    }
                }
            }

            ._list-actions {
                position: absolute;
                bottom: 10px;
                right: 10px;
                button {
                    padding: 0;
                    width: 40px;
                    min-height: 40px;
                    &::before {
                        margin: 0;
                    }
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
