.back-to-top {
    margin: 40px 0 0;
    display: flex;
    justify-content: center;
    a {
        color: color(base, DefaultFontColor);
        display: block;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        span {
            display: block;
            &:first-child {
                width: 40px;
                height: 40px;
                margin: 0 auto 15px;
                border-radius: 50%;
                background-color: color(base, DefaultFontColor);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='White'%3E%3Cpath fill-rule='evenodd' d='M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                background-size: 26px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
        &:hover {
            color: #000;
            span:first-child {
                background-color: #000;
            }
        }
    }
}
