a {
    display: inline-flex;
    text-decoration: none;
    color: color(base, Primary);
    outline: none;
    @include font-size(14px);

    &:hover, &:active {
        outline-width: 0;
        color: lighten(color(base, Primary), 10%);
    }

    &.--arrow-right {
        @include link-arrow-right();
    }

    .--content {
        color: color(base, DefaultFontColor);
    }

    footer & {
        color: color(base, White);
        &:hover, &:active {
            text-decoration: underline;
        }
    }

    &.round__button {
        background-color: color(Gray, Gray-200);
        font-weight: 600;
        padding: 0 10px;
        cursor: pointer;
        @include font-size(14px);
        color: color(base, DefaultFontColor);
        line-height: 28px;
        height: 28px;
        @include border-radius(1.5rem);
        vertical-align: top;

        .fa.fa-arrow-right {
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23626c77' stroke-linecap='round' stroke-linejoin='round' d='M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3'/%3E%3C/svg%3E");
            height: 28px;
            vertical-align: top;
        }
    }
}

.--button {
    @include default-button();
    &.--loading {
        @include loading-button;
    }
}


