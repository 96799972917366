#offCanvas-filter {
    @include breakpoint(small only) {
        max-width: 100%;
        width: calc(100% - 4rem);
        background-color: transparent;
        display: flex;
        overflow: initial;
    }
    button[data-close] {
        > * {
            display: block;
            width: 100%;
            position: relative;
            > * {
                pointer-events: none;
            }
            svg {
                position: absolute;
                left: 1.2rem;
                top: .1rem;
            }
        }
    }
    .catalog__category-filter {
        @include breakpoint(small only) {
            display: flex;
            flex: 0 0 100%;
            background-color: color(Gray, Gray-100);
            overflow-y: auto;
        }
        section {
            @include breakpoint(small only) {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
            .modal__header {
                box-shadow: 0 0 15px rgba(0,0,0,.1);
                button {
                    background: color(base, Primary);
                    color: color(base, White);
                    display: block;
                    text-align: center;
                    line-height: 38px;
                    @include font-size(22px);
                    font-weight: 700;
                    height: 55px;
                    width: 100%;
                    position: relative;
                }
            }
            .modal__search {
                input {
                    margin: .5rem 0;
                    height: 3.2rem;
                }
                .filter__no-result {
                    padding: 0.5rem 1rem;
                }
            }
            .modal__content {
                flex-direction: column;
                display: flex;
                @include breakpoint(small only) {
                    height: 100%;
                    overflow-y: scroll;
                }
                .--sub-categories {
                    dl {
                        padding-bottom: 2rem;
                        border-bottom: 2px solid color(base, Primary);
                        @include breakpoint(small only) {
                            border-bottom: 1px solid color(Gray, Gray-300);
                            padding-bottom: 0;
                        }
                    }
                }
                dl {
                    margin-bottom: 2rem;
                    @include breakpoint(small only) {
                        margin: 0;
                        border-bottom: 1px solid color(Gray, Gray-300);
                    }
                    dt {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 10px;
                        > span {
                            @include font-size(13px);
                            text-transform: uppercase;
                            color: color(base, Anthracite);
                            line-height: 3.8em;
                            cursor: pointer;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            @include breakpoint(medium) {
                                line-height: 1.8em;
                            }
                            &::after {
                                content: '';
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke='%231d1d1f' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                                width: 16px;
                                height: 16px;
                                display: none;
                                @include breakpoint(medium) {
                                    display: block;
                                }
                            }
                        }
                        > .--button {
                            padding: 0 1rem;
                            &::after {
                                display: none;
                            }
                        }
                        @include breakpoint(small only) {
                            line-height: 50px;
                            @include font-size(14px);
                            padding: 0 20px;
                            justify-content: flex-start;
                            background-color: color(base, White);
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D1D1F;%7D%0A%3C/style%3E%3Cpath class='st0' d='M12.6,12L8.7,8.1C8.52,7.92,8.43,7.68,8.43,7.4S8.52,6.88,8.7,6.7c0.18-0.18,0.42-0.27,0.7-0.27 s0.52,0.09,0.7,0.27l4.6,4.6c0.1,0.1,0.17,0.21,0.21,0.33c0.04,0.12,0.06,0.24,0.06,0.38c0,0.13-0.02,0.26-0.06,0.38 c-0.04,0.12-0.11,0.22-0.21,0.32l-4.6,4.6c-0.18,0.18-0.42,0.27-0.7,0.27s-0.52-0.09-0.7-0.27c-0.18-0.18-0.27-0.42-0.27-0.7 c0-0.28,0.09-0.52,0.27-0.7L12.6,12z'/%3E%3C/svg%3E%0A");
                            background-position: center right 2rem;
                            background-repeat: no-repeat;
                            background-size: 2rem;
                            &.--visible {
                                background-color: transparent;
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D1D1F;%7D%0A%3C/style%3E%3Cpath class='st0' d='M11.7,11.1L7.8,15c-0.18,0.18-0.42,0.27-0.7,0.27S6.58,15.18,6.4,15c-0.18-0.18-0.28-0.42-0.28-0.7 c0-0.28,0.09-0.52,0.28-0.7L11,9c0.1-0.1,0.21-0.17,0.32-0.21c0.12-0.04,0.24-0.06,0.38-0.06c0.13,0,0.26,0.02,0.38,0.06 C12.19,8.83,12.3,8.9,12.4,9l4.6,4.6c0.18,0.18,0.27,0.42,0.27,0.7c0,0.28-0.09,0.52-0.27,0.7c-0.18,0.18-0.42,0.27-0.7,0.27 s-0.52-0.09-0.7-0.27L11.7,11.1z'/%3E%3C/svg%3E%0A");
                            }
                        }
                        &.--visible {
                            span {
                                &::after {
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                    dd {
                        @include breakpoint(small only) {
                            padding: 0 2rem 2rem 2rem;
                        }
                    }
                    button {
                        color: color(base, Primary);
                        @include font-size(13px);
                        line-height: 1.8em;
                        display: flex;
                        align-items: center;
                        &.--chevron-down {
                            &::after {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke='%23ec660d' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                            }
                        }
                        &.--chevron-up {
                            &::after {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ec660d' stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                            }
                        }
                        &::after {
                            content: '';
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background-size: 70%;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                        &:hover {
                            color: color(base, PrimaryHover);
                            &.--chevron-down {
                                &::after {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke='%23fa9119' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                                }
                            }
                            &.--chevron-up {
                                &::after {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23fa9119' stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                                }
                            }
                        }
                    }
                }

                ol {
                    display: flex;
                    flex-direction: column;
                    li {
                        padding: 2px 0;
                        a {
                            color: color(base, DefaultFontColor);
                            @include font-size(14px);
                            line-height: 1.4em;
                            &:hover {
                                color: color(base, Primary);
                            }
                        }
                        label {
                            display: flex;
                            align-items: center;
                            @include font-size(14px);
                            line-height: 1.4em;
                            cursor: pointer;
                            &:hover {
                                color: color(base, Primary);
                            }
                            &.--active {
                                &::before {
                                    background-color: color(base, Primary);
                                    border: 1px solid color(base, Primary);
                                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23fff' d='m382-354 339-339q12-12 28.5-12t28.5 12q12 12 12 28.5T778-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z'/%3E%3C/svg%3E");
                                    background-position: center;
                                    background-size: 100%;
                                }
                            }
                            &::before {
                                content: '';
                                width: 15px;
                                height: 15px;
                                border: 1px solid color(Gray, Gray-400);
                                display: inline-block;
                                border-radius: 3px;
                                margin-right: 1rem;
                            }
                            .--count {
                                color: color(Gray, Gray-600);
                            }
                        }
                    }

                    &.--swatch {
                        li {
                            label {
                                display: flex;
                                align-items: center;
                                gap: 4px;

                                &::before {
                                    display: none;
                                }

                                &.--active {
                                    .--checkbox {
                                        outline: 1px solid color(base, Primary)
                                    }
                                }

                                .--checkbox {
                                    height: 19px;
                                    width: 19px;
                                    border-radius: 10px;
                                    border: 1px solid color(Gray, Gray-200);
                                }
                            }
                        }
                    }
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    th {
                        background: color(Gray, Gray-100);
                        color: color(base, Anthracite);
                        @include font-size(14px);
                        font-weight: 600;
                        padding: 10px 15px;
                        text-align: left;
                    }
                    td {
                        padding: 10px 15px;
                        border-bottom: 1px solid color(Gray, Gray-300);
                        @include font-size(14px);
                        color: color(base, Anthracite);
                    }
                    tr {
                        &:hover {
                            background: color(Gray, Gray-100);
                        }
                    }
                }
                .price__filter {
                    max-width: 250px;
                    .vue-slider.vue-slider-ltr {
                        margin-left: 6px;
                        margin-right: 6px;
                    }
                    .vue-slider-process {
                        background: color(base, Primary);
                    }
                    .vue-slider-dot {
                        background: none;
                        box-shadow: none;
                        border: 2px solid color(base, White);
                        @include breakpoint(small only) {
                            border: 2px solid color(Gray, Gray-100);
                        }
                        .vue-slider-dot-handle {
                            background: color(base, Primary);
                            box-shadow: none;
                        }
                        .vue-slider-dot-tooltip-inner {
                            background: color(Gray, Gray-400);
                            border-color: color(Gray, Gray-400);
                        }
                    }
                    .custom__controls {
                        display: flex;
                        justify-content: space-between;
                        input[type="number"] {
                            max-width: 100px;
                        }
                    }
                }
            }

            .modal__footer {
                background: color(base, White);
                width: 100%;
                padding: 1rem;
                box-shadow: 0 0 15px rgba(0,0,0,.1);
                button {
                    display: block;
                    background: color(base, Primary);
                    color: color(base, White);
                    border-radius: 3px;
                    line-height: 40px;
                    @include font-size(14px);
                    font-weight: 600;
                    padding: 0 10px;
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}
@include breakpoint(small only) {
    .filter__content {
        &.--has-max-height ._content {
            max-height: 75px;

            &._filter::after {
                content: '';
                display: block;
                height: 30px;
                width: 100%;
                position: absolute;
                background: linear-gradient(0deg, rgba(246, 246, 246, 1) 0%, rgba(246, 246, 246, .5) 100%);
                bottom: 0;
            }
        }

        ._content {
            @include font-size(12px);
            overflow: hidden;
            position: relative;

            p {
                @include font-size(12px);
                text-align: left;
            }
        }

        ._actions {
            padding: 20px 0 10px;
            display: flex;
            justify-content: center;

            button {
                color: color(base, Primary);
                border: 1px solid color(base, Primary);
                line-height: 32px;
                border-radius: 16px;
                padding: 0 35px 0 20px;
                font-size: 13px;
                background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(0.000000, 0.000000)' fill='%23EC660D' fill-rule='nonzero'%3E%3Cpath d='M0.292786454,0.305312993 C0.683286305,-0.085068945 1.3162866,-0.085068945 1.70678645,0.305312993 L4.99978645,3.59831299 L8.29278645,0.305312993 C8.54386092,0.0453564089 8.91566435,-0.0588996039 9.26529378,0.0326158368 C9.61492321,0.124131277 9.88796817,0.397176238 9.97948361,0.746805669 C10.0709991,1.0964351 9.96674304,1.46823852 9.70678645,1.71931299 L5.70678645,5.71931299 C5.3162866,6.10969493 4.68328631,6.10969493 4.29278645,5.71931299 L0.292786454,1.71931299 C-0.0975954846,1.32881314 -0.0975954846,0.695812845 0.292786454,0.305312993 L0.292786454,0.305312993 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: right 10px center;
                background-size: 11px;
                font-weight: 600;

                &:hover {
                    color: color(base, PrimaryHover);;
                    border-color: color(base, PrimaryHover);;
                }

                > span {
                    display: block;
                }

                &._active {
                    background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='7px' viewBox='0 0 11 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(5.006050, 3.006050) rotate(-180.000000) translate(-5.006050, -3.006050) translate(0.000000, 0.000000)' fill='%23EC660D' fill-rule='nonzero'%3E%3Cpath d='M0.292786454,0.305312993 C0.683286305,-0.085068945 1.3162866,-0.085068945 1.70678645,0.305312993 L4.99978645,3.59831299 L8.29278645,0.305312993 C8.54386092,0.0453564089 8.91566435,-0.0588996039 9.26529378,0.0326158368 C9.61492321,0.124131277 9.88796817,0.397176238 9.97948361,0.746805669 C10.0709991,1.0964351 9.96674304,1.46823852 9.70678645,1.71931299 L5.70678645,5.71931299 C5.3162866,6.10969493 4.68328631,6.10969493 4.29278645,5.71931299 L0.292786454,1.71931299 C-0.0975954846,1.32881314 -0.0975954846,0.695812845 0.292786454,0.305312993 L0.292786454,0.305312993 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
                }
            }
        }
    }
}
