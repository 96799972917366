#tweakwise-guided-selling {
    @include breakpoint(medium) {
        .twn-question__grid {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
    @include breakpoint (small only) {
        .twn-question__grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            .twn-question-grid {
                &-item {
                    background-color: white;
                    &__title {
                        @include font-size(13px);
                        padding: 0;
                    }
                    &__caption,
                    &__summary {
                        padding-left: 5px;
                        padding-right: 5px;
                        @include font-size(13px);
                        line-height: 22px;
                    }
                }
            }
        }
    }

    .twn-question-grid-item {
        &__title {
            font-weight: 600;
            padding: 0;
        }
        &__summary {
            + .twn-question-grid-item__expand {
                &:after {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path fill="%23ec660d" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>');
                }

            }
        }
        &__caption,
        &__summary {
            color: color(base, DefaultFontColor);
            line-height: 20px;
        }
        &__expand {
            color: transparent;
            position: relative;
            width: 60px;
            text-wrap: nowrap;

            &:before {
                content:'Uitleg';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                color: color(base, Primary);
            }
            &:after {
                content: "";
                display: inline-block;
                width: 14px;
                height: 14px;
                position: absolute;
                right: 0;
                top: .1rem;
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="14" viewBox="0 0 512 512"><path fill="%23ec660d" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');

            }
        }
        &.is-selected {
            border: 1px solid color(base, Primary);
        }
    }
}
