.catalog__crosssell {
    max-width: 90rem;
    position: relative;

    .blaze-slider {
        button {
            &.blaze-prev {
                @include slider-button('prev');
                left: -1.5rem;
            }

            &.blaze-next {
                @include slider-button('next');
                right: -1.5rem;
            }
        }
    }

    .__item {
        display: flex;
        padding: 0 0 .1rem;

        .product__tile {
            @include product-tile();
            height: 100%;
            margin-bottom: 0;

            .product_footer .product_footer_left .price__holder {
                span {
                    &.--old {
                        font-size: 1.1rem;
                    }

                    &.--special {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }
}
