.store__overview {
    .page-title {
        padding: 0 0 30px;
        @include breakpoint(small only) {
            padding: 0;
        }
        h1 {
            text-transform: uppercase;
        }
    }
    .store__search {
        position: relative;
        @include breakpoint(small only) {
            margin: 10px 0;
        }
        input {
            height: 50px;
            border-radius: 0 3px 3px 0;
        }
        button {
            @include default-button();
            position: absolute;
            right: 0;
            top: 0;
            line-height: 50px;
            height: 50px;
            padding: 0 15px;
            i {
                @include font-size(12px);
                margin-left: 5px;
            }
        }
    }
    .store__search__result {
        margin: 0 0 10px;
        p {
            margin: 0 0 10px;
            font-size: 14px;
        }
        ul {
            list-style: none !important;
            padding: 0 !important;
            display: flex;
            flex-wrap: wrap;
            li {
                a {
                    display: block;
                    background: color(base, Primary);
                    font-weight: 600;
                    border-radius: 3px;
                    text-transform: uppercase;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    color: color(base, White);
                    font-size: 10px;
                    padding: 5px 10px;
                    line-height: normal;
                    span {
                        margin-left: 10px;
                        display: inline-block;
                    }
                    &:hover {
                        background: color(base, PrimaryHover);
                        color: color(base, White);
                    }
                }
            }
        }
    }
    .stores__map {
        padding: 0 30px 20px 0;
        @include breakpoint(medium down) {
            padding-right: 0;
        }
        #map {
            @include breakpoint(small only) {
                height: 300px;
            }
        }
    }
    h3 {
        @include font-size(24px);
        margin: 0 0 10px;
    }
    .store__list {
        span {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 18px;
        }
        ul {
            &.store__link-list {
                padding: 0;
                margin: 10px 0 20px 0;
                list-style: none;
            }
            li {

                a {
                    font-size: 14px;
                    line-height: 26px;
                    color: color(base, DefaultFontColor);
                    display: inline-block;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.store__banner{
    width: 100%;
}

.store__detail, .store__rating {
    .store__header {
        position: relative;
        overflow: hidden;
        .store__map_overlay {
            display: block;
            padding: 30px 0;
            @include breakpoint(small only) {
                padding: 40px 0;
            }
            position: absolute;
            text-align: center;
            left: 0;
            right: 0;
            top: -230px;
            bottom: 0;
            background: rgba(255, 255, 255, .6);
            z-index: 5;
            &._padding {
                padding: 15px 0;
            }
            &._loaded {
                top: 0;
                transition: all 0.3s ease-in-out;
            }
            h1 {
                color: color(base, DefaultFontColor);
                font-size: 44px;
                @include breakpoint(small only) {
                    @include font-size(28px);
                }
            }
            span {
                display: block;
                text-align: center;
                font-size: 18px;
                @include breakpoint(small only) {
                    font-size: 14px;
                }
                &.header__score {
                    display: inline-block;
                    background: color(base, Primary);
                    border-radius: 50%;
                    color: color(base, White);
                    line-height: 60px;
                    height: 60px;
                    width: 60px;
                    margin: 10px 0;
                    font-weight: 700;
                    font-size: 24px;
                }
            }
            .header__rating .rating {
                margin: 0;
                display: inline-block;
            }
            a {
                @include default-button();
                margin: 20px 0 0;
                padding: 5px 20px;
                height: auto;
                @include breakpoint(small only) {
                    padding: 5px 10px;
                }
                i {
                    margin-left: 5px;
                    position: relative;
                    top: 1px;
                }
            }
        }
        #map {
            height: 230px;
            width: 100%;
        }
        .rating__background {
            height: 230px;
            width: 100%;
            background: url(../images/rating-test.png) no-repeat top 20% center;
            background-size: cover;
        }
    }
    .store__random-ratings {
        padding: 30px;
        @include breakpoint(medium down) {
            padding: 15px;
        }
        @include breakpoint(small only) {
            padding: 10px 0;
        }
        ul {
            li {
                @include clearfix;
                position: relative;
                display: flex;
                span.rating__score {
                    background: color(Gray, Gray-100);
                    border-radius: 50%;
                    color: color(base, Primary);
                    font-weight: 600;
                    line-height: 80px;
                    width: 80px;
                    display: block;
                    text-align: center;
                    font-size: 30px;
                    @include breakpoint(medium down) {
                        width: 60px;
                        line-height: 60px;
                        font-size: 20px;
                    }
                    @include breakpoint(small only) {
                        height: auto;
                        width: auto;
                        line-height: 22px;
                        font-size: 16px;
                        background: none;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                }
                span.rating__holder {
                    max-width: 90%;
                    padding-left: 20px;
                    @include breakpoint(small only) {
                        max-width: 100%;
                        padding: 0;
                    }
                    span.rating__author {
                        display: block;
                        font-weight: 600;
                        color: color(base, Primary);
                        font-size: 16px;
                        line-height: normal;
                    }
                    span.rating__text {
                        @include font-size(14px);
                        display: inline-block;
                        line-height: 22px;
                        @include breakpoint(medium down) {
                            @include font-size(12px);
                        }
                    }
                }
            }
        }
    }
    .mobile_align {
        @include breakpoint(small only) {
            text-align: center;
        }
    }
    a.show__rating {
        @include default-button();
        margin: 52px 0;
        gap: 7px;
        align-items: center;
        @include breakpoint(medium down) {
            @include font-size(12px);
            margin: 30px 0;
        }
        @include breakpoint(small only) {
            margin: 0 0 10px;
        }
        .fa-thumbs-up {
            margin-right: 5px;
        }
        .fa-angle-right {
            margin-left: 5px;
            @include font-size(14px);
            position: relative;
            top: 1px;
        }
    }
    .store__content {
        padding: 30px 0;
        @include breakpoint(medium down) {
            padding: 20px 0;
        }
        border-top: 1px solid color(Gray, Gray-350);
        .feeddex__widget {
            @include clearfix;
            padding: 15px 15px 10px;
            margin: 10px 0;
            position: relative;
            background: color(Gray, Gray-100);
            .total__score {
                font-weight: 600;
            }
            ul {
                @include clearfix;
                @include breakpoint(small only) {
                    padding-left: 0;
                }
                li {
                    float: left;
                    width: 50%;
                    @include breakpoint(small only) {
                        width: 100%;
                    }
                    .title {
                        display: block;
                        font-size: 13px;
                    }
                    .rating__holder {
                        display: block;
                        width: 85%;
                        float: left;
                        height: 12px;
                        background: color(base, White);
                        border-radius: 3px;
                        margin: 3px 0;
                        .rating_inner {
                            height: 12px;
                            background: color(base, Green);
                            border-radius: 3px;
                            display: block;
                        }
                    }
                }
            }
            .score__block {
                line-height: 18px;
                font-weight: 600;
                font-size: 13px;
                display: inline-block;
                padding-left: 10px;
                @include breakpoint(small only) {
                    float: right;
                }
                .score__number {
                    color: color(base, Green);
                }
            }
            .more__reviews {
                display: block;
                color: color(base, Secondary);
                margin: 0;
                float: right;
                @include font-size(12px);
                @include breakpoint(small only) {
                    float: none;
                    margin: 10px 0;
                }
                &:hover {
                    color: color(base, PrimaryHover);
                }
            }
        }
        .store__description {
            .store__banner {
                width: 100%;
                @include breakpoint(small only) {
                    display: none;
                }
            }
            padding-right: 30px;
            @include breakpoint(medium down) {
                padding-right: 0;
            }
            img {
                margin: 0 0 15px;
            }
            h2 {
                font-weight: 600;
                font-size: 22px;
                margin: 0 0 5px;
                @include breakpoint(small only) {
                    display: none;
                }
            }
            h3 {
                color: color(base, DefaultFontColor);
                @include font-size(20px);
            }
            p {
                @include font-size(13px);
                margin: 0 0 15px;
                a {
                    margin: 0;
                }
            }
            a {
                @include font-size(13px);
                display: inline-block;
                margin: 0 0 15px;
                i {
                    margin-left: 5px;
                    @include font-size(12px);
                }
            }
            .note {
                background: color(Gray, Gray-100);
                padding: 15px 15px 5px;
                margin: 0 0 15px;
            }
        }
        .store__information__pane {
            font-size: 14px;
            background: color(Gray, Gray-100);
            padding: 20px;
            @include breakpoint(small only) {
                padding: 10px;
                text-align: center;
            }
            margin: 0 0 10px;
            address {
                font-style: normal;
                padding: 10px 0 0;
                line-height: 20px;
                a {
                    display: block;
                    margin: 2px 0;
                    line-height: 20px;
                    word-break: break-all;
                    @include breakpoint(small only) {
                        @include default-button();
                        display: table;
                        margin: 10px auto;
                    }
                    i {
                        @include font-size(12px);
                        margin-right: 5px;
                    }
                }
            }
            > a {
                display: block;
                text-decoration: underline;
                line-height: 22px;
                i {
                    margin-left: 5px;
                    @include font-size(12px);
                }
                &:last-of-type {
                    color: color(base, DefaultFontColor);
                    font-weight: 600;
                    font-size: 16px;
                    text-decoration: none;
                    margin: 20px 0 0;
                    i {
                        margin-right: 3px;
                        position: relative;
                        top: -1px;
                        margin-left: 0;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            a.cta {
                @include default-button();
                @include breakpoint(small only) {
                    display: block;
                }
                text-decoration: none;
                .fa-map-marker {
                    margin-right: 10px;
                }
                .fa-angle-right {
                    margin-left: 10px;
                    @include font-size(14px);
                    position: relative;
                    top: 1px;
                }
            }
            ul li {
                line-height: 22px;
                display: flex;
                justify-content: space-between;
                &.--active {
                    color: color(base, Green);
                }
                span {
                    display: inline-block;
                    line-height: 20px;
                    width: 50%;
                    &._hour {
                        text-align: right
                    }
                    @include breakpoint(small only) {
                        width: auto;
                        text-align: center;
                    }
                }
                small {
                    @include font-size(13px);
                }
            }
            span.title {
                font-weight: 700;
                text-transform: uppercase;
                color: color(base, Primary);
                font-size: 20px;
            }
            span.sub-title {
                font-weight: 600;
                margin: 20px 0 5px;
                display: block;
            }
        }
        .contact__fb-messenger {
            height: 28px;
            background: #0084ff;
            display: inline-block !important;
            color: color(base, White);
            line-height: 28px !important;
            text-align: center;
            border-radius: 3px;
            margin-top: 20px;
            cursor: pointer;
            padding: 0 10px;
            text-decoration: none !important;
            @include breakpoint(small only) {
                margin-top: 10px;
            }
            &:hover {
                background: #3d6ad6;
            }
            span:before {
                display: block;
                float: left;
                width: 16px;
                content: '';
                height: 16px;
                background: url('../images/facebook-messenger.svg');
                margin: 6px 6px 6px 0;
                background-size: contain;

            }
        }
        .contact__fb-messenger-info {
            @include font-size(13px);
            display: block;
            margin-top: 5px;
        }
        .store__route-planner_holder {
            padding-right: 30px;
            margin: 30px 0 0;
            @include breakpoint(small only) {
                padding-right: 0;
            }
            .store__route-planner {
                border: 1px solid color(Gray, Gray-350);
                padding: 20px;
                @include clearfix;
                @include breakpoint(small only) {
                    max-width: 100%;
                    padding: 10px;
                    background-image: none;
                }
                .text__holder {
                    float: left;
                    @include breakpoint(small only) {
                        float: none;
                        padding: 0;
                    }
                    h4 {
                        font-weight: 700;
                        color: color(base, DefaultFontColor);
                        text-transform: uppercase;
                        line-height: normal;
                        font-size: 20px;
                        @include breakpoint(medium down) {
                            font-size: 14px;
                        }
                    }
                    span {
                        line-height: normal;
                        @include font-size(13px);
                        @include breakpoint(medium down) {
                            @include font-size(10px);
                        }
                    }
                }
                .form__holder {
                    float: right;
                    @include breakpoint(small only) {
                        float: none;
                    }
                    position: relative;
                    input {
                        width: 475px;
                        height: 50px;
                        border-radius: 0 3px 3px 0;
                        @include breakpoint(medium down) {
                            width: 375px;
                        }
                        @include breakpoint(small only) {
                            width: 100%;
                        }
                    }
                    button {
                        @include default-button();
                        top: 0;
                        bottom: 0;
                        right: 0;
                        position: absolute;
                        line-height: 50px;
                        height: 50px;
                        @include breakpoint(medium down) {
                            @include font-size(12px);
                        }
                        i {
                            margin-left: 5px;
                            @include font-size(13px);
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
        }
        #route-map {
            margin: 20px 0;
        }
        .store__route__result {
            .result__route {
                h3 {
                    color: color(base, DefaultFontColor);
                    text-transform: uppercase;
                    font-weight: 700;
                    span {
                        color: color(base, Primary);
                    }
                }
                > span {
                    display: block;
                    font-size: 14px;
                    margin: 0 0 20px;
                    i {
                        color: color(base, Primary);
                        margin-right: 5px;
                        &:last-of-type {
                            margin-left: 5px;
                        }
                    }
                }
                ul {
                    border: 1px solid color(Gray, Gray-350);
                    li {
                        @include clearfix;
                        padding: 0 10px;
                        @include font-size(12px);
                        line-height: 34px;
                        border-bottom: 1px solid color(Gray, Gray-350);
                        &:last-of-type {
                            border-bottom: none;
                        }
                        &:nth-child(even) {
                            background: color(Gray, Gray-100);
                        }
                        span.maneuver {
                            display: block;
                            float: left;
                            width: 20px;
                        }
                        span.instruction {
                            display: block;
                            float: left;
                            max-width: 80%;
                        }
                        span.distance {
                            display: block;
                            float: right;
                            span.duration {
                                display: inline-block;
                                padding-left: 5px;
                                color: #454545;
                            }
                        }
                    }
                }
            }
        }
    }
    .review__list {
        margin: 20px 0;
        padding-right: 30px;
        @include breakpoint(medium down) {
            padding-right: 0;
        }
        > h3 {
            display: block;
            margin: 0 0 10px;
            color: color(base, DefaultFontColor);
            padding: 0 0 5px;
            border-bottom: 1px solid color(Gray, Gray-100);
        }
        .review__item {
            background: color(Gray, Gray-100);
            margin: 0 0 15px;
            padding: 10px;
            .review__head {
                h3 {
                    display: inline-block;
                    color: color(base, DefaultFontColor);
                    line-height: 30px;
                    @include font-size(18px);
                }
                .rating {
                    display: inline-block;
                    margin: 9px 5px;
                }
            }
            .review__content {
                @include font-size(12px);
                line-height: 22px;
            }
            .review__author {
                display: block;
                font-style: italic;
                text-align: right;
                @include font-size(11px);
                margin: 10px 0 0;
            }
        }
    }
    .rating__store__search {
        margin: 20px 0;
        .search__header {
            background: color(Gray, Gray-100);
            padding: 10px;
            input {
                padding: 8px 12px;
            }
        }
        h3 {
            display: block;
            margin: 0 0 10px;
        }
        ul {
            margin: 0 0 10px 0;
            background: color(Gray, Gray-100);
            li a {
                @include font-size(13px);
                color: color(base, DefaultFontColor);
                line-height: 34px;
                border-bottom: 1px solid color(base, White);
                display: block;
                padding: 0 10px;
                i {
                    margin-right: 8px;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .top__rating {
        border: 1px solid color(Gray, Gray-100);
        padding: 20px;
        .rating__title {
            font-weight: 700;
            text-transform: uppercase;
            color: color(base, Primary);
            display: block;
            font-size: 22px;
        }
        .rating__subtitle {
            text-transform: uppercase;
            display: block;
            font-size: 18px;
            position: relative;
            top: -4px;
        }
        .header__score {
            font-weight: 700;
            display: block;
            text-align: center;
            font-size: 42px;
            margin: 5px 0;
        }
        .header__rating {
            text-align: center;
            .rating._large {
                display: inline-block;
            }
        }
    }
    .review__first {
        padding: 10px;
        position: relative;
        .title {
            color: color(base, Primary);
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
        }
        .score {
            display: block;
            background: color(Gray, Gray-100);
            width: 60px;
            height: 60px;
            border-radius: 50%;
            font-weight: 600;
            text-align: center;
            line-height: 60px;
            font-size: 26px;
            float: right;
        }
        a {
            color: color(base, DefaultFontColor);
            @include font-size(12px);
            display: inline-block;
            margin: 20px 0 0;
            font-weight: 700;
        }
    }
}

.store__rating {
    .store__header, .review__first {
        padding-right: 30px;
        @include breakpoint(medium down) {
            padding-right: 0;
        }
    }
    .review__first p {
        max-width: 85%;
    }
    .store__header {
        margin: 0 0 20px;
        .header__desc {
            p {
                margin: 0 0 10px;
            }
            ._read-more {
                font-weight: 700;
                color: color(base, DefaultFontColor);
                display: inline-block;
                margin-left: 5px;
                i {
                    margin-left: 5px;
                }
            }
            ._more {
                display: none;
            }
        }
    }
}

.route_loading {
    display: block;
    text-align: center;
    padding: 10px 0;
    span {
        @include svg-loader;
        margin: 0 0 10px;
    }
}

.route__error span {
    @include font-size(12px);
    color: #a94442;
    padding: 5px 0 0;
}
