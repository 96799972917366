.product__specs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    ._content {
        max-height: 300px;
        overflow: hidden;
        h3 {
            font-weight: 600;
            font-size: 14px;
            margin: 20px 0 5px;
            @include breakpoint(medium) {
                margin: 20px 0 10px;
            }
        }
        dl {
            div {
                display: flex;
                padding: 7px 0;
                align-items: center;
                font-size: 13px;
                &:nth-child(odd) {
                    background: color(Gray, Gray-100);
                }
                dt {
                    padding: 8px;
                    width: 100%;
                    max-width: 50%;
                    color: color(base, DefaultFontColor);
                    flex: 1;
                }
                dd {
                    flex: 1;
                    &._value {
                        padding: 8px 8px 8px 0;
                        font-weight: 600;
                        svg {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }
    }
}

#offCanvas-specifications {
    h3 {
        font-weight: 600;
        font-size: 14px;
        margin: 20px 0 5px;
        @include breakpoint(medium) {
            margin: 20px 0 10px;
        }
    }
    dl {
        div {
            display: flex;
            padding: 7px 0;
            align-items: center;
            font-size: 13px;
            &:nth-child(odd) {
                background: color(Gray, Gray-100);
            }
            dt {
                width: 50%;
                color: color(base, DefaultFontColor);
                margin-right: 10px;
                text-align: left;
                -ms-hyphens: auto;
                -moz-hyphens: auto;
                -webkit-hyphens: auto;
                hyphens: auto;
            }
            dd {
                &._value {
                    font-weight: 600;
                }
            }
        }
    }
}
