.seo__block--homepage {
    display: flex;
    gap: 1rem;
    flex-flow: column;
    overflow: hidden;
    padding: 0 1rem;

    @include breakpoint(medium) {
        gap: 3rem;
        flex-flow: row wrap;
        padding: 0;
    }

    .--column {
        flex: 1;

        &.--image {
            > a, > div {
                @include border-radius();
                height: 100%;
                display: block;
                overflow: hidden;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        &.--content {
            height: 100%;

            .global__content {
                @include border-radius();
                padding: 4rem 5rem;
                background: color(Gray, Gray-100);
                height: 100%;
                @include breakpoint(small only) {
                    padding: 2rem;
                }

                a {
                    display: inline-block;
                }

                h1 {
                    @include font-size(18px);
                }
            }
        }
    }
}

.seo__block--block-wrapper {
    .--white {
        background: #fff;
    }

    .--gray {
        background: color(Gray, Gray-100);
    }

    .grid-x.grid-padding-x {
        margin-left: -1rem;
        margin-right: -1rem;

        .cell {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    a {
        display: inline-flex;
        font-size: 1.5rem;
        line-height: 2.6rem;

        @include breakpoint(medium) {
            font-size: 1.7rem;
            line-height: 2.8rem;
        }
    }

    p {
        font-size: 1.5rem;
        line-height: 2.6rem;

        @include breakpoint(medium) {
            font-size: 1.7rem;
            line-height: 2.8rem;
        }
    }

    em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    ul {
        padding-left: 1.6rem;
        list-style-type: disc;
        font-size: 1.5rem;
        line-height: 2.6rem;

        @include breakpoint(medium) {
            font-size: 1.7rem;
            line-height: 2.8rem;
        }
    }

    ol {
        padding-left: 1.6rem;
        list-style-type: decimal;
        font-size: 1.5rem;
        line-height: 2.6rem;

        @include breakpoint(medium) {
            font-size: 1.7rem;
            line-height: 2.8rem;
        }

        li {
            &[data-list="bullet"] {
                list-style: disc;
            }

            &[data-list="ordered"] {
                list-style: decimal;
            }
        }
    }

    .--column {
        &.--content {
            padding: 2rem;

            .global__content {
                > *:not(:last-child) {
                    margin-bottom: 1.6rem;
                }

                a {
                    display: inline-block;

                    .fa-arrow-right {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23fff' d='M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z'/%3E%3C/svg%3E");
                        background-position: center;
                        background-size: contain;
                        margin-bottom: -2px;
                        padding: 0 0.5rem 0 1.6rem !important;
                    }
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 1.6rem;

                    th, td {
                        padding: 1rem;
                        border-bottom: 1px solid color(Gray, Gray-300);
                    }

                    th {
                        background: color(Gray, Gray-100);
                    }

                    td {
                        background: color(Gray, Gray-200);
                    }
                }

                .button {
                    @include default-button();
                    background-image: url("data:image/svg+xml,%3Csvg width='21' height='23' viewBox='0 0 21 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_15_2)'%3E%3Cpath d='M13.7363 7.7851L20.195 11.4304L13.7337 15.0571L13.7363 7.7851Z' fill='white'/%3E%3Cpath d='M5.11455 19.8463L0.933228 22.193L0.941228 0.59967L5.12122 2.95701L5.11455 19.8463Z' fill='white'/%3E%3Cpath d='M7.25909 18.7162L7.2591 4.16553L11.5391 6.57886L11.5631 16.2282L7.25909 18.7162Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_15_2'%3E%3Crect width='21' height='23' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                    background-position: right 1rem center;
                    background-size: 1.3rem;
                    background-repeat: no-repeat;
                    padding-right: 3.3rem;
                }
            }
        }
        &.--youtube {
            iframe {
                max-width: 100%;
            }
        }
        .--image {
            @include border-radius();
            overflow: hidden;
            margin-bottom: 2rem;
        }

        &.--image {
            > div {
                @include border-radius();
                display: flex;
                overflow: hidden;
            }
        }
    }

    .--collapsable {
        > .cell {
            @include breakpoint(small only) {
                overflow: hidden;
                position: relative;
            }
        }

        &.is-collapsable {
            > .cell {
                @include breakpoint(small only) {
                    max-height: 75px;
                    transition: max-height 0.3s ease;
                    overflow: hidden;
                    &:after {
                        content: "";
                        display: block;
                        height: 30px;
                        width: 100%;
                        position: absolute;
                        background: linear-gradient(0deg, #fff, hsla(0, 0%, 96.5%, .5));
                        bottom: 0;
                    }

                }
            }

            ._actions {
                .--button {
                    &.--open {
                        display: none;
                    }
                }
            }

            &.--open {
                > .cell {
                    @include breakpoint(small only) {
                        max-height: fit-content;

                        &:after {
                            display: none;
                        }
                    }
                }

                ._actions {
                    .--button {
                        display: none;

                        &.--open {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    ._actions {
        display: flex;
        width: 100%;
        align-content: center;
        justify-content: center;
        margin-top: 3rem;
    }
}

