.blogs-overview {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
}
.blog-item {
    display: block;
    border: 2px solid color(Gray, Gray-200);
    min-height: 100%;
    background-color: color(base, White);
    &__wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
    }
    &__type-label {
        margin-top: -5px;
        border-bottom-right-radius: 10px;
        position: absolute;
        font-size: 13px;
        font-weight: 600;
        padding: 5px;
        border: 2px solid white;
        margin-left: -2px;
        color: rgb(239, 239, 239);
        background-color: rgb(236, 102, 13);
        text-align: center;
        z-index:1;
    }
    &__date {
        color: color(base, DefaultFontColor);
        font-size: 1.2rem;
        font-weight: 600;
        font-style: italic;
        margin: 1rem 0;
        padding: 0 2rem;
    }
    &__content {
        padding: 1rem 2rem 2rem;
        span {
            color: color(base, DefaultFontColor);
            line-height: 2.2rem;
        }
        .link {
            display: inline-block;
            color: color(base, Primary);
            cursor: pointer;
        }
    }
}
