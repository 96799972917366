.grid {
    display: grid;

    .large-column-1-2 {
        grid-column: 1 / 2;
        @include breakpoint(small only) {
            grid-column: unset;
        }
    }

    .large-column-2-3 {
        grid-column: 2 / 3;
        @include breakpoint(small only) {
            grid-column: unset;
        }
    }

    .large-row-1-2 {
        grid-row: 1 / 2;
        @include breakpoint(small only) {
            grid-row: unset;
        }
    }

    @include breakpoint(small only) {
        .small-column-1-2 {
            grid-column: 1 / 2;
        }

        .small-column-1-3 {
            grid-column: 1 / 3;
        }

        .small-column-2-3 {
            grid-column: 2 / 3;
        }

        .small-row-1-2 {
            grid-row: 1 / 2;
        }
    }
}

._content,
.content {
    &.--column {
        height: 100%;
    }
    p {
        margin-bottom: 2rem;
    }
    table {
        width: 100%;
        @include font-size(13px);
        td {
            line-height: 2.1rem;
        }
    }

    ul {
        color: color(base, DefaultFontColor);
        list-style: disc outside;
        margin: 0 0 20px 28px;

        li {
            @include font-size(14px);
        }

        &.list__bullet {
            list-style: none;
            margin: 0 0 20px 10px;

            li {
                position: relative;
                padding-left: 18px;

                &:before {
                    position: absolute;
                    left: 0;
                    content: "•";
                    color: color(base, Primary);
                }
            }
        }

        &.list__check {
            list-style-type: none !important;
            margin: 0 0 20px 10px !important;
            padding: 0 !important;

            li {
                position: relative;
                padding-left: 18px;

                &:before {
                    position: absolute;
                    left: -3px;
                    color: color(base, Primary);
                    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="12.25" viewBox="0 0 448 512"><path fill="%23ec660d" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>') no-repeat;
                    content: '';
                    width: 12px;
                    height: 14px;
                    top: 4px;
                }
            }
        }
    }
}
