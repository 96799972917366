footer {
    form .field__container {
        padding: 0;
    }

    .top__footer {
        background: color(base, Primary);
        color: color(base, White);
        padding: 4rem 0;

        p {
            margin: 0 0 1.5rem;
        }

        a {
            color: color(base, White);
            font-weight: 600;
            @include link-arrow-right();
            padding: 1.5rem 1.5rem 1.5rem 0;
        }

        .footer__form {
            margin-right: 7rem;
            @include breakpoint(small only) {
                margin-right: 0;
            }

            form {
                position: relative;

                input {
                    border: none;
                    padding-right: 5.5rem;
                }

                button {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: calc(4rem + 1px);
                    height: 4rem;
                    border-left: .1rem solid color(Gray, Gray-200);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        width: .8rem;
                    }
                }
            }
        }
    }

    .middle__footer {
        padding: 4rem 0;

        @include breakpoint(small only) {
            padding: 0;

            svg {
                width: 3.5rem;
                height: 3.5rem;
            }

            ul {
                justify-content: center;
                padding: 1.2rem 0;
                border-bottom: 1px solid color(Gray, Gray-200);
                margin: 0 -1rem 0.7rem;
            }
            .safe {
                text-align: right;
                img {
                    height: 20px;
                    width: auto;
                }
            }
        }

        .fa {
            width: 30px;
            height: 30px;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            &.fa-facebook-official {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%233b5998' d='M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64h98.2V334.2H109.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H255V480H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z'/%3E%3C/svg%3E");
            }
            &.fa-youtube-play {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23cd201f' d='M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z'/%3E%3C/svg%3E");
            }
            &.fa-instagram {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill='%23ec660d' d='M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z'/%3E%3C/svg%3E");
            }
        }

        h3 {
            color: color(base, DefaultFontColor);
        }

        ul {
            display: flex;
            gap: 20px;
        }
    }

    .bottom__footer {
        padding: 4rem 0 5rem;
        background: color(Gray, Gray-100);
        border-top: .1rem solid color(Gray, Gray-200);
        border-bottom: .1rem solid color(Gray, Gray-200);
        @include breakpoint(small only) {
            padding: 0;
            border-bottom: 0;
        }

        .mobile-footer {
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            li {
                &.--active {
                    a[href="#"] {
                        &::after {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                        }
                    }
                    ul {
                        display: block;
                    }
                }
                a {
                    border-bottom: 1px solid color(Gray, Gray-200);
                    line-height: 40px;
                    padding: 0 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &[href="#"] {
                        &::after {
                            content: '';
                            width: 16px;
                            height: 16px;
                            display: inline-block;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                            background-position: center;
                            background-size: 100%;
                            background-repeat: no-repeat;
                        }
                    }
                }

                ul {
                    transition: height .2s ease;
                    overflow: hidden;
                    height: 0;
                }
            }
        }

        span {
            display: block;
            @include font-size(16px);
            font-weight: 700;
            margin: 2rem 0 1rem 0;
        }

        a {
            line-height: 2.8rem;
            color: color(base, DefaultFontColor);
        }
    }

    .absolute__footer {
        padding: 3.2rem 0;

        > div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 2rem;
            justify-content: center;
            @include breakpoint(large) {
                justify-content: flex-start;
            }

            > a {
                display: flex;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 1rem;
                justify-content: center;
                @include breakpoint(large) {
                    justify-content: flex-start;
                    gap: 2rem;
                }

                li a {
                    color: color(base, DefaultFontColor);
                    display: block;
                    @include font-size(13px);
                    line-height: 3.1rem;


                    @include hover() {
                        text-decoration: underline;
                    }
                }
            }

            > span {
                @include font-size(13px);
                line-height: 3.1rem;
                color: color(Gray, Gray-600);
                @include breakpoint(large) {
                    margin-left: auto;
                }

                > a {
                    @include font-size(13px);
                    line-height: 3.1rem;
                    text-decoration: underline;
                    color: color(Gray, Gray-600);
                }
            }
        }
    }

    .checkout-checkout_body & {
        .middle__footer {
            background-color: color(base, White);
        }

        .bottom__footer {
            padding: 3rem 0;
            margin-top: 2rem;

            h4 {
                color: color(base, DefaultFontColor);
                font-size: 1.8rem;
                font-weight: 600;
            }

            .--actions {
                display: flex;
                gap: 1rem;
                justify-content: center;

                a:not(:last-child)::after {
                    content: '|';
                    margin-left: 1rem;
                    pointer-events: none;
                    color: color(Gray, Gray-500);
                }
            }
        }
    }
}
