.personal__advice {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include breakpoint(small only) {
        border-bottom: 1px solid color(base, Primary);
        padding-bottom: 20px;
    }
    ._flex-holder {
        display: flex;
        gap: 10px;
        ._content {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            line-height: 2.2rem;
            > a {
                display: inline-block;
                color: color(base, DefaultFontColor);
                font-weight: 600;
                &:last-child {
                    display: flex;
                    align-items: center;
                    &::after {
                        content: '';
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 16px;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        ._img {
            display: flex;
            align-items: center;
            flex: 0 0 25%;
        }
    }
}
