.catalog__category-list {
    .grid-x.grid-padding-x {
        .catalog__banner {
            display: flex;
            &.medium-24 {
                a {
                    img.hide-for-small-only,
                    img.hide-for-medium {
                        position: relative;
                        object-fit: initial;
                        left: auto;
                        top: auto;
                        height: auto;
                    }
                }
            }
            a {
                display: block;
                position: relative;
                flex: 1;
                overflow: hidden;
                margin-bottom: 1rem;

                img {
                    width: 100%;

                    @include breakpoint(medium) {
                        position: absolute;
                        object-fit: contain;
                        left: 0;
                        top: 0;
                        height: 100%;
                    }
                }
            }
        }
    }
}
