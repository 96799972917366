.catalog-category-page {
    @include breakpoint(small only) {
        background: color(Gray, Gray-100);
    }
}

.category__placeholder {
    animation: blinker 2s linear infinite;
    ul {
        li {
            ._placeholder {
                border: 1px solid color(Gray, Gray-100);
                display: block;
                background-color: color(base, White);
                flex: 0 0 100%;
                aspect-ratio: 1/2;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 205.6 430.6' style='enable-background:new 0 0 205.6 430.6;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F6F6F6;%7D%0A%3C/style%3E%3Cg%3E%3Crect class='st0' width='205.6' height='205.6'/%3E%3Crect y='220.2' class='st0' width='205.6' height='27.7'/%3E%3Crect y='252.9' class='st0' width='118.5' height='18.5'/%3E%3Crect y='276.5' class='st0' width='74.5' height='18.5'/%3E%3Crect y='387.1' class='st0' width='44.3' height='18.5'/%3E%3Crect x='19.7' y='415.4' class='st0' width='88.8' height='15.3'/%3E%3Crect x='18.1' y='350.4' class='st0' width='87.2' height='18.5'/%3E%3Crect x='0.7' y='350.4' class='st0' width='13.7' height='18.5'/%3E%3Crect x='18.1' y='328.8' class='st0' width='62' height='18.5'/%3E%3Crect x='0.7' y='328.8' class='st0' width='13.7' height='18.5'/%3E%3Crect x='18.1' y='307.1' class='st0' width='77.6' height='18.5'/%3E%3Crect x='0.7' y='307.1' class='st0' width='13.7' height='18.5'/%3E%3Cpath class='st0' d='M202,430.5h-29.6c-1.2,0-2.3-1-2.3-2.3v-29.6c0-1.2,1-2.3,2.3-2.3H202c1.2,0,2.3,1,2.3,2.3v29.6 C204.2,429.5,203.2,430.5,202,430.5z'/%3E%3Cpath class='st0' d='M157.9,430.5h-29.6c-1.2,0-2.3-1-2.3-2.3v-29.6c0-1.2,1-2.3,2.3-2.3h29.6c1.2,0,2.3,1,2.3,2.3v29.6 C160.2,429.5,159.1,430.5,157.9,430.5z'/%3E%3Cpath class='st0' d='M13,430.5H2.3c-1.2,0-2.3-1-2.3-2.3v-10.8c0-1.2,1-2.3,2.3-2.3H13c1.2,0,2.3,1,2.3,2.3v10.8 C15.3,429.5,14.3,430.5,13,430.5z'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-size: 90%;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 0.5rem;
                margin-top: 0.5rem;
                @include breakpoint(small only) {
                    aspect-ratio: 40/18;
                    background-size: 95%;
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 516.1 210.3' style='enable-background:new 0 0 516.1 210.3;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23F6F6F6;%7D%0A%3C/style%3E%3Crect class='st0' width='210.4' height='210.4'/%3E%3Crect x='240.4' class='st0' width='205.6' height='27.7'/%3E%3Crect x='240.4' y='32.8' class='st0' width='118.5' height='18.5'/%3E%3Crect x='240.4' y='56.3' class='st0' width='74.5' height='18.5'/%3E%3Crect x='240.4' y='183.9' class='st0' width='44.3' height='18.5'/%3E%3Crect x='258.4' y='141.2' class='st0' width='87.2' height='18.5'/%3E%3Crect x='241.1' y='141.2' class='st0' width='13.7' height='18.5'/%3E%3Crect x='258.4' y='119.6' class='st0' width='62' height='18.5'/%3E%3Crect x='241.1' y='119.6' class='st0' width='13.7' height='18.5'/%3E%3Crect x='258.4' y='97.9' class='st0' width='77.6' height='18.5'/%3E%3Crect x='241.1' y='97.9' class='st0' width='13.7' height='18.5'/%3E%3Cpath class='st0' d='M513.3,210.3h-29.6c-1.2,0-2.3-1-2.3-2.3v-29.6c0-1.2,1-2.3,2.3-2.3h29.6c1.2,0,2.3,1,2.3,2.3v29.6 C515.6,209.3,514.6,210.3,513.3,210.3z'/%3E%3Cpath class='st0' d='M469.3,210.3h-29.6c-1.2,0-2.3-1-2.3-2.3v-29.6c0-1.2,1-2.3,2.3-2.3h29.6c1.2,0,2.3,1,2.3,2.3v29.6 C471.5,209.3,470.5,210.3,469.3,210.3z'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
}

.category__header {
    h1 {
        @include font-size(26px);
        color: color(base, DefaultFontColor);
        font-weight: 700;
        margin-top: 0;
    }
    a {
        display: inline-block;

        .fa.fa-arrow-right {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="12.25" viewBox="0 0 448 512"><path fill="%23f58220" d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg>');
            height: 1.5rem;
            display: inline-block;
            position: relative;
            top: 0.3rem;
        }
    }
}

.category__footer {
    padding: 24px 0;
}

.category_description_bottom {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    border: 1px solid color(Gray, Gray-100);
    padding: 1rem 2rem;
    margin: 2rem 0;
    background: color(base, White);
    .cell {
        padding: 1rem 0;
    }
    h3 {
        @include font-size(12px);
        color: color(base, DefaultFontColor);
        margin-bottom: 1em;
    }
    a {
        @include font-size(12px);
        color: color(base, Primary);

        &:hover {
            color: color(base, PrimaryHover);
        }
    }
}

.active__filter__container {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    button {
        padding: 0.4em 20px 0.4em 0.4em;
        background-color: color(base, Secondary);
        border-radius: 3px;
        color: color(base, White);
        text-transform: uppercase;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
        background-position: center right 0.4em;
        background-repeat: no-repeat;
        background-size: 13px;
        &:hover {
            background-color: color(base, SecondaryHover);
        }
    }
}
