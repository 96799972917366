form, .form {
    padding-top: 6px;
    padding-bottom: 6px;
    color: color(base, DefaultFontColor);
    label {
        display: none;
    }
    .field__container,
    .field__actions,
    h3 {
        padding: 6px;
        flex: 1;
        &.--flex {
            &:has(.field__container) {
                padding: 0;
            }
            & > .field__container {
                padding: 6px;
            }
        }
        &:has(input[type="checkbox"]) {
            input[type="checkbox"] {
                &:checked + label {
                    &::before {
                        background-color: color(base, Primary);
                        border: 1px solid color(base, Primary);
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23fff' d='m382-354 339-339q12-12 28.5-12t28.5 12q12 12 12 28.5T778-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z'/%3E%3C/svg%3E");
                        background-position: center;
                        background-size: 100%;
                    }
                }
            }
            label {
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 100%;
                padding: 6px;
                @include font-size(14px);
                &::before {
                    content: '';
                    width: 2.4rem;
                    height: 2.4rem;
                    border: 1px solid color(Gray, Gray-300);
                    border-radius: 3px;
                    margin-right: 6px;
                    margin-top: -.3rem;
                }
            }
        }

        &.icon-right {
            &:has(input[type="checkbox"]) {
                input[type="checkbox"] {
                    &:checked + label {
                        &::before {
                            display: none;
                        }
                        &::after {
                            background-color: color(base, Primary);
                            border: 1px solid color(base, Primary);
                            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23fff' d='m382-354 339-339q12-12 28.5-12t28.5 12q12 12 12 28.5T778-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z'/%3E%3C/svg%3E");
                            background-position: center;
                            background-size: 100%;
                            right: 5px;
                            left: unset;
                        }
                    }
                }
                label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    width: 100%;
                    padding: 6px;
                    @include font-size(14px);
                    &::before {
                        display: none;
                    }
                    &::after {
                        content: '';
                        width: 2.4rem;
                        height: 2.4rem;
                        border: 1px solid color(Gray, Gray-300);
                        border-radius: 3px;
                        margin-left: 6px;
                        margin-top: -.4rem;
                    }
                }
            }
        }
    }
}

.checkout__address-autocomplete,
.address-autocomplete_result {
    .resolved__address {
        width: 60%;
        background: color(base, White);
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
        margin: .6rem 0;
        padding: 2rem;
        line-height: 20px;
        border-radius: .5rem;
        @include breakpoint(small only) {
            width: calc(100% - 10px );
        }

        > span {
            font-weight: bold;
            margin-bottom: 10px;
        }

        label.--cta {
            display: block;
            position: unset;
            padding: 0;
            pointer-events: auto;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        address {
            @include font-size(14px);
        }
    }
}
.address-autocomplete_result {
    padding: 0 6px;
}

.field__container {
    &.--payment-method {
        border: 1px solid color(Gray, Gray-300);
        padding: 12px 4px;
        border-radius: 5px;
        margin: 12px 0;

        label {
            display: flex;
            align-items: center;
            padding-left: 10px;

            &:before {
                margin-right: 12px;
            }

            img {
                max-width: 65px;
                margin-right: 16px;
            }

            .--content {
                display: flex;
                flex-direction: column;

            }
            .--title {
                font-weight: 600;
            }
        }
        .payment__method__options {
            margin: 2.4rem 3rem;
            select {
                &.failed {
                    border-color: color(alert, Error);
                }
                & + .field-error {
                    color: color(alert, Error);
                    display: inline-block;
                    @include font-size(10px);
                    padding: 6px;
                }
            }
            .payment__method__option-holder span {
                padding: 6px 4px;
                display: block;
            }
        }
    }
}
