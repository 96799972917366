.nearest-store {
    @include breakpoint(small only) {
        margin: 0 0 20px;
    }
    h3 {
        padding-right: 100px;
    }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background: color(base, White);
        padding: 10px;
        max-height: 65px;
        border-radius: 3px;
        border-bottom-right-radius: 0;
        @include breakpoint(small only) {
            margin: 0 20px 0 0;
        }
        img {
            position: absolute;
            right: -20px;
            top: -105px;
        }
    }
    &__cta {
        display: flex;
        flex-direction: column;
        gap: 6px;
        a {
            display: block;
            color: color(base, DefaultFontColor) !important;
            @include font-size(13px);
            line-height: normal;
            padding: 2px 0 !important;
            @include breakpoint(medium down) {
                text-transform: capitalize;
            }
            i {
                margin-right: 8px;
            }
        }
    }
    .opening__time {
        color: color(base, Green);
        @include font-size(12px);
        font-weight: 700;
        padding: 12px 0;
        @include breakpoint(medium down) {
            text-align: center;
            padding: 5px 0;
        }
    }
    .edit__store {
        color: color(base, White);
        @include font-size(14px);
        text-decoration: underline;
        display: block;
        text-align: right;
        cursor: pointer;
        margin: 5px 0 0;
        @include breakpoint(small only) {
            margin: 5px 15px 0 0;
        }
    }
}

