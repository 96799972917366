.rating {
    display: flex;
    align-items: center;
    .rating__block {
        width: 84px;
        height: 15px;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='84px' height='16px' viewBox='0 0 84 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' stroke='%23FFC400'%3E%3Cpath d='M8,1.12977573 L10.0191083,5.22093591 L14.5339716,5.87698395 L11.2669858,9.06150803 L12.0382165,13.5581282 L8,11.4351121 L3.96178349,13.5581282 L4.73301422,9.06150803 L1.46602844,5.87698395 L5.98089175,5.22093591 L8,1.12977573 Z' id='Star'%3E%3C/path%3E%3Cpath d='M42,1.12977573 L44.0191083,5.22093591 L48.5339716,5.87698395 L45.2669858,9.06150803 L46.0382165,13.5581282 L42,11.4351121 L37.9617835,13.5581282 L38.7330142,9.06150803 L35.4660284,5.87698395 L39.9808917,5.22093591 L42,1.12977573 Z' id='Star-Copy-2'%3E%3C/path%3E%3Cpath d='M25,1.12977573 L27.0191083,5.22093591 L31.5339716,5.87698395 L28.2669858,9.06150803 L29.0382165,13.5581282 L25,11.4351121 L20.9617835,13.5581282 L21.7330142,9.06150803 L18.4660284,5.87698395 L22.9808917,5.22093591 L25,1.12977573 Z' id='Star-Copy'%3E%3C/path%3E%3Cpath d='M59,1.12977573 L61.0191083,5.22093591 L65.5339716,5.87698395 L62.2669858,9.06150803 L63.0382165,13.5581282 L59,11.4351121 L54.9617835,13.5581282 L55.7330142,9.06150803 L52.4660284,5.87698395 L56.9808917,5.22093591 L59,1.12977573 Z' id='Star-Copy-3'%3E%3C/path%3E%3Cpath d='M76,1.12977573 L78.0191083,5.22093591 L82.5339716,5.87698395 L79.2669858,9.06150803 L80.0382165,13.5581282 L76,11.4351121 L71.9617835,13.5581282 L72.7330142,9.06150803 L69.4660284,5.87698395 L73.9808917,5.22093591 L76,1.12977573 Z' id='Star-Copy-4'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        display: block;
        .rating__block_fill {
            height: 15px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='84px' height='16px' viewBox='0 0 84 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%23FFC400' stroke='%23FFC400'%3E%3Cpath d='M8,1.12977573 L10.0191083,5.22093591 L14.5339716,5.87698395 L11.2669858,9.06150803 L12.0382165,13.5581282 L8,11.4351121 L3.96178349,13.5581282 L4.73301422,9.06150803 L1.46602844,5.87698395 L5.98089175,5.22093591 L8,1.12977573 Z' id='Star'%3E%3C/path%3E%3Cpath d='M42,1.12977573 L44.0191083,5.22093591 L48.5339716,5.87698395 L45.2669858,9.06150803 L46.0382165,13.5581282 L42,11.4351121 L37.9617835,13.5581282 L38.7330142,9.06150803 L35.4660284,5.87698395 L39.9808917,5.22093591 L42,1.12977573 Z' id='Star-Copy-2'%3E%3C/path%3E%3Cpath d='M25,1.12977573 L27.0191083,5.22093591 L31.5339716,5.87698395 L28.2669858,9.06150803 L29.0382165,13.5581282 L25,11.4351121 L20.9617835,13.5581282 L21.7330142,9.06150803 L18.4660284,5.87698395 L22.9808917,5.22093591 L25,1.12977573 Z' id='Star-Copy'%3E%3C/path%3E%3Cpath d='M59,1.12977573 L61.0191083,5.22093591 L65.5339716,5.87698395 L62.2669858,9.06150803 L63.0382165,13.5581282 L59,11.4351121 L54.9617835,13.5581282 L55.7330142,9.06150803 L52.4660284,5.87698395 L56.9808917,5.22093591 L59,1.12977573 Z' id='Star-Copy-3'%3E%3C/path%3E%3Cpath d='M76,1.12977573 L78.0191083,5.22093591 L82.5339716,5.87698395 L79.2669858,9.06150803 L80.0382165,13.5581282 L76,11.4351121 L71.9617835,13.5581282 L72.7330142,9.06150803 L69.4660284,5.87698395 L73.9808917,5.22093591 L76,1.12977573 Z' id='Star-Copy-4'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            display: block;
        }
    }
    .rating__count {
        @include font-size(14px);
        color: color(base, DefaultFontColor);
        span {
            margin-left: 3px;
        }
    }
}
