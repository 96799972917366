.product__countdown {
    margin: .5rem 0 .5rem;

    .--title {
        display: block;
        font-weight: 600;
        font-size: 1.3rem;
        padding: 0 0 .8rem;
        color: color(base, Anthracite);
    }

    .time__container {
        display: flex;
        align-items: center;
        gap: 1rem;

        .--time {

            .--digit {
                @include border-radius(.5rem);
                display: block;
                margin: 0 auto;
                font-weight: 700;
                background: color(base, Primary);
                color: color(base, White);
                text-align: center;
                font-size: 2rem;
                line-height: 3.2rem;
                padding: 0 1rem;
            }

            .--text {
                font-size: 1rem;
                display: block;
                text-align: center;
                padding: .2rem 0 0;
                color: color(base, Anthracite);
            }
        }
    }
}
