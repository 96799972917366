.benefit__usp {
    margin: 20px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid color(base, Primary);
    .benefit__usp-container {
        @include breakpoint(small only) {
            overflow-y: scroll;
            margin: 0 -10px;
            padding: 0 10px 20px 10px;
        }
        ul {
            display: flex;
            margin: 10px -5px 0 -10px;
            li {
                flex: 0 0 33.33%;
                max-width: 33.33%;
                padding: 0 5px;
                @include breakpoint(small only) {
                    min-width: 200px;
                }
                img {
                    border-radius: .5rem;
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

.service__usp {
    @include border(0 0 1px, color(base, Primary));
    padding: 20px 0;
}

.product__usp {
    @include list("checked-icon");
}
