@keyframes shake-input {
    0% {
        transform: translate(10px);
    }
    20% {
        transform: translate(-10px);
    }
    40% {
        transform: translate(5px);
    }
    60% {
        transform: translate(-5px);
    }
    80% {
        transform: translate(2px);
    }
    100% {
        transform: translate(0px);
    }
}

@keyframes loading {
    0% {transform: rotate(0deg)}
    100% {transform: rotate(360deg)}
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes blinker {
    50% {
        opacity: 0.2;
    }
}
