.catalog-category__toolbar {
    padding: 24px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @include font-size(12px);
    &.--top {
        .left_column {
            @include breakpoint(small only) {
                flex: 1;
            }
        }
    }

    &.--bottom {
        .show-for-small-only {
            display: none;
        }

        .left_column {
            @include breakpoint(small only) {
                display: none;
            }
        }
        .right_column {
            @include breakpoint(small only) {
                display: flex;
                flex: 0 0 100%;
                justify-content: center;
            }
        }
    }

    .top_column {
        flex: 0 0 100%;
    }

    .left_column {
        display: flex;
        @include breakpoint(small only) {
            justify-content: flex-end;
        }
        .catalog-category__sorter,
        .catalog-category__page-size,
        .catalog-category__page-count {
            color: color(Gray, Gray-500);
            display: flex;
            align-items: center;
            padding: 0 16px;
            border-right: 1px solid color(Gray, Gray-300);
            @include breakpoint(small only) {
                justify-content: flex-end;
                padding: 0;
            }
            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                border: 0;
            }
            label {
                white-space: nowrap;
            }
            select {
                padding: 0;
                color: color(base, DefaultFontColor);
                font-weight: 600;
                height: auto;
                border: 0;
                padding: 4px 24px 4px 4px;
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D1D1F;%7D%0A%3C/style%3E%3Cpath class='st0' d='M11.7,12.9L15.6,9c0.18-0.18,0.42-0.28,0.7-0.28S16.82,8.82,17,9s0.27,0.42,0.27,0.7s-0.09,0.52-0.27,0.7 L12.4,15c-0.1,0.1-0.21,0.17-0.32,0.21s-0.24,0.06-0.38,0.06s-0.26-0.02-0.38-0.06S11.1,15.1,11,15l-4.6-4.6 c-0.18-0.18-0.27-0.42-0.27-0.7S6.22,9.18,6.4,9c0.18-0.18,0.42-0.28,0.7-0.28c0.28,0,0.52,0.09,0.7,0.28L11.7,12.9z'/%3E%3C/svg%3E%0A");
                background-position: center right;
                background-repeat: no-repeat;
                background-size: 20px;
                background-color: transparent;
            }
            strong {
                &:first-of-type {
                    margin-right: 0.2em;
                }
                &:last-of-type {
                    margin-left: 0.2em;
                }
            }
        }
    }

    .right_column {
        .pagination {
            ul {
                display: flex;
                align-items: center;
                line-height: 0;
                li {
                    display: inline-block;
                    a,
                    span {
                        display: inline-block;
                        text-align: center;
                        line-height: 24px;
                        background: color(Gray, Gray-100);
                        min-width: 24px;
                        height: 24px;
                        margin-left: 5px;
                        color: color(Gray, Gray-500);
                        @include font-size(11px);
                        padding: 0 5px;
                        border-bottom: 2px solid transparent;
                        @include breakpoint(small only) {
                            line-height: 35px;
                            min-width: 35px;
                            height: 35px;
                            @include font-size(13px);
                        }
                        &:hover {
                            background: color(base, Primary);
                            color: color(base, White);
                        }
                        &.--next {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23626c77' d='M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z'/%3E%3C/svg%3E");
                            background-size: 70%;
                            background-position: center;
                            background-repeat: no-repeat;
                            &:hover {
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23ffffff' d='M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z'/%3E%3C/svg%3E");
                            }
                        }
                        &.--prev {
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23626C77;%7D%0A%3C/style%3E%3Cpath class='st0' d='M10.8,12l3.9,3.9c0.18,0.18,0.28,0.42,0.28,0.7s-0.09,0.52-0.28,0.7c-0.18,0.18-0.42,0.28-0.7,0.28 s-0.52-0.09-0.7-0.28l-4.6-4.6c-0.1-0.1-0.17-0.21-0.21-0.32C8.45,12.26,8.43,12.13,8.43,12c0-0.13,0.02-0.26,0.06-0.38 C8.53,11.51,8.6,11.4,8.7,11.3l4.6-4.6c0.18-0.18,0.42-0.28,0.7-0.28s0.52,0.09,0.7,0.28c0.18,0.18,0.28,0.42,0.28,0.7 c0,0.28-0.09,0.52-0.28,0.7L10.8,12z'/%3E%3C/svg%3E%0A");
                            background-size: 70%;
                            background-position: center;
                            background-repeat: no-repeat;
                            &:hover {
                                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 28.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ffffff;%7D%0A%3C/style%3E%3Cpath class='st0' d='M10.8,12l3.9,3.9c0.18,0.18,0.28,0.42,0.28,0.7s-0.09,0.52-0.28,0.7c-0.18,0.18-0.42,0.28-0.7,0.28 s-0.52-0.09-0.7-0.28l-4.6-4.6c-0.1-0.1-0.17-0.21-0.21-0.32C8.45,12.26,8.43,12.13,8.43,12c0-0.13,0.02-0.26,0.06-0.38 C8.53,11.51,8.6,11.4,8.7,11.3l4.6-4.6c0.18-0.18,0.42-0.28,0.7-0.28s0.52,0.09,0.7,0.28c0.18,0.18,0.28,0.42,0.28,0.7 c0,0.28-0.09,0.52-0.28,0.7L10.8,12z'/%3E%3C/svg%3E%0A");
                            }
                        }
                        &.--current {
                            background: color(base, White);
                            border-bottom: 2px solid color(base, Primary);
                            color: color(Gray, Gray-500);
                        }
                    }
                }
            }
        }
    }
}
