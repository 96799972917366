.page__builder-hero--wrapper {
    .main__banner {
        @include border-radius();
        display: flex;
        height: 100%;
        overflow: hidden;
        position: relative;

        picture {
            display: flex;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        > a {
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            @include font-size(18px);
            line-height: 4rem;
            padding: 0 2rem;
        }
    }

    .banner__navigation {
        display: flex;
        flex-flow: column;
        gap: 1rem;
        height: 100%;
        @include breakpoint(small only) {
            flex-flow: row;
            overflow-y: scroll;
            margin-left: -1rem;
            margin-right: -1rem;
            padding: 1rem;
        }

        .hero__row {
            @include border-radius();
            @include transition();
            flex: 1;
            display: flex;
            gap: 1rem;
            align-items: center;
            background: color(Gray, Gray-100);
            padding: 0 2rem;
            @include breakpoint(small only) {
                flex: 0 0 90vw;
                max-width: 350px;
            }

            .image__holder {
                display: block;
                flex: 0 0 25%;
            }

            .--content {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding: 1rem 0;

                h3 {
                    font-weight: 700;
                    @include font-size(18px);
                    line-height: 2.2rem;
                    margin: 0 0 1rem;
                }

                p {
                    @include font-size(13px);
                    line-height: normal;
                }
            }

            @include hover() {
                background: color(Gray, Gray-150);
                cursor: pointer;
            }
        }
    }
}
.home .page__builder-hero--wrapper {
    .--header {
        h2 {
            display: none;
        }
    }
}
