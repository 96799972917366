.product__compare-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 8000;

    .product__compare-overlay {
        position: fixed;
        z-index: 80000;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, .35);
    }

    .product__compare-block {
        background: color(base, White);
        position: relative;
        bottom: 0;
        box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .2);
        max-width: 34rem;
        z-index: 80001;

        &._active {
            width: 34rem;

            .product__compare-header {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-down' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, White)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cpolyline points='6 9 12 15 18 9' /%3E%3C/svg%3E");
            }
        }

        .product__compare-header {
            background-color: color(base, Primary);
            background-size: 1.6rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-chevron-up' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, White)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cpolyline points='6 15 12 9 18 15' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 1rem center;
            color: color(base, White);
            font-weight: 700;
            padding: 1.5rem 3.8rem 1.5rem 2rem;
            display: flex;
            align-items: center;
            cursor: pointer;

            > span {
                background-color: color(base, Black);
                border-radius: 50%;
                width: 20px;
                height: 20px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 1rem;
            }
        }

        .product__compare-list {
            max-height: 33rem;
            overflow-y: auto;

            li {
                padding: 1rem;
                @include border(0 0 1px, color(gray, Gray-100));
                display: grid;
                grid-template-columns: 2fr 6fr 1fr;
                align-items: center;
                gap: 1rem;

                &:last-child {
                    border: none;
                }

                .image__holder {
                    position: relative;
                    z-index: 1;
                    display: block;
                    background-color: color(base, White);
                    text-align: center;

                    img {
                        aspect-ratio: 1/1;
                        width: 100%;
                        object-fit: scale-down;
                    }
                }

                .product__holder {
                    display: flex;
                    flex-direction: column;
                    a {
                        color: color(base, DefaultFontColor);
                        font-weight: 700;

                        &:hover {
                            color: color(base, DefaultFontColor);
                            text-decoration: underline;
                        }
                    }

                    .price__holder {
                        span {
                            color: color(base, Primary);
                            line-height: 2.2rem;
                            font-weight: 700;

                            &.--old {
                                text-decoration: line-through;
                                color: color(gray, Gray-400);
                                font-weight: 400;
                            }
                        }
                    }
                }

                ._remove {
                    align-self: center;
                    margin-left: auto;
                    cursor: pointer;
                    width: 2rem;
                    height: 1.6rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-300)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                    background-size: contain;
                    margin-right: 1rem;
                    @include hover() {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-350)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                    }
                }
            }
        }

        .product__compare-button {
            padding: 1rem;
            border-top: .1rem solid color(gray, Gray-100);
        }
    }
}

.catalog-compare {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }
    }

    &:has(#hideSameCompareSpecs:checked) .equal {
        display: none;
    }

    .--header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        h1 {
            font-size: 2.2rem;
            line-height: 2.8rem;
            text-transform: uppercase;
        }

        .--swiper-pagination {
            margin: 0 0 0 auto;
        }
    }

    .compare__products {
        $animation-duration: 0.3s;
        $animation-easing: ease-in-out;

        .compare__group {
            overflow: hidden;
            max-height: 0;
            transition: max-height $animation-duration $animation-easing;

            &.active {
                max-height: 100rem;
            }
        }

        .compare__products-product {
            position: relative;

            &.__attributes {
                ul._attributes {
                    li.compare__element-key {
                        text-align: left;
                        font-weight: 600;
                    }
                }
            }

            .--remove {
                width: 2rem;
                height: 2rem;
                position: absolute;
                z-index: 2;
                top: 1.2rem;
                right: 1.7rem;
                margin-left: auto;
                cursor: pointer;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-400)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                background-size: contain;
                @include hover() {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-x' width='20' height='20' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(gray, Gray-600)}' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z'/%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E");
                }
            }

            .product__header {
                margin: 0 0 1.5rem;
                padding: 0 .5rem;
                position: relative;
                height: 46rem;

                @include breakpoint(medium) {
                    height: 38rem;
                }

                > div:not(.field__container) {
                    display: flex;
                    flex-flow: column;
                    height: 100%;
                    background: color(gray, Gray-150);
                    padding: 1rem;

                    > a {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        height: 100%;

                        h2 {
                            font-size: 1.8rem;
                            margin: 1rem 0 3rem;
                            color: color(base, DefaultFontColor);
                            text-align: center;
                        }

                        > span {
                            display: block;

                            &.product__holder {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                            }

                            .price__holder {
                                margin-top: auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: .4rem;

                                .--old {
                                    color: color(gray, Gray-400);
                                    text-decoration: line-through;
                                }

                                .price, .--special {
                                    display: block;
                                    font-size: 1.6rem;
                                    font-weight: 700;
                                    color: color(base, DefaultFontColor);
                                }
                            }
                        }
                    }

                    .product__buttons {
                        margin-top: auto;
                        padding: 1rem 0;

                        button {
                            font-size: 1rem;
                            height: 3.2rem;
                            line-height: 3.2rem;
                            padding: 0 2rem;
                        }
                    }
                }

                &.form .field__container {
                    display: flex;
                    height: 100%;
                    padding: 0;

                    label {
                        margin-top: auto;
                        display: inline-block;
                        text-align: end;
                    }
                }
            }

            ul._attributes {
                li {
                    border-left: .1rem solid color(gray, Gray-100);
                    border-bottom: .1rem solid color(gray, Gray-100);
                    padding: 0 1rem;
                    font-size: 1.3rem;
                    line-height: 2rem;
                    min-height: 4rem;

                    svg {
                        width: 16px;
                        height: 16px;
                    }

                    &.compare__element-key:nth-child(odd) {
                        background: color(gray, Gray-100);
                    }

                    &.compare__element-title {
                        margin-top: 2rem;
                        cursor: pointer;
                        border-right: none;
                        border-left: none;

                        h2 {
                            font-size: 1.4rem;
                            margin: 0;

                            &::before {
                                content: '';
                                display: inline-block;
                                height: 15px;
                                width: 15px;
                                margin-right: 1rem;
                                background-size: 1.3rem;
                                background-position: center top .3rem;
                                background-repeat: no-repeat;
                                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#{color(base,Primary)}" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/></svg>');
                            }
                        }

                        &.active {
                            h2 {
                                &::before {
                                    content: '';
                                    background-size: 1.3rem;
                                    background-position: center top .3rem;
                                    background-repeat: no-repeat;
                                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="#{color(base,Primary)}" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>');
                                }
                            }
                        }
                    }

                    &.compare__element-key {
                        text-align: center;

                        .fa-check {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="%2338b000" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-size: 18px;
                            padding: 0 0 0 22px;
                            color: color(alert, Success)
                        }

                        .fa-times {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path fill="%23d44a4a" d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>');
                            background-repeat: no-repeat;
                            background-position: left center;
                            background-size: 18px;
                            padding: 0 0 0 22px;
                            color: color(alert, Error);

                        }
                    }

                    > div {
                        padding: 1rem 0;
                    }

                }
            }

            &._attributes {
                ul {
                    li {
                        border-left: none;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .product__compare-whatsapp {
        margin-left: 10px;

        > .product__compare-whatsapp {
            margin-left: 0;
        }
    }
}

.--swiper-pagination {
    display: flex;
    position: absolute;
    top: -40px;
    right: 10px;
    gap: .5rem;

    button {
        display: block;
        width: 2.6rem;
        height: 2.6rem;
        background-repeat: no-repeat;
        background-position: right center;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(base, Primary)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        background-size: 1.2rem;
        cursor: pointer;

        &.swiper-button-disabled {
            background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-200)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }

        @include hover() {
            &:not(.swiper-button-disabled) {
                background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{lighten(color(base, Primary),10%)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
            }
        }

        &.--prev {
            transform: rotate(180deg);
            background-position: left center;
        }
    }

    .static & {
        button, button:hover {
            cursor: auto;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='9px' height='14px' viewBox='0 0 9 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpolyline id='Stroke-1' stroke='#{color(gray, Gray-200)}' stroke-width='2' points='1.005 1 6.891 6.886 1 12.776'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
        }
    }
}
