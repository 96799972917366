.obiChatLauncher {
    body.no-scroll & {
        @include breakpoint(small only) {
            display: none;
        }
    }
}
.obiChatLauncherBottomRight {
    bottom: 100px !important;
    @include breakpoint(small only) {
        bottom: 60px !important;
    }
}

