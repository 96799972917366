.product__actionpanels {
    padding: 15px 0px;
    margin-top: 8px;
    font-size: 13px;
    line-height: 20px;
    position: relative;
    @include breakpoint(small only) {
        margin-top: 28px;
    }

    .product__label {
        position: absolute;
        top: -3px;
        left: 15px;
        height: 28px;
        line-height: 29px;
        padding: 0 15px;
        letter-spacing: .02em;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;
        color: color(base,White);
        border-radius: 3px;
        background: color(base,Primary);
    }

    .product__items {
        display: flex;
        flex-direction: column;
        gap: 8px;
        .__actionpanel {
            padding: 15px;
            background-color: color(Gray, Gray-100);
        }
    }
}
