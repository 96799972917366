.blog-category__nav {
    margin: 0 0 2rem 0;
    position: relative;
    border: none !important;
    padding: 0 !important;

    &::before {
        background: linear-gradient(90deg, transparent, #fff);
        content: "";
        display: block;
        height: 4.4rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 2rem;
        z-index: 2;
    }

    section {
        display: flex;
        gap: 1rem;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0 0 1rem;

        &::-webkit-scrollbar {
            height: .4rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: color(Gray, Gray-300);
        }
    }

    &__item {
        border: .1rem solid color(base, Primary);
        border-radius: .5rem;
        color: color(base, Primary);
        font-size: 1.4rem;
        padding: 1rem;
        white-space: nowrap;

        &:hover, &.--active {
            background-color: color(base, Primary);
            color: color(base, White);
        }

        &.--active {
            font-weight: bold;
        }
    }
}
