/* Override default */
$default-padding: 2.5rem;
$default-border-radius: 0.5rem;
$default-border-width: 0.1rem;

$grid__max-width: 125.8rem;
$grid__gutter: 1rem;
$grid__max-padding: 1rem;
$grid__max-padding__mobile: 1rem;
$grid__padding: 1rem;

$grid__breakpoints: (
        '': 0,
        small: 576,
        medium: 769,
        between-medium-small: 941,
        between-large-medium: 1100,
        large: 1025,
        xlarge: 1200,
        xxlarge: 1440
) !default;

$breakpoints: (
        small: 0,
        medium: 769px,
        between-medium-small: 941px,
        large: 1025px,
        between-large-medium: 1100px,
        xlarge: 1200px,
        xxlarge: 1440px,
) !default;

$color-palette: (
        base: (
                White: rgb(255 255 255),
                Black: rgb(0 0 0),
                Primary: rgb(236 102 13),
                PrimaryHover: rgb(250 145 25),
                PrimaryDark: rgb(239 119 16),
                Secondary: rgb(0 125 241),
                SecondaryHover: rgb(0 104 241),
                Green: rgb(56 176 0),
                GreenHover: rgb(45 143 0),
                Red: rgb(212 74 74),
                Anthracite: rgb(29 29 31),
                DefaultFontColor: rgb(29 29 31), //#1d1d1f
        ),
        Gray: (
                Gray-50: rgb(254 254 254), //#fefefe
                Gray-100: rgb(246 246 246), //#f6f6f6
                Gray-150: rgb(241 241 241), //#f1f1f1
                Gray-200: rgb(229 229 229), //#e5e5e5
                Gray-250: rgb(233 234 242), //#e9eaf2
                Gray-300: rgb(212 212 212), //#d4d4d4
                Gray-350: rgb(201 201 201), //#c9c9c9
                Gray-400: rgb(163 163 163), //#a3a3a3
                Gray-500: rgb(115 115 115), //#737373
                Gray-600: rgb(98 108 119), //#626c77
                Gray-700: rgb(68 68 68), //#444444
                Gray-800: rgb(38 38 38), //#262626
                Gray-900: rgb(23 23 23), //#171717
        ),
        alert: (
                Error: rgb(204 75 55),
                Warning: rgb(152 104 0),
                Success: rgb(58 219 118),
                Neutral: rgb(66 88 195),
        ),
        alert-backgrounds: (
                Error: rgb(251 213 213),
                Warning: rgb(255 249 205),
                Success: rgb(223 255 223),
                Neutral: rgb(240, 244, 255),
        ),
);
