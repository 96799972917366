.product__reviews-container {
    display: flex;
    gap: 10px;
    flex-direction: column;
    @include breakpoint(small only) {
        padding-bottom: 20px;
    }

    > .rating {
        display: flex;
        gap: 10px;

        .rating__count {
            strong {
                display: block;
                font-size: 25px;
                line-height: 25px;
                margin: 0 0 5px;
            }
        }

        .rating__block {
            width: 130px;
            height: 25px;
            background-size: cover;

            .rating__block_fill {
                height: 25px;
                background-size: cover;
            }
        }
    }

    ._ratings {
        display: block;
        color: color(base, DefaultFontColor);
        margin: 5px 0 0;
    }

    .review__content-block {
        max-width: 70%;

        &.reviews_empty {
            margin-bottom: 20px;
        }

        @include breakpoint(small only) {
            max-width: 100%;
        }
    }

    .product__reviews {
        position: relative;
        @include breakpoint(small only) {
            overflow: hidden;
            margin: 0 -10px 0 0;
        }

        > ul {
            @include breakpoint(small only) {
                display: flex;
                overflow-x: scroll;
                padding-bottom: 15px;
            }

            > li {
                position: relative;
                padding: 20px;
                width: 90%;
                margin-right: 10px;
                border: 1px solid color(Gray, Gray-200);
                border-radius: 5px;
                display: flex;
                gap: 10px;
                flex-direction: column;
                @include breakpoint(small only) {
                    min-width: 200px;
                }
                @include breakpoint(medium) {
                    width: 100%;
                    margin-right: 0;
                    border: none;
                    border-top: 1px solid color(Gray, Gray-200);
                    border-radius: 0;
                    padding: 20px 0;
                }

                .rating {
                    justify-content: space-between;
                    gap: 10px;
                    flex-wrap: wrap;

                    .review-verified {
                        inset: 20px 0 auto auto;
                        background-color: rgba(56, 176, 0, .2);
                        line-height: 24px;
                        border-radius: 12px;
                        color: #38b000;
                        font-size: 11px;
                        font-weight: 600;
                        padding: 0 10px 0 27px;
                        background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nMTEnIGhlaWdodD0nOCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNOS4xNjcgMS43NUw0LjEyNSA2Ljc5MiAxLjgzMyA0LjUnIHN0cm9rZT0nIzM4QjAwMCcgc3Ryb2tlLXdpZHRoPScyJyBmaWxsPSdub25lJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnLz48L3N2Zz4=);
                        background-repeat: no-repeat;
                        background-position: left 10px center;
                    }
                }

                h3 {
                    font-size: 14px;
                    color: color(base, DefaultFontColor);
                    line-height: 22px;
                    font-weight: 600;
                }

                p, .review__author {
                    color: color(base, DefaultFontColor);
                    line-height: 22px;
                }

                p {
                    max-height: 290px;
                    overflow: hidden;
                }

                .review__author {
                    display: block;
                }

                &.more_reviews-container {
                    padding: 0;
                    border: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .button {
                        @include default-button();
                        border-radius: 20px;
                    }
                }
            }
        }

        span.--tag {
            position: absolute;
            inset: 20px 0 auto auto;
            background-color: rgba(56, 176, 0, .2);
            line-height: 24px;
            border-radius: 12px;
            color: color(base, Green);
            font-size: 11px;
            font-weight: 600;
            padding: 0 10px 0 27px;
            background-image: url("data:image/svg+xml,%0A%3Csvg width='11px' height='8px' viewBox='0 0 11 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Vector-(3)' transform='translate(1.833340, 1.750000)' stroke='%2338B000' stroke-width='2'%3E%3Cpolyline id='Path' points='7.33333 0 2.29167 5.04167 2.22044605e-16 2.75'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: left 10px center;
            @include breakpoint(small only) {
                inset: auto;
                position: relative;
            }

            &.--gray {
                background: rgba(29, 29, 31, 0.08);
                color: rgba(29, 29, 31, 0.48);
                padding: 0 10px;
            }
        }

        .add__review__form {
            .add__rating {
                display: flex;
                align-items: center;

                .rating__group {
                    display: flex;
                    flex-direction: row-reverse;

                    > input {
                        opacity: 0;
                        position: absolute;

                        + label {
                            &::before, &::after {
                                display: none;
                            }

                            margin-right: 2px;
                            height: 25px;
                            width: 26px;
                            display: block;
                            float: right;
                            cursor: pointer;
                            background-repeat: no-repeat;
                            background-size: contain;
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='25px' viewBox='0 0 26 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M13,1.12977571 L16.6851882,8.5967825 L24.9255194,9.79417272 L18.9627597,15.6064269 L20.3703762,23.8134615 L13,19.9386253 L5.62962368,23.8134615 L7.03724024,15.6064269 L1.07448053,9.79417272 L9.31481172,8.5967825 L13,1.12977571 Z' id='Star-Copy-4' stroke='%23FFC400' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                        }

                        &:checked ~ label, & + label:hover ~ label, & + label:hover {
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='26px' height='25px' viewBox='0 0 26 25' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M13,1.12977571 L16.6851882,8.5967825 L24.9255194,9.79417272 L18.9627597,15.6064269 L20.3703762,23.8134615 L13,19.9386253 L5.62962368,23.8134615 L7.03724024,15.6064269 L1.07448053,9.79417272 L9.31481172,8.5967825 L13,1.12977571 Z' id='Star-Copy-4' stroke='%23FFC400' fill='%23FFC400' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
                        }
                    }
                }
            }

            .error-message {
                display: block;
                font-size: 12px;
                margin: 5px 0 0;
                color: color(base, Red);
            }

            .add__rating, input[type=text], textarea, button {
                margin: 10px 0 0;
            }
        }
    }

    ._actions {
        display: block;
    }

    .product__reviews-form {
        form {
            width: 400px;
            max-width: 100%;


            .input-rating {
                display: flex;

                input {
                    display: none;
                }

                label + input {
                    width: initial;

                    &::before {
                        display: none;
                    }
                }

                input + label {
                    cursor: pointer;
                    width: 35px;
                    height: 35px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' fill='%23626c77' width='24'%3E%3Cpath d='m354-247 126-76 126 77-33-144 111-96-146-13-58-136-58 135-146 13 111 97-33 143Zm-61 83.924 49.615-212.539-164.923-142.847 217.231-18.846L480-737.693l85.077 200.385 217.231 18.846-164.923 142.847L667-163.076 480-275.923 293-163.076ZM480-430Z'/%3E%3C/svg%3E");
                    background-size: cover;

                    &::before {
                        display: none;
                    }

                    &.--selected {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23fec400' d='m293-163.076 49.615-212.539-164.923-142.847 217.231-18.846L480-737.693l85.077 200.385 217.231 18.846-164.923 142.847L667-163.076 480-275.923 293-163.076Z'/%3E%3C/svg%3E");
                    }
                }
            }

            .field__container {
                label {
                    display: block;
                    margin: 0 0 .5rem;
                }
            }

            button {
                width: 100%;
            }
        }
    }
}
