.page-builder__wrapper {
    overflow: clip;

    .page-builder__content-block {
        margin: 3rem 0;

        &:not(.--first) {
            > div {
                &:not(.grid-x){
                    padding: 3rem 0 0;
                }
                &.product__row {
                    padding-bottom: 3rem;

                    &.--border-top {
                        border-top: 1px solid color(base, Primary);
                    }

                    &.--border-bottom {
                        border-bottom: 1px solid color(base, Primary);
                    }
                }
            }
        }

        .--header {
            margin: 0 0 10px;
        }
    }
}
