.blaze-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;

    button {
        @include transition();
        display: flex;
        width: 1.4rem;
        height: 1.4rem;
        opacity: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.594 6.35442C13.8162 6.73935 13.8162 7.21359 13.594 7.59852L10.824 12.3962C10.6018 12.7811 10.1911 13.0183 9.74661 13.0183L4.2067 13.0183C3.76223 13.0183 3.35152 12.7811 3.12928 12.3962L0.359327 7.59852C0.137089 7.21359 0.137089 6.73934 0.359327 6.35442L3.12928 1.55672C3.35152 1.17179 3.76223 0.93467 4.2067 0.93467L9.74661 0.93467C10.1911 0.93467 10.6018 1.17179 10.824 1.55672L13.594 6.35442Z' fill='%23E3E3E3'/%3E%3C/svg%3E%0A");
        margin: 0;
        text-indent: -9999px;

        &.active {
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6409 6.37795C13.8631 6.76288 13.8631 7.23712 13.6409 7.62205L10.8591 12.4401C10.6369 12.8251 10.2262 13.0622 9.78172 13.0622L4.21828 13.0622C3.77381 13.0622 3.3631 12.8251 3.14086 12.4401L0.359142 7.62205C0.136904 7.23712 0.136904 6.76288 0.359142 6.37795L3.14086 1.55987C3.3631 1.17495 3.77381 0.937822 4.21828 0.937822L9.78172 0.937822C10.2262 0.937822 10.6369 1.17495 10.8591 1.55987L13.6409 6.37795Z' fill='%23D4AF37'/%3E%3C/svg%3E%0A");
        }
    }

    &.--alternative {
        gap: 0;
        background-color: color(Gray, Gray-100);

        button {
            flex: 1;
            width: auto;
            height: .4rem;
            background-image: none;

            &.active {
                background-color: color(base, Black);
            }
        }
    }
}

.blaze-slider {
    .blaze-controls {
        &.--compact {
            display: flex;
            align-items: center;
            gap: .2rem;

            span {
                display: block;
                font-size: 2rem;
                line-height: 2.4rem;
            }

            button {
                width: 2.4rem;
                height: 2.4rem;

                &.blaze-next {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_38523' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_38523)'%3E%3Cpath d='M13.8461 17.6538L12.7923 16.5692L16.6116 12.75H4.5V11.25H16.6116L12.7923 7.43079L13.8461 6.34619L19.5 12L13.8461 17.6538Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                }

                &.blaze-prev {
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_156' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_156)'%3E%3Cpath d='M10.1538 6.34618L11.2077 7.43077L7.38842 11.25L19.5 11.25L19.5 12.75L7.38842 12.75L11.2077 16.5692L10.1538 17.6538L4.50002 12L10.1538 6.34618Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                }
            }

            &.--inverted {
                span {
                    color: color(base, White);
                }

                button {
                    &.blaze-next {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_38523' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_38523)'%3E%3Cpath d='M13.8461 17.6538L12.7923 16.5692L16.6116 12.75H4.5V11.25H16.6116L12.7923 7.43079L13.8461 6.34619L19.5 12L13.8461 17.6538Z' fill='#{color(base, White)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }

                    &.blaze-prev {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_156' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_156)'%3E%3Cpath d='M10.1538 6.34618L11.2077 7.43077L7.38842 11.25L19.5 11.25L19.5 12.75L7.38842 12.75L11.2077 16.5692L10.1538 17.6538L4.50002 12L10.1538 6.34618Z' fill='#{color(base, White)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        &:not(.--compact) {
            button.slider__control {
                position: absolute;
                width: 3.2rem;
                height: 3.2rem;
                background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_41852' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'%3E%3Crect width='32' height='32' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_41852)'%3E%3Cpath d='M18.6666 23.5385L11.1282 16.0001L18.6666 8.46167L20.0717 9.86677L13.9384 16.0001L20.0717 22.1334L18.6666 23.5385Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                z-index: 3;
                top: 50%;
                transform: translateY(-50%);

                &.blaze-next {
                    right: 0;
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_41850' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'%3E%3Crect width='32' height='32' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_41850)'%3E%3Cpath d='M17.2615 16.0001L11.1282 9.86677L12.5333 8.46167L20.0717 16.0001L12.5333 23.5385L11.1282 22.1334L17.2615 16.0001Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    &.start {
        .blaze-controls {
            &.--compact {
                button {
                    &.blaze-prev {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_156' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_156)'%3E%3Cpath d='M10.1538 6.34618L11.2077 7.43077L7.38842 11.25L19.5 11.25L19.5 12.75L7.38842 12.75L11.2077 16.5692L10.1538 17.6538L4.50002 12L10.1538 6.34618Z' fill='#{color(Gray, Gray-150)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }

                &.--inverted {
                    button {
                        &.blaze-prev {
                            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_0_156' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_0_156)'%3E%3Cpath d='M10.1538 6.34618L11.2077 7.43077L7.38842 11.25L19.5 11.25L19.5 12.75L7.38842 12.75L11.2077 16.5692L10.1538 17.6538L4.50002 12L10.1538 6.34618Z' fill='#{color(Gray, Gray-800)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }

            &:not(.--compact) {
                button.slider__control {
                    &.blaze-prev {
                        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_41852' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'%3E%3Crect width='32' height='32' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_41852)'%3E%3Cpath d='M18.6666 23.5385L11.1282 16.0001L18.6666 8.46167L20.0717 9.86677L13.9384 16.0001L20.0717 22.1334L18.6666 23.5385Z' fill='#{color(Gray, Gray-150)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }

    &.end {
        .blaze-controls {
            &.--compact {
                button {
                    &.blaze-next {
                        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_38523' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_38523)'%3E%3Cpath d='M13.8461 17.6538L12.7923 16.5692L16.6116 12.75H4.5V11.25H16.6116L12.7923 7.43079L13.8461 6.34619L19.5 12L13.8461 17.6538Z' fill='#{color(Gray, Gray-150)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }

                &.--inverted {
                    button {
                        &.blaze-next {
                            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_38523' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_38523)'%3E%3Cpath d='M13.8461 17.6538L12.7923 16.5692L16.6116 12.75H4.5V11.25H16.6116L12.7923 7.43079L13.8461 6.34619L19.5 12L13.8461 17.6538Z' fill='#{color(Gray, Gray-800)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }

            &:not(.--compact) {
                button.slider__control {
                    &.blaze-next {
                        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_41850' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='32' height='32'%3E%3Crect width='32' height='32' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_41850)'%3E%3Cpath d='M17.2615 16.0001L11.1282 9.86677L12.5333 8.46167L20.0717 16.0001L12.5333 23.5385L11.1282 22.1334L17.2615 16.0001Z' fill='#{color(Gray, Gray-150)}'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }

    &.static {
        .blaze-container > button, .blaze-controls {
            display: none;
        }
    }
}
