.no-scrolling, .no-scroll {
    position: inherit;
    overflow-y: hidden !important;
}

.off-canvas {
    display: flex;
    flex-direction: column;
    &.position-bottom {
        height: 90%;
        left: 0;
        overflow-y: auto;
        bottom: 0;
        width: 100%;
        transform: translateY(100%);
        padding-bottom: 7.5rem;
        &.is-open {
            transform: translate(0);
        }
    }
    &.position-left {
        width: calc(100% - 20px);
        max-width: 300px;
    }
    &.position-center {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background: transparent;
        .--inner {
            width: calc(100% - 20px);
            box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, .5);
            border-radius: 5px;
            background: color(base, White);
            max-width: 90vw;
            max-height: 90vh;
            @include breakpoint(small only) {
                max-width: calc(100% - 20px);
                max-height: calc(100vh - 20px);
            }
            .modal-header {
                overflow: hidden;
                padding: 15px 20px 0;
                display: flex;
                justify-content: space-between;
                h4 {
                    color: color(base, Primary);
                    @include font-size(16px);
                    font-weight: 700;
                }
                span {
                    cursor: pointer;
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    background-position: center;
                    margin-left: auto;
                }
            }
        }
    }
    .title {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);

        h2 {
            margin: 0;
            font-size: 16px;
        }

        .close-button {
            position: relative;
            font-size: 26px;
        }
    }

    .offcanvas-content {
        padding: 10px;
        flex: 1;
        overflow-x: scroll;
    }
    .drilldown {
        &.menu {
            overflow: hidden;
            height: 100vh;
        }
    }
}
