#mediaGallery {
    @include breakpoint(small only) {
        margin-bottom: 20px;
    }
    .blaze-container {
        .blaze-track-container {
            border: 1px solid color(Gray, Gray-300);
            border-radius: 5px;
        }

        .blaze-pagination {
            position: absolute;
            left: 0;
            bottom: 20px;
            width: 100%;
            height: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            button {
                font-size: 0;
                width: 3px;
                height: 3px;
                border-radius: 10px;
                background: color(gray, Gray-300);
                display: block;
                transition: width .2s ease-in-out, height .2s ease-in-out;
                &.active {
                    height: 9px;
                    width: 9px;
                    background: color(base, Primary);
                }
            }
        }
    }
}

.overlay__image {
    pointer-events: none;
    width: 100px;
    height: 100px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    @include breakpoint(small only) {
        width: 75px;
        height: 75px;
    }
}

#mediaGalleryControls {
    position: relative;
    .blaze-container {
        .blaze-track-container {
            .blaze-track {
                & > div {
                    border: 1px solid color(Gray, Gray-300);
                    border-radius: 5px;
                    padding: 10px;
                }
            }
        }
    }

    .more_slides_than_visible {
        position: absolute;
        right: 0;
        height: calc(100% - 40px);
        border-radius: 5px;
        background: rgba(0,0,0,.2);
        top: 20px;
        aspect-ratio: 1/1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: 1px solid color(Gray, Gray-400);
        &:hover {
            border: 2px solid color(base, Primary);
        }
        p {
            color: #fff;
            font-weight: 700;
            font-size: 30px;
        }
    }
}

.product__media {
    position: relative;

    .action__label {
        display: block;
        position: absolute;
        border-radius: 4px;
        top: 20px;
        left: 20px;
        z-index: 2;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        font-weight: 800;
        padding: 0 10px;
        color: color(base, White);
        background: color(base, Primary);
    }

    #mediaGallery,
    #mediaGalleryControls,
    #mediaGalleryLightbox {
        .blaze-container {
            .blaze-track-container {
                .blaze-track {
                    &>div {
                        cursor: pointer;
                        &.--thumb {
                            picture {
                                max-width: 104px;

                                > span {
                                    z-index: 1;
                                    display: block;
                                    width: 4.4rem;
                                    height: 4.4rem;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    background-image: url("data:image/svg+xml,%3Csvg width='51' height='51' viewBox='0 0 51 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_2_2)'%3E%3Cpath d='M25.5 50C39.031 50 50 39.031 50 25.5C50 11.969 39.031 1 25.5 1C11.969 1 1 11.969 1 25.5C1 39.031 11.969 50 25.5 50Z' fill='white' stroke='%23EC660D' stroke-width='2'/%3E%3Cpath d='M37 23.768C38.3333 24.5378 38.3333 26.4623 37 27.2321L19.75 37.1913C18.4167 37.9611 16.75 36.9989 16.75 35.4593V15.5407C16.75 14.0011 18.4167 13.0389 19.75 13.8087L37 23.768Z' fill='%23EC660D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_2_2'%3E%3Crect width='51' height='51' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
                                    background-size: 4.4rem;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                }
                            }
                        }
                        picture {
                            position: relative;
                            display: block;
                            &::before {
                                padding-top: 100%;
                                content: '';
                                display: block;
                            }
                            img,
                            source {
                                position: absolute;
                                left: 0;
                                top: 0;
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                                border-radius: 2px;
                            }
                        }
                    }
                }
            }

            .blaze-prev {
                @include slider-button('prev');
            }

            .blaze-next {
                @include slider-button('next');
            }
        }
    }

    #mediaGalleryControls {
        padding: 20px 0;
        .blaze-container {
            .blaze-track-container {
                .blaze-track {
                    & > div {
                        padding: 1px;
                        &:hover,
                        &.--active {
                            padding: 0;
                            border: 2px solid color(base, Primary);
                        }
                    }
                }
            }
        }
    }

    #media_gallery_lightbox {
        position: fixed;
        display: none;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99992;
        justify-content: center;
        align-items: center;

        .lightbox__video {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &.--open {
            display: flex;
        }
        .--overlay {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: rgba(0,0,0,.4);
        }
        .--inner {
            background: color(base, White);
            width: 80vw;
            height: 80vw;
            max-width: 90vw;
            max-height: 90vh;
            box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, .5);
            border-radius: 5px;
            z-index: 1;
            @include breakpoint(small only) {
                width: 100%;
            }
            .modal-header {
                overflow: hidden;
                padding: 15px 20px 0;
                display: flex;
                margin-bottom: 14px;
                h4 {
                    color: color(base, Primary);
                    font-size: 16px;
                    font-weight: 700;
                }

                span {
                    cursor: pointer;
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGNsYXNzPSdoLTUgdy01JyB2aWV3Qm94PScwIDAgMjAgMjAnIGZpbGw9J2N1cnJlbnRDb2xvcic+PHBhdGggZmlsbC1ydWxlPSdldmVub2RkJyBkPSdNNC4yOTMgNC4yOTNhMSAxIDAgMDExLjQxNCAwTDEwIDguNTg2bDQuMjkzLTQuMjkzYTEgMSAwIDExMS40MTQgMS40MTRMMTEuNDE0IDEwbDQuMjkzIDQuMjkzYTEgMSAwIDAxLTEuNDE0IDEuNDE0TDEwIDExLjQxNGwtNC4yOTMgNC4yOTNhMSAxIDAgMDEtMS40MTQtMS40MTRMOC41ODYgMTAgNC4yOTMgNS43MDdhMSAxIDAgMDEwLTEuNDE0eicgY2xpcC1ydWxlPSdldmVub2RkJy8+PC9zdmc+);
                    background-repeat: no-repeat;
                    background-position: 50%;
                    margin-left: auto;
                }
            }

            #mediaGalleryLightbox {
                .blaze-container {
                    .blaze-track-container {
                        .blaze-track {
                            & > div {
                                border: 0;
                                border-radius: 0;
                                picture {
                                    width: 100%;
                                    height: calc(80vw - 70px);
                                    max-height: calc(90vh - 70px);
                                    position: relative;
                                    display: block;
                                    &::before {
                                        display: none;
                                    }
                                    img,
                                    source {
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
