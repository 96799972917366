h1, h2, h3, h4, h5, h6 {
    @include font-size(26px);
    line-height: normal;
    font-weight: 700;
    color: color(base, Primary);
    margin: 0 0 1rem;

    &.--primary {
        color: color(base, Primary);
    }

    &.--black {
        color: color(base, DefaultFontColor);
    }

    footer & {
        color: color(base, White);
    }

}
h1 {
    ._header & {
        @include font-size(22px);

        @include breakpoint('medium'){
            @include font-size(30px);
        }
    }
    .--content & {
        margin-top: 0;
    }
}
h2 {
    @include font-size(24px);
}
h3 {
    @include font-size(22px);

    .--content & {
        @include font-size(18px);
    }
}
h4, h5, h6 {
    @include font-size(14px);
}

p {
    line-height: 2.2rem;
}
address {
    @include font-size(16px);
}

