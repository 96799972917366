@use 'sass:math';

@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin transition {
    transition: all 0.3s ease;
}

@mixin hover() {
    @include breakpoint(medium) {
        &:hover {
            @content;
        }
    }
}

@mixin tablet-only() {
    @media screen and (min-width: 768px) and (max-width: 1025px) {
        @content;
    }
}

@mixin dropdown-shadow() {
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
}

$default-border-color: color(Gray, Gray-350);

@mixin border($width: $default-border-width, $color: $default-border-color) {
    border-width: $width;
    border-style: solid;
    border-color: $color;
}

@mixin border-radius($radius: $default-border-radius) {
    border-radius: $radius;
}

@mixin padding($padding: $default-padding) {
    padding: $padding;
}

@mixin font-size($size) {
    font-size: calculateRem($size);
}

@mixin letter-spacing($spacing: .032) {
    letter-spacing: #{$spacing}rem;
}

@mixin default-button() {
    @include transition();
    @include border-radius();
    @include letter-spacing();
    background-color: color(base, Primary);
    color: color(base, White);
    font-weight: 600;
    line-height: 4rem;
    display: inline-flex;
    align-items: center;
    padding: 0 1.8rem;
    cursor: pointer;
    font-size: 1.4rem;

    svg {
        fill: color(base, Primary);
        margin-right: 1rem;
    }

    @include hover() {
        background-color: lighten(color(base, Primary), 10%);
        text-decoration: none;
        color: color(base, White);

        svg {
            fill: lighten(color(base, Primary), 10%);
        }
    }


    &.--secondary {
        background-color: color(base, Secondary);
        color: color(base, White);

        @include hover() {
            background-color: darken(color(base, Secondary), 10%);
        }
    }

    &.--green {
        background-color: color(base, Green);
        color: color(base, White);

        @include hover() {
            background-color: darken(color(base, green), 10%);
        }

        &.--chevron {
            &--right {
                @include chevron(right, color(base, White));
            }
        }
    }
    &.--grey {
        background-color: color(Gray, Gray-200);
        color: color(base, White);

        @include hover() {
            background-color: darken(color(Gray, Gray-200), 10%);
        }
    }
    &.--chevron {
        &--toggle {
            @include chevron(down);

            &:hover {
                @include chevron(down, lighten(color(base, Primary), 10%));
            }

            &._active {
                @include chevron(up);

                &:hover {
                    @include chevron(up, lighten(color(base, Primary), 10%));
                }
            }
        }

        &--right {
            @include chevron(right, color(base, Primary));
            padding-right: 2.5rem;
        }
    }

    &.--add-to-cart {
        @include add-button();

        &::before {
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512'%3E%3Cpath fill='%23fff' d='M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z'%3E%3C/path%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: .6rem;
        }

        &.--success {
            &::before {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20fill='%23FFF'%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20448%20512'%3E%3C!--!%20Font%20Awesome%20Free%206.4.2%20by%20@fontawesome%20-%20https://fontawesome.com%20License%20-%20https://fontawesome.com/license/free%20(Icons:%20CC%20BY%204.0,%20Fonts:%20SIL%20OFL%201.1,%20Code:%20MIT%20License)%20Copyright%202023%20Fonticons,%20Inc.--%3E%3Cpath%20d='M438.6%20105.4c12.5%2012.5%2012.5%2032.8%200%2045.3l-256%20256c-12.5%2012.5-32.8%2012.5-45.3%200l-128-128c-12.5-12.5-12.5-32.8%200-45.3s32.8-12.5%2045.3%200L160%20338.7l233.4-233.3c12.5-12.5%2032.8-12.5%2045.3%200z'/%3E%3C/svg%3E");
            }
        }

        &.--danger {
            background-color: color(base, Red);

            &::before {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636' /%3E%3C/svg%3E%0A");
            }
        }
    }

    &.--whatsapp {
        &::after {
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%2338b000' d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'/%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: .6rem;
        }

        &:hover {
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3C!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%232d8f00' d='M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z'/%3E%3C/svg%3E");
            }
        }
    }

    &.--stores {
        &::after {
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            display: inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23007df1' stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z' /%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-left: .6rem;
        }

        &:hover {
            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%230068f1' stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z' /%3E%3C/svg%3E%0A");
            }
        }
    }

    &.--icon-only {
        padding: 0;
    }
    &.--rounded {
        @include border-radius(2.5rem);
    }
    &.--big {
        line-height: 4.6rem;
        padding: 0 1.6rem;
        &.button--wishlist {
            height: 4.6rem;
        }
    }
    &.--full-width {
        width: 100%;
    }
    &.--center {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &.--outline {
        background-color: transparent;
        color: color(base, Primary);
        @include border($color: color(base, Primary));

        @include hover() {
            color: lighten(color(base, Primary), 10%);
            @include border($color: lighten(color(base, Primary), 10%));
            background-color: transparent;
        }

        &.--secondary {
            color: color(base, Secondary);
            @include border($color: color(base, Secondary));

            @include hover() {
                color: darken(color(base, Secondary), 10%);
                @include border($color: darken(color(base, Secondary), 10%));
            }
        }

        &.--green {
            color: color(base, Green);
            @include border($color: color(base, Green));

            @include hover() {
                color: darken(color(base, Green), 10%);
                @include border($color: darken(color(base, Green), 10%));
            }
        }

        &.--blue {
            color: color(base, Secondary);
            @include border($color: color(base, Secondary));

            @include hover() {
                color: color(base, SecondaryHover);
                @include border($color: color(base, SecondaryHover));
            }
        }
        &.--grey {
            color: color(base, DefaultFontColor);
            @include border($color: color(Gray, Gray-350));

            @include hover() {
                color: color(base, DefaultFontColor);
                @include border($color: color(Gray, Gray-900));
            }
        }
    }
}

@mixin loading {
    animation-name: loading;
    animation-timing-function: steps(30);
    animation-iteration-count: infinite;
    animation-duration: 0.75s;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z' fill='%23fff'%3E%3C/path%3E%3C/svg%3E");
}

@mixin link-arrow-right {
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 1.4rem;
    background-position: right center;
    padding-right: 1.5rem;
}

@mixin svg-loader {
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.0' width='33px' height='9px' viewBox='0 0 128 35' xml:space='preserve'%3E%3Cg%3E%3Ccircle fill='%23f58220' fill-opacity='1' cx='17.5' cy='17.5' r='17.5'/%3E%3Canimate attributeName='opacity' dur='1200ms' begin='0s' repeatCount='indefinite' keyTimes='0;0.167;0.5;0.668;1' values='0.3;1;1;0.3;0.3'/%3E%3C/g%3E%3Cg%3E%3Ccircle fill='%23f58220' fill-opacity='1' cx='110.5' cy='17.5' r='17.5'/%3E%3Canimate attributeName='opacity' dur='1200ms' begin='0s' repeatCount='indefinite' keyTimes='0;0.334;0.5;0.835;1' values='0.3;0.3;1;1;0.3'/%3E%3C/g%3E%3Cg%3E%3Ccircle fill='%23f58220' fill-opacity='1' cx='64' cy='17.5' r='17.5'/%3E%3Canimate attributeName='opacity' dur='1200ms' begin='0s' repeatCount='indefinite' keyTimes='0;0.167;0.334;0.668;0.835;1' values='0.3;0.3;1;1;0.3;0.3'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    width: 3.3rem;
    height: .9rem;
    display: block;
    margin: 1rem 0;
}


@mixin search-loading-button {
    &::before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z' /%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    &.--loading {
        &::before {
            @include loading;
        }
    }
}

@mixin loading-button {
    &::before {
        content: '';
        @include loading();
        width: 1.5rem;
        height: 1.5rem;
        margin-right: .6rem;
        display: inline-block;
    }
}

@mixin add-button {
    &::before {
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 576 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cpath fill='%23fff' d='M0 24C0 10.7 10.7 0 24 0H69.5c22 0 41.5 12.8 50.6 32h411c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3H170.7l5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5H488c13.3 0 24 10.7 24 24s-10.7 24-24 24H199.7c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5H24C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96zM252 160c0 11 9 20 20 20h44v44c0 11 9 20 20 20s20-9 20-20V180h44c11 0 20-9 20-20s-9-20-20-20H356V96c0-11-9-20-20-20s-20 9-20 20v44H272c-11 0-20 9-20 20z'/%3E%3C/svg%3E");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: .6rem;
    }

    &[disabled] {
        @include loading-button();
    }
}

@mixin list($icon-style) {
    @if $icon-style == 'checked-icon' {
        list-style-type: none;

        li {
            &::before {
                background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxNiAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDM0MzMgMTIuMjY1M0wwLjIzNDMyNSA3LjAzNTI3QzAuMDg0MjIzMyA2Ljg4MzkzIDAgNi42Nzk0MiAwIDYuNDY2MjdDMCA2LjI1MzEyIDAuMDg0MjIzMyA2LjA0ODYgMC4yMzQzMjUgNS44OTcyN0wxLjM2NjMzIDQuNzU5MjdDMS41MTYzNCA0LjYwOTM1IDEuNzE5NzQgNC41MjUxNCAxLjkzMTgyIDQuNTI1MTRDMi4xNDM5MSA0LjUyNTE0IDIuMzQ3MzEgNC42MDkzNSAyLjQ5NzMyIDQuNzU5MjdMNi4wMDAzMyA4LjI4MjI3TDEzLjUwMDMgMC43MzYyNjZDMTMuNjUwMyAwLjU4NjM1IDEzLjg1MzcgMC41MDIxMzYgMTQuMDY1OCAwLjUwMjEzNkMxNC4yNzc5IDAuNTAyMTM2IDE0LjQ4MTMgMC41ODYzNSAxNC42MzEzIDAuNzM2MjY2TDE1Ljc2MjMgMS44NzQyN0MxNS45MTI0IDIuMDI1NiAxNS45OTY2IDIuMjMwMTIgMTUuOTk2NiAyLjQ0MzI3QzE1Ljk5NjYgMi42NTY0MiAxNS45MTI0IDIuODYwOTMgMTUuNzYyMyAzLjAxMjI3TDYuNTYyMzMgMTIuMjY1M0M2LjQxMjQ0IDEyLjQxNDMgNi4yMDk2NyAxMi40OTc5IDUuOTk4MzMgMTIuNDk3OUM1Ljc4Njk4IDEyLjQ5NzkgNS41ODQyMiAxMi40MTQzIDUuNDM0MzMgMTIuMjY1M1YxMi4yNjUzWiIgZmlsbD0iI0VDNjYwRCIvPgo8L3N2Zz4K");
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 100%;
                margin-right: .8rem;
            }
        }
    }

    li {
        position: relative;
        padding: .2rem 0 .2rem 2.2rem;
        line-height: 2.2rem;

        &::before {
            content: '';
            width: 1.4rem;
            height: 2.6rem;
            display: inline-block;
            left: 0;
            top: 0;
            position: absolute;
        }
    }
}

@mixin tooltip {
    position: relative;
    display: inline-block;
    cursor: help;

    @include hover() {
        .__content {
            display: block;
        }
    }

    &::before {
        content: '';
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"><path fill="%23626c77" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>');
        background-size: 100%;
    }

    .__content {
        @include border-radius();
        position: absolute;
        z-index: 99;
        top: calc(100% + .8rem);
        padding: 1rem;
        background: color(base, DefaultFontColor);
        color: color(base, White);
        left: -1.17rem;
        width: 25rem;
        display: none;

        p {
            color: color(base, White);
        }

        &::before {
            content: "";
            @include border-radius(.2rem);
            background: color(base, DefaultFontColor);
            height: 1.2rem;
            left: calc(50% - .6rem);
            position: absolute;
            top: -.5rem;
            transform: rotate(45deg);
            width: 1.2rem;
        }
    }
}


@mixin chevron($orientation: right, $color: color(base, Primary)) {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 320 512' transform='rotate()' fill='#{$color}' %3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E%0A");
    background-position: right 1rem center;
    background-size: 1.4rem;
    padding: 0 3.5rem 0 2rem;

    @if $orientation == 'down' {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 320 512' transform='rotate(90)' fill='#{$color}' %3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E%0A");
    }

    @if $orientation == 'up' {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 320 512' transform='rotate(-90)' fill='#{$color}' %3E%3Cpath d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z'/%3E%3C/svg%3E%0A");
    }

}

@mixin search-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;

    .dot {
        width: .4rem;
        height: .4rem;
        border-radius: 50%;
        background-color: color(base, Black);
        margin: 0 .2rem;
        opacity: 0;
        animation: fadeIn 1s forwards;
        animation-iteration-count: infinite;

        &:nth-child(1) {
            animation-delay: 0.2s;
        }

        &:nth-child(2) {
            animation-delay: 0.4s;
        }

        &:nth-child(3) {
            animation-delay: 0.6s;
        }
    }
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: .8rem;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: color(Gray, Gray-300);
        border-radius: 1rem;
        border: .2rem solid color(base, White);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: color(Gray, Gray-400);
    }
}

@mixin slider-button($side) {
    position: absolute;
    top: calc(50% - 2rem);
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: color(base, White);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 4rem;
    z-index: 2;

    @include hover() {
        box-shadow: .1rem .1rem .2rem rgba(0, 0, 0, .2);
    }

    &.swiper-button-disabled {
        display: none;
    }

    @if $side == 'prev' {
        left: 1rem;
        right: auto;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24' xml:space='preserve'%3E%3Cpath d='m10.8 12 3.9 3.9c.18.18.28.42.28.7s-.09.52-.28.7c-.18.18-.42.28-.7.28s-.52-.09-.7-.28l-4.6-4.6c-.1-.1-.17-.21-.21-.32a1.209 1.209 0 0 1 0-.76c.04-.11.11-.22.21-.32l4.6-4.6c.18-.18.42-.28.7-.28s.52.09.7.28c.18.18.28.42.28.7 0 .28-.09.52-.28.7L10.8 12z' style='fill:%23626c77'/%3E%3C/svg%3E");
    }

    @if $side == 'next' {
        left: auto;
        right: 1rem;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23626c77' d='M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z'/%3E%3C/svg%3E");
    }
}

@mixin product-tile {
    @include transition();
    display: flex;
    flex-flow: column;
    flex: 1;
    position: relative;
    border: .1rem solid color(Gray, Gray-100);

    @include hover() {
        border-color: color(Gray, Gray-300);
    }

    > a {
        display: flex;
        flex-flow: column;
        flex: 1;
        padding: 1rem 1rem 0 1rem;
        background: color(base, White);
        position: relative;

        @include breakpoint(small only) {
            flex-flow: row;
            flex-wrap: wrap;
            padding: 1rem;
        }

        .action__label {
            position: absolute;
            top: 1.5rem;
            left: -.5rem;
            padding: .3rem .8rem;
            letter-spacing: -.02em;
            font-weight: 700;
            font-size: 1.2rem;
            color: color(base, White);
            border-radius: .3rem;
            z-index: 1;
            background: color(base, Primary);
        }

        .image__holder {
            display: flex;
            position: relative;
            aspect-ratio: 1;

            @include breakpoint(small only) {
                flex: 0 0 35%
            }

            > img {
                width: 100%;
                background: color(base, White);
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                object-fit: contain;
            }

            .cart-grid__overlay {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 1;
            }
        }

        .product_main {
            display: flex;
            flex: 1;
            flex-flow: column;
            padding: 1rem 0 0 0;

            @include breakpoint(small only) {
                flex: 0 0 65%;
                padding: 0 0 3.4rem 1rem;
            }

            h3 {
                font-size: 1.5rem;
                flex: 1;
                line-height: 1.9rem;
                word-wrap: break-word;
                font-weight: 700;
                color: color(base, DefaultFontColor);
                margin: 0;

                @include breakpoint(small only) {
                    flex: 0;
                }
            }

            p {
                color: color(Gray, Gray-600);
                font-size: 1.3rem;
                min-height: 2.4rem;
            }

            .youreko-placeholder {
                width: 100%;
            }

            .product_rating {
                width: 8.4rem;
                height: 1.5rem;
                background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nODQnIGhlaWdodD0nMTYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTggMS4xM2wyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw4IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMOCAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDQyIDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNDIgMS4xM3ptLTE3IDBsMi4wMiA0LjA5IDQuNTE0LjY1Ny0zLjI2NyAzLjE4NS43NzEgNC40OTZMMjUgMTEuNDM1bC00LjAzOCAyLjEyMy43NzEtNC40OTYtMy4yNjctMy4xODUgNC41MTUtLjY1NkwyNSAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDU5IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNTkgMS4xM3ptMTcgMGwyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw3NiAxMS40MzVsLTQuMDM4IDIuMTIzLjc3MS00LjQ5Ni0zLjI2Ny0zLjE4NSA0LjUxNS0uNjU2TDc2IDEuMTN6JyBzdHJva2U9JyNGRkM0MDAnIGZpbGw9J25vbmUnIGZpbGwtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
                display: block;
                margin: .6rem 0;

                &.--hide {
                    background: transparent;

                    @include breakpoint(small only) {
                        display: none;
                    }
                }

                .product_rating_fill {
                    height: 1.5rem;
                    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nODQnIGhlaWdodD0nMTYnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTggMS4xM2wyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw4IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMOCAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDQyIDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNDIgMS4xM3ptLTE3IDBsMi4wMiA0LjA5IDQuNTE0LjY1Ny0zLjI2NyAzLjE4NS43NzEgNC40OTZMMjUgMTEuNDM1bC00LjAzOCAyLjEyMy43NzEtNC40OTYtMy4yNjctMy4xODUgNC41MTUtLjY1NkwyNSAxLjEzem0zNCAwbDIuMDIgNC4wOSA0LjUxNC42NTctMy4yNjcgMy4xODUuNzcxIDQuNDk2TDU5IDExLjQzNWwtNC4wMzggMi4xMjMuNzcxLTQuNDk2LTMuMjY3LTMuMTg1IDQuNTE1LS42NTZMNTkgMS4xM3ptMTcgMGwyLjAyIDQuMDkgNC41MTQuNjU3LTMuMjY3IDMuMTg1Ljc3MSA0LjQ5Nkw3NiAxMS40MzVsLTQuMDM4IDIuMTIzLjc3MS00LjQ5Ni0zLjI2Ny0zLjE4NSA0LjUxNS0uNjU2TDc2IDEuMTN6JyBmaWxsPScjRkZDNDAwJyBzdHJva2U9JyNGRkM0MDAnIGZpbGwtcnVsZT0nZXZlbm9kZCcvPjwvc3ZnPg==);
                    display: block;
                }
            }

            ul {
                margin: 1rem 0;

                li {
                    color: color(Gray, Gray-600);
                }
            }
        }
    }

    .product_footer {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        padding: 0 1rem 1rem 1rem;

        @include breakpoint(small only) {
            width: 65%;
            position: absolute;
            right: 0;
            bottom: 0;
            border: 0;
        }

        .product_footer_left {
            .price__holder {
                display: flex;
                gap: .5rem;
                margin-top: .5rem;
                align-items: center;

                > span {
                    display: block;
                    font-size: 1.4rem;
                    color: color(Gray, Gray-400);

                    &.--old {
                        text-decoration: line-through;
                        font-size: 1.2rem;
                        color: color(Gray, Gray-600);
                        position: relative;
                        font-weight: 700;
                    }

                    &.--special {
                        color: color(base, Primary);
                        font-weight: 700;
                        font-size: 1.6rem;
                    }

                    &.price {
                        color: color(base, Primary);
                        font-weight: 700;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                    }
                }
            }

            ._compare-product {
                input[type="checkbox"] {
                    display: none;
                }

                label {
                    font-size: 1.1rem;
                    color: color(Gray, Gray-600);
                    display: flex;
                    align-items: center;
                    margin-top: .5rem;
                    cursor: pointer;

                    &::before {
                        content: "";
                        display: inline-block;
                        width: 1.5rem;
                        height: 1.5rem;
                        background: color(base, White);
                        border-radius: .3rem;
                        border: .1rem solid color(Gray, Gray-350);
                        margin-right: .5rem;
                    }
                }

                input[type="checkbox"]:checked + label {
                    &::before {
                        background-color: color(base, Primary);
                        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23ffffff' d='m382-354 339-339q12-12 28.5-12t28.5 12q12 12 12 28.5T778-636L410-268q-12 12-28 12t-28-12L182-440q-12-12-11.5-28.5T183-497q12-12 28.5-12t28.5 12l142 143Z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-size: 100%;
                        border-color: color(base, Primary);
                        background-position: center;

                    }
                }
            }
        }

        .product_footer_right {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            gap: 1rem;
            flex: 1;
            background: color(base, White);

            > button, .add-to-cart-button-container > button {
                @include transition();
                width: 3.4rem;
                height: 3.4rem;
                border-radius: .3rem;

                &.availability-button {
                    border: .1rem solid color(base, Secondary);
                    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nMjMnIGhlaWdodD0nMjMnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PGcgY2xpcC1wYXRoPSd1cmwoI2NsaXAwXzNfNjgpJz48cGF0aCBmaWxsPScjZmZmJyBkPSdNMCAwaDIyLjgzMXYyMi4zMjNIMHonLz48cGF0aCBkPSdNMy4yOTggMTkuNTMzSDIwLjA0TTMuMjk4IDguMDMzdi4zMzhjMCAuNzQuNDkxLS41MjMgMS4wMTQgMCAxLjAxNSAwIDEuNzc2LjI1NCAyLjc5IDEuMDE1Ljc2Mi41NyAxLjAxNiAxLjI2OCAxLjAxNiAxLjI2OC41MjMtLjUyMy43Ni0xLjg4Ljc2LTIuNjJtLTUuNTggMGg1LjU4bS01LjU4IDBIMjAuMDRtLTE2Ljc0MiAwbDEuODYtMy43MjJIMTguMThsMS44NiAzLjcyMW0tMTEuMTYxIDBjMCAuNzQtLjA3MiAyLjA0Mi43NiAyLjg3NWEyLjk1IDIuOTUgMCAwMDIuMDMuODQ2IDIuNzkgMi43OSAwIDAwMi43OS0yLjc5bS01LjU4LS45M2g1LjU4di45M20wIDBhMi43OSAyLjc5IDAgMDA1LjU4MSAwdi0uOTNtLTEuODYgMTEuNDk5di03Ljg2NG0tOS4zMDEgNy44NjR2LTMuNzJhMS44NiAxLjg2IDAgMDExLjg2LTEuODZoMS44NmExLjg2IDEuODYgMCAwMTEuODYgMS44NnYzLjcyJyBzdHJva2U9JyMwMDdERjEnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcvPjxwYXRoIGQ9J00xLjY5MyA5LjgyM2gwYTQuMDM4IDQuMDM4IDAgMDEyLjg3My0xLjIwNWMxLjA3NyAwIDIuMTEuNDMzIDIuODczIDEuMjA1YTQuMTUzIDQuMTUzIDAgMDExLjE5MyAyLjkxOGMwIDEuMDk2LS40MyAyLjE0NS0xLjE5MyAyLjkxOGwtMi44NzMgMi45MDktMi44NzMtMi45MWgwYTQuMTI4IDQuMTI4IDAgMDEtLjg4My0xLjMzNyA0LjE3MyA0LjE3MyAwIDAxLjg4My00LjQ5OHptMi44NzMgNC43MzljLjQ4IDAgLjk0LS4xOTQgMS4yNzgtLjUzNmExLjgzIDEuODMgMCAwMDAtMi41NyAxLjc5NiAxLjc5NiAwIDAwLTIuNTU2IDAgMS44MyAxLjgzIDAgMDAwIDIuNTdjLjMzOC4zNDIuNzk4LjUzNiAxLjI3OC41MzZ6JyBzdHJva2U9JyMwMDdERjEnLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSdjbGlwMF8zXzY4Jz48cGF0aCBmaWxsPScjZmZmJyBkPSdNMCAwaDIyLjgzMXYyMi4zMjNIMHonLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
                    background-size: 1.8rem;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-color: color(base, White);

                    @include hover() {
                        background-color: color(base, Secondary);
                        background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB3aWR0aD0nMjMnIGhlaWdodD0nMjMnIGZpbGw9J25vbmUnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PGcgY2xpcC1wYXRoPSd1cmwoI2NsaXAwXzNfNjgpJz48cGF0aCBmaWxsPScjMDA3REYxJyBkPSdNMCAwaDIyLjgzMXYyMi4zMjNIMHonLz48cGF0aCBkPSdNMy4yOTggMTkuNTMzSDIwLjA0TTMuMjk4IDguMDMzdi4zMzhjMCAuNzQuNDkxLS41MjMgMS4wMTQgMCAxLjAxNSAwIDEuNzc2LjI1NCAyLjc5IDEuMDE1Ljc2Mi41NyAxLjAxNiAxLjI2OCAxLjAxNiAxLjI2OC41MjMtLjUyMy43Ni0xLjg4Ljc2LTIuNjJtLTUuNTggMGg1LjU4bS01LjU4IDBIMjAuMDRtLTE2Ljc0MiAwbDEuODYtMy43MjJIMTguMThsMS44NiAzLjcyMW0tMTEuMTYxIDBjMCAuNzQtLjA3MiAyLjA0Mi43NiAyLjg3NWEyLjk1IDIuOTUgMCAwMDIuMDMuODQ2IDIuNzkgMi43OSAwIDAwMi43OS0yLjc5bS01LjU4LS45M2g1LjU4di45M20wIDBhMi43OSAyLjc5IDAgMDA1LjU4MSAwdi0uOTNtLTEuODYgMTEuNDk5di03Ljg2NG0tOS4zMDEgNy44NjR2LTMuNzJhMS44NiAxLjg2IDAgMDExLjg2LTEuODZoMS44NmExLjg2IDEuODYgMCAwMTEuODYgMS44NnYzLjcyJyBzdHJva2U9JyNGRkYnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcvPjxwYXRoIGQ9J00xLjY5MyA5LjgyM2gwYTQuMDM4IDQuMDM4IDAgMDEyLjg3My0xLjIwNWMxLjA3NyAwIDIuMTEuNDMzIDIuODczIDEuMjA1YTQuMTUzIDQuMTUzIDAgMDExLjE5MyAyLjkxOGMwIDEuMDk2LS40MyAyLjE0NS0xLjE5MyAyLjkxOGwtMi44NzMgMi45MDktMi44NzMtMi45MWgwYTQuMTI4IDQuMTI4IDAgMDEtLjg4My0xLjMzNyA0LjE3MyA0LjE3MyAwIDAxLjg4My00LjQ5OHptMi44NzMgNC43MzljLjQ4IDAgLjk0LS4xOTQgMS4yNzgtLjUzNmExLjgzIDEuODMgMCAwMDAtMi41NyAxLjc5NiAxLjc5NiAwIDAwLTIuNTU2IDAgMS44MyAxLjgzIDAgMDAwIDIuNTdjLjMzOC4zNDIuNzk4LjUzNiAxLjI3OC41MzZ6JyBzdHJva2U9JyNGRkYnLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSdjbGlwMF8zXzY4Jz48cGF0aCBmaWxsPScjMDA3REYxJyBkPSdNMCAwaDIyLjgzMXYyMi4zMjNIMHonLz48L2NsaXBQYXRoPjwvZGVmcz48L3N2Zz4=);
                    }
                }

                &.--add-to-cart {
                    padding: 0;

                    &::before {
                        margin: 0;
                    }
                }
            }
        }
    }
}
