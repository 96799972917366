.physical-stores__container {
    .physical-stores__container-message {
        background: color(alert-backgrounds, Neutral);
        border: 1px solid color(base, Secondary);
        color: color(base, Secondary);
        border-radius: 0.3rem;
        padding: 1rem 2rem;
        margin-bottom: 1rem;
    }

    .__form {
        .field__container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 0;
            input[type="text"] {
                width: calc(100% - 40px);
                border-radius: 0.5rem 0 0 0.5rem;
                border-right: 0;
            }
            button {
                width: 40px;
                border-radius: 0 0.5rem 0.5rem 0;
                background-color: color(base, Primary);
                border: 0;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                @include search-loading-button;
                &:hover {
                    background-color: color(base, PrimaryHover);
                }
                &:disabled {
                    background-color: color(Gray, Gray-300);
                    cursor: not-allowed;
                    &:hover {
                        background-color: color(Gray, Gray-300);
                    }
                }
            }
            .validator-err {
                flex: 0 0 100%;
            }
        }
    }

    .__results {
        display: flex;
        gap: 10px;
        flex-direction: column;
        position: relative;

        &.--loading {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(255, 255, 255, .5);
                z-index: 1;
            }
        }

        .__item {
            header {
                background: color(Gray, Gray-100);
                padding: 14px 35px 14px 14px;
                position: relative;
                cursor: pointer;
                border-radius: 5px;
                display: flex;
                //flex-direction: column;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                &:hover {
                    background: color(Gray, Gray-150);
                }

                &::after {
                    content: '';
                    width: 16px;
                    height: 16px;
                    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath fill='%231d1d1f' d='M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'/%3E%3C/svg%3E");
                    position: absolute;
                    right: 10px;
                    top: calc(50% - 8px);
                }

                &.--active {
                    border-radius: 5px 5px 0 0;
                    &::after {
                        transform: rotate(180deg);
                    }
                }

                h3 {
                    font-size: 2rem;
                    margin: 0;
                }

                address {
                    font-size: 1.2rem;
                    color: color(Gray, Gray-600);
                }

                .__in-stock,
                .__out-of-stock {
                    font-weight: 700;
                    border-radius: 20px;
                    padding: 3px 10px;
                    font-size: 12px;
                    color: color(base, Green);
                    display: inline-block;

                    &::before {
                        content: '';
                        width: 15px;
                        height: 15px;
                        display: inline-block;
                        margin: 0 6px -3px 0;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%2338b000' stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E%0A");
                    }
                }

                .__out-of-stock {
                    color: color(base, Red);

                    &::before {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%23D44a4a' stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                        background-size: cover;
                    }
                }
            }

            main {
                display: flex;
                gap: 15px;
                flex-direction: column;
                padding: 14px;
                border: 1px solid color(Gray, Gray-100);

                h4 {
                    margin: 0;
                    color: color(base, DefaultFontColor);
                }

                a.--icon {
                    color: color(base, DefaultFontColor);
                    &:hover {
                        text-decoration: underline;
                    }
                }

                table {
                    width: 100%;
                    line-height: 2.2rem;
                }
            }
        }
    }
}

.footer__container {
    .physical-stores__container {
        @include breakpoint(medium) {
            margin-right: 7rem;
        }

        form {
            margin-right: 0;
        }
        .field__container {
            position: relative;
            align-items: center;
            input {
                border: 0;
                border-radius: 0.5rem !important;
                padding-right: 5.5rem;
                width: 100% !important;
            }
            button {
                background-color: color(base, White);
                border-left: 0.1rem solid #e5e5e5;
                position: absolute;
                right: 0;
                width: 40px;
                height: 4rem;
                top: 0;
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke='%231d1d1f' stroke-linecap='round' stroke-linejoin='round' d='m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z'/%3E%3C/svg%3E");
                background-size: 1.6rem;
                background-repeat: no-repeat;
                background-position: center;

                &::before {
                    display: none;
                }

                &:hover {
                    background-color: color(base, White);
                }
            }
            .validator-err {
                color: color(base, White);
                font-size: 1.2rem;
                padding: 0;
            }
        }
    }
}
