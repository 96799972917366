
$sl-overlay-background: color(base, Black);

.sl-wrapper {
    .sl-close {
        @include transition();
        background-color: color(base, White);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='44' height='38' viewBox='0 0 44 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_16_7)'%3E%3Cpath d='M42.3904 17.4911C42.6443 17.9545 42.7773 18.4733 42.7773 19.0004C42.7773 19.5275 42.6443 20.0463 42.3904 20.5097L33.7168 36.343C33.4421 36.8444 33.0356 37.2629 32.5404 37.5546C32.0451 37.8462 31.4795 38.0001 30.9032 38H12.3136C11.7374 37.9999 11.1718 37.8458 10.6768 37.554C10.1817 37.2623 9.77538 36.8436 9.5008 36.3422L0.826403 20.5089C0.572517 20.0455 0.439545 19.5267 0.439545 18.9996C0.439545 18.4725 0.572517 17.9537 0.826403 17.4903L9.5008 1.65696C9.77549 1.15577 10.1818 0.737262 10.6769 0.445621C11.172 0.15398 11.7374 2.22046e-05 12.3136 9.03492e-09H30.904C31.4804 -4.30699e-05 32.0461 0.153966 32.5413 0.445759C33.0365 0.737551 33.443 1.15629 33.7176 1.65775L42.3904 17.4911Z' fill='white'/%3E%3Cpath d='M16 24L28 12M16 12L28 24' stroke='black' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_16_7'%3E%3Crect width='44' height='38' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        width: 4.4rem !important;
        height: 4.4rem !important;
        border-radius: 25rem;
        opacity: .8;
        top: 3.4rem;
        right: 3.4rem;

        @include hover() {
            opacity: 1;
        }
    }

    .sl-image {
        @include border-radius();
        position: relative;
        border: .1rem solid color(Gray, Gray-150);
    }

    .sl-navigation {
        button {
            @include transition();
            opacity: .8;
            background-color: color(base, White);
            background-repeat: no-repeat;
            background-position: center;
            width: 4.4rem !important;
            height: 4.4rem !important;
            border-radius: 25rem;

            &.sl-prev {
                background-image: url("data:image/svg+xml,%3Csvg width='44' height='38' viewBox='0 0 44 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_16_7)'%3E%3Cpath d='M1.6096 17.4911C1.35571 17.9545 1.22274 18.4733 1.22274 19.0004C1.22274 19.5275 1.35571 20.0463 1.6096 20.5097L10.2832 36.343C10.5579 36.8444 10.9644 37.2629 11.4596 37.5546C11.9549 37.8462 12.5205 38.0001 13.0968 38H31.6864C32.2626 37.9999 32.8282 37.8458 33.3232 37.554C33.8183 37.2623 34.2246 36.8436 34.4992 36.3422L43.1736 20.5089C43.4275 20.0455 43.5605 19.5267 43.5605 18.9996C43.5605 18.4725 43.4275 17.9537 43.1736 17.4903L34.4992 1.65696C34.2245 1.15577 33.8182 0.737262 33.3231 0.445621C32.828 0.15398 32.2626 2.22046e-05 31.6864 9.03492e-09H13.096C12.5196 -4.30699e-05 11.9539 0.153966 11.4587 0.445759C10.9635 0.737551 10.557 1.15629 10.2824 1.65775L1.6096 17.4911Z' fill='white'/%3E%3Cpath d='M25.75 10.5L18.25 18L25.75 25.5' stroke='black' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_16_7'%3E%3Crect width='44' height='38' fill='white' transform='matrix(-1 0 0 1 44 0)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }

            &.sl-next {
                background-image: url("data:image/svg+xml,%3Csvg width='44' height='38' viewBox='0 0 44 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_16_7)'%3E%3Cpath d='M42.3904 17.4911C42.6443 17.9545 42.7773 18.4733 42.7773 19.0004C42.7773 19.5275 42.6443 20.0463 42.3904 20.5097L33.7168 36.343C33.4421 36.8444 33.0356 37.2629 32.5404 37.5546C32.0451 37.8462 31.4795 38.0001 30.9032 38H12.3136C11.7374 37.9999 11.1718 37.8458 10.6768 37.554C10.1817 37.2623 9.77538 36.8436 9.5008 36.3422L0.826403 20.5089C0.572517 20.0455 0.439545 19.5267 0.439545 18.9996C0.439545 18.4725 0.572517 17.9537 0.826403 17.4903L9.5008 1.65696C9.77549 1.15577 10.1818 0.737262 10.6769 0.445621C11.172 0.15398 11.7374 2.22046e-05 12.3136 9.03492e-09H30.904C31.4804 -4.30699e-05 32.0461 0.153966 32.5413 0.445759C33.0365 0.737551 33.443 1.15629 33.7176 1.65775L42.3904 17.4911Z' fill='white'/%3E%3Cpath d='M18.25 10.5L25.75 18L18.25 25.5' stroke='black' stroke-width='2.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_16_7'%3E%3Crect width='44' height='38' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
            }

            @include hover() {
                opacity: 1;
            }
        }
    }
    + .sl-overlay {
        background-color: $sl-overlay-background;
    }
}
