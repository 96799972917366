.saldo__check {
    background-color: color(Gray, Gray-150);
    padding: 2rem;
    @include breakpoint(medium) {
        max-width: 50%;
    }

    .field__container {
        &.--button {
            margin: 6px;
        }
    }
    .saldo__result {
        display: flex;
        justify-content: center;
        border-top: 1px solid color(Gray, Gray-350);
        margin-top: 2rem;
        @include breakpoint(medium) {
            justify-content: left;
            border-top: 0;
        }

        .saldo__card {
            .saldo__card-title {
                display: block;
                font-weight: 700;
                margin: 2rem 0 1rem 0;
            }
            .saldo__card--container {
                position: relative;
                border-radius: 1rem;
                overflow: hidden;
                border: 1px solid color(Gray, Gray-350);
                > span {
                    position: absolute;
                    font-size: 5rem;
                    font-weight: 800;
                    color: color(base, Primary);
                    padding: 1.2rem;
                    text-shadow: 2px 2px 0 color(Gray, Gray-900);
                }
            }
        }
        .saldo__errors {
            > span {
                display: block;
                color: color(alert, Error);
                font-weight: 700;
                margin: 2rem 0 1rem 0;
            }
        }
    }
}
