.breadcrumbs {
    margin: 2rem   0;
    ol {
        display: flex;
        align-items: center;
        li {
            display: flex;
            @include font-size(13px);
            @include breakpoint(small only) {
                display: none;
                &:last-child, &:nth-last-child(2) {
                    display: flex;
                }
            }
            a {
                color: color(base, DefaultFontColor);
                @include font-size(14px);
            }
            span {
                @include font-size(14px);
            }
            &:after {
                display: none;
            }
            .breadcrumbs__separator {
                width: 25px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 -960 960 960' width='24'%3E%3Cpath fill='%23626c77' d='M504-480 348-636q-11-11-11-28t11-28q11-11 28-11t28 11l184 184q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13L404-268q-11 11-28 11t-28-11q-11-11-11-28t11-28l156-156Z'/%3E%3C/svg%3E");
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
            .current {
                color: color(base, Primary);
                font-weight: 700;
            }
        }
    }
}
