.modal__overlay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99992;
    justify-content: center;
    align-items: center;
    display: none;
    width: 100vw;
    height: 100%;

    &.--open {
        display: flex;
    }

    .__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.4);

    }

    .modal__wrapper {
        z-index: 1;
        background: color(base, White);
        box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, .5);
        border-radius: 5px;
        max-width: 90vw;
        max-height: 90vh;
        min-width: 20vw;
        @include breakpoint(large) {
            max-width: 125.8rem;
        }
        @include breakpoint(small only) {
            max-width: calc(100% - 20px);
            max-height: calc(100vh - 20px);
        }

        > img {
            max-height: 130px;
            object-fit: cover;
            width: 100%;
            border-radius: 5px 5px 0 0;
        }

        .modal-header {
            overflow: hidden;
            padding: 15px 20px 0;
            display: flex;

            h4 {
                color: color(base, Primary);
                @include font-size(16px);
                font-weight: 700;
            }

            span, button {
                cursor: pointer;
                display: block;
                width: 20px;
                height: 20px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
                margin-left: auto;
            }
        }

        .modal-content {
            padding: 10px 20px 20px;
            overflow-y: auto;
            height: fit-content;
            max-height: 75vh;

            .product__view-availability-product {
                padding: 10px 0;
                margin: 0 0 20px;
                border-bottom: 1px solid color(Gray, Gray-300);
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 20px;
                justify-content: space-between;
                @include breakpoint(small only) {
                    gap: 10px;
                }

                ._info {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    max-width: 450px;
                    @include breakpoint(small only) {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }

                    img {
                        width: 100px;
                        height: 100px;
                        @include breakpoint(small only) {
                            width: 75px;
                            height: 75px;
                        }
                    }

                    .bundle__images {
                        display: flex;
                        gap: 1rem;
                        align-items: center;

                        .bundle__image__holder {
                            display: flex;
                            align-items: center;
                            gap: 1rem;
                            position: relative;

                            img {
                                width: 7.5rem;
                                height: 7.5rem;
                            }

                            &:not(:last-child)::after {
                                content: '+';
                                font-size: 2rem;
                                color: color(base, Primary);
                                font-weight: 700;
                            }
                        }
                    }

                    ._product-details {
                        display: flex;
                        gap: 5px;
                        flex-direction: column;

                        ._name {
                            color: color(base, DefaultFontColor);
                            font-size: 1.6rem;
                            font-weight: 600;
                            @include breakpoint(small only) {
                                margin: 10px 0 0;
                                font-size: 1.6rem;
                            }
                        }

                        ._type {
                            color: color(Gray, Gray-600);
                            @include breakpoint(small only) {
                                font-size: 12px;
                            }
                        }

                        ._price {
                            font-weight: 700;
                            color: color(base, DefaultFontColor);
                        }
                    }
                }

                ._actions {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .--button {
                        width: 100%;
                    }

                    @include breakpoint(small only) {
                        flex: 0 0 100%;
                    }
                }
            }
        }
    }

    .product__tile {
        .modal__overlay {
            display: none;
        }
    }
}

a[data-open] {
    cursor: pointer;
}

.reveal {
    display: none;

    .modal__overlay {
        display: flex;
    }

    .modal__wrapper {
        overflow-y: auto;
        position: relative;

        .modal-content {
            padding: 20px;
        }

        .close-button {
            position: absolute;
            top: 10px;
            right: 20px;
            cursor: pointer;
            display: block;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;

            span {
                display: none;
            }
        }
    }
}

.product__tile + .modal__overlay {
    .modal__wrapper {
        width: 96rem;
    }
}
