header.--checkout {
    .header__container {
        background: color(base, White);
        padding: 1rem 0;
        text-align: center;

        .--logo {
            display: inline-flex;
            max-width: 18rem;
        }

        &.--checkout-top {
            background-color: color(base, Primary);
        }

        &:not(.--checkout-top) {
            padding-top: 2rem;
        }

        @include breakpoint(medium) {
            text-align: left;
        }

        .safe_shopping {
            color: color(base, Green);
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-top: 10px;
            @include breakpoint(medium) {
                justify-content: flex-end;
                margin: 0;
            }

            svg {
                width: 12px;
                fill: color(base, Green);
            }
        }
    }
}

.checkout-checkout {
    input {
        &[type='checkbox'],
        &[type='radio'] {
            & + label {
                &::before {
                    border: .1rem solid color(Gray, Gray-350);
                    width: 2.4rem;
                    height: 2.4rem;
                }
            }

            &:checked + label {
                &::before {
                    background-color: color(base, Secondary);
                }
            }
        }
    }

    .checkout-form {
        background: color(base, White);
        padding: 10px 10px 20px 10px;
        border-radius: .5rem;
        @include breakpoint(medium) {
            padding: 2rem 0 0;
        }

        .checkout-footer {
            .field__actions {
                flex-direction: row;
            }
        }

        .block__holder {
            form {
                > * {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }
    }

    .progress-bar {
        margin: 0 -1rem 0 0;
        display: flex;
        justify-content: space-between;

        &.grid-x {
            .cell {
                padding-left: 0;
                padding-right: 0;
                @include breakpoint(small only) {
                    display: none;
                    &._active {
                        display: block;
                        width: 70%;
                    }
                }
            }
        }

        &-item {
            display: flex;
            flex-direction: row;
            height: 100%;
            align-items: center;
            overflow: hidden;
            position: relative;

            @include breakpoint(medium) {
                @include font-size(14px);
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                width: auto;
                height: 3px;
                background-color: color(Gray, Gray-100);
                right: 1.6rem;
                left: 6.6rem
            }

            .progress-bar-indicator {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: color(Gray, Gray-100);
                width: 5rem;
                height: 5rem;
                border-radius: 25px;
                z-index: 1;

                svg {
                    fill: color(Gray, Gray-500);
                }
            }

        }

        &-title {
            font-weight: 600;
            @include font-size(14px);
            color: color(Gray, Gray-600);
            background-color: color(base, White);
            padding: 0 1.6rem;
            z-index: 1;
            display: none;
        }

        li {
            &._last {
                .progress-bar-item {
                    &:after {
                        display: none;
                    }
                }
            }

            &._active {
                .progress-bar-item {
                    .progress-bar-indicator {
                        background: color(base, Primary);
                        color: color(base, White);

                        svg {
                            fill: color(base, White);
                        }
                    }
                }

                .progress-bar-title {
                    display: block;
                    color: color(base, Primary);
                }
            }

            &._complete {
                .progress-bar-item {
                    .progress-bar-indicator {
                        background: rgba(56, 176, 0, 0.1);
                        color: color(base, White);
                        position: relative;

                        svg {
                            fill: color(base, Green);
                        }
                    }
                }
            }

            &._complete {
                cursor: pointer;

                .progress-bar-item:after {
                    background-position: -40px -50px;
                    @include breakpoint(medium) {
                        background-position: -123px 0;
                    }
                }
            }
        }
    }

    .checkout-error-container {
        padding: 0;
        margin: 2rem 0 1rem 0;

        .message {
            display: block;
        }
    }

    .checkout-step-container {
        margin-top: 2rem;
        display: block;

        h2 {
            color: color(base, DefaultFontColor);
            line-height: 36px;
        }

        .billing__address {
            margin-top: 25px;
        }

        .shipping__address,
        .billing__address {
            background-color: color(Gray, Gray-100);
            padding: 3rem;
            border-radius: .5rem;
        }

        .checkout_address-book {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .checkout_address-book__option {
                cursor: pointer;
                flex: 0 0 32%;
                margin: 0;
                padding: 20px;
                border-width: $default-border-width;
                border-style: solid;
                border-color: color(Gray, Gray-350);
                @include border-radius();
                background-color: color(base, White);

                input[type='radio'] {
                    display: none;
                }

                @include breakpoint(small only) {
                    flex: 0.5 0.5 45%;
                    padding: 10px;
                }

                &.--active {
                    border-color: color(base, Primary);
                }

                .checkout_address-book__content {
                    @include font-size(14px);
                    font-weight: normal;
                    color: color(base, DefaultFontColor);
                    line-height: 20px;

                    span {
                        display: block;
                    }
                }

                &.--new {
                    border-style: dashed;
                    text-align: center;

                    svg {
                        max-width: 40px;
                    }
                }
            }
        }

        .shipping-address-select,
        .billing-address-select {
            width: 100%;
        }

        .checkout__login__link {
            @include font-size(12px);
            margin-top: 1rem;
            color: color(base, Secondary);
            cursor: pointer;
        }
    }

    .shipping__methods {
        #omnistock-checkout-widget {
            .radio-container {
                background-color: color(Gray, Gray-100);
                box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
                border-radius: .5rem;
                margin: 0 0 2rem !important;
                padding: 3rem;

                .form-check-label {
                    position: relative;
                    padding-left: 5rem;
                }

                .label-head {
                    @include font-size(14px);
                    margin: 0;
                }

                .checkmark {
                    height: 2.4rem;
                    width: 2.4rem;
                    background-color: color(base, White);

                    &:after {
                        content: '';
                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="%23ffffff" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
                        background-repeat: no-repeat;
                        background-color: transparent;
                        width: 1.6rem;
                        height: 1.6rem;
                        position: absolute;
                        top: .4rem;
                        left: .5rem;
                    }
                }

                input:checked ~ .checkmark {
                    background-color: color(base, Secondary);
                }
            }

            .card {
                border: none;
                padding: 0;
                margin: 0;

                &:first-of-type {
                    margin-bottom: 3rem;
                }

                .card-body {
                    padding: 0;
                    >
                        .row {
                            @include breakpoint(medium) {
                                flex-wrap: nowrap;
                                gap: 3.2rem;
                            }
                        }

                }

                .row {
                    display: flex;
                    margin: 0;



                    > div {
                        background: color(Gray, Gray-100);
                        padding: 3rem;
                        border-radius: .5rem;
                        flex: auto;
                    }

                    .row {
                        > div {
                            padding: 0;

                            .address-card {
                                padding: 0;
                                border: none;
                                height: auto;
                                margin-top: 1rem;
                                border-bottom: 1px solid color(Gray, Gray-350);
                                padding-bottom: 1rem;
                                margin-bottom: 1rem !important;

                                &:has(.checkmark[style*="display"]) {

                                        border-bottom: 0;

                                }

                                .radio-container {
                                    box-shadow: none;
                                }

                            }
                        }
                    }
                }

                &:has(#inputMethodPickup:checked) {
                    + .card {
                        .row {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 0;

                            .row {
                                > div {
                                    @include breakpoint(medium) {
                                        &:nth-child(odd) {
                                            padding-right: 1rem;
                                        }

                                        &:nth-child(even) {
                                            padding-left: 1rem;
                                        }
                                    }

                                    .card-body {
                                        .col-3 {
                                            background-color: transparent;
                                            padding: 1rem 0 !important;

                                            button {
                                                background-color: color(base, White);
                                                border-radius: .5rem;
                                                margin-left: -.5rem;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .radio-container {
                            padding: 2rem !important;
                            background: color(base, White);
                            border-radius: .5rem !important;
                            box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);

                            label {
                                padding-left: 4.5rem !important;
                            }
                        }
                    }
                }

                .address-card {
                    padding: 0;
                    border: none;
                    height: auto;
                    margin-top: 2rem;
                }
            }

            .card-title {
                display: none;
            }

            .footer-text {
                display: none;
            }
        }
    }

    .payment__methods {
        &-list {
            padding: 0;
        }

        .--payment-method {
            background-color: color(Gray, Gray-100);
            padding: 3rem 0;
            border: 0;
            box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);

            label {
                padding: 0 3rem;
                position: relative;

                &:before {
                    height: 2.4rem !important;
                    width: 2.4rem !important;
                    border-radius: 3rem !important;
                    margin-right: 0 !important;
                }

                img {
                    position: absolute;
                    right: 1.5rem;
                }
            }
        }

        .cart__coupon {
            background-color: color(Gray, Gray-100);
            padding: 3rem;
            border-radius: .5rem;
            box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.05);
        }
    }

    .checkout-summary {
        display: block;
        margin-top: 3rem;

        .cell {
            display: flex;
            flex-direction: column;
            gap: 3.0rem;
            @include breakpoint(small only) {
                padding: 0 0.5rem !important;
            }
        }
        .grid-x {
            @include breakpoint(small only) {
                gap: 3.2rem;
            }
        }

        .summary-container {
            background-color: color(Gray, Gray-100);
            padding: 3rem;
            border-radius: .5rem;
            width: 100%;

            &:first-child {
                .summary-block {
                    .address {
                        padding-top: 2rem;
                    }
                }
            }

            h3 {
                color: color(base, Primary);
                @include font-size(20px);
            }

            .summary-block {
                margin: 0;

                .address {
                    padding: 0;
                }
            }
        }

        h3 {
            color: color(base, DefaultFontColor);
            @include font-size(13px);
            font-weight: 700;
            padding: 0;
        }

        .--edit {
            color: color(base, Secondary);
            @include font-size(12px);
            margin-top: 12px;
        }

        .personal-details,
        .summary-block {
            margin-top: 25px;

            > ul {
                margin: 0;
                background-color: color(Gray, Gray-100);

                &:last-of-type {
                    box-shadow: none;
                }

            }

            .cart {
                &__items,
                &__totals {
                    li {
                        display: flex;
                        flex-wrap: wrap;
                        line-height: 16px;
                        padding: 15px 0;
                        width: 100%;

                        &.--bundle {
                            .bundle-items {
                                margin: 0.4rem 0;
                                padding: 0 2rem;
                                background-color: RGBA(0, 0, 0, 0.03);
                                border-top: 0.2rem solid color(Gray, Gray-350);
                            }
                        }
                        > span {
                            margin-left: auto;

                            &.--title {
                                margin-left: 0;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                max-width: 70%;
                            }
                        }

                        &:not(:last-of-type) {
                            border-bottom: 1px solid color(Gray, Gray-350);
                        }
                        > div {
                            margin-top: 1rem;
                            width: 100%;
                            > strong {
                                font-size: 90%;
                            }
                            > div {
                                > strong {
                                    display: none;
                                }
                            }
                        }
                        .services-service {
                            font-size: 90%;
                            display: flex;
                            width: 100%;
                            justify-content: space-between;
                            gap: 2rem;
                            margin-top: .3rem;
                            .--title {
                                width: 100%;
                                max-width: 70%;
                            }
                            .--free {
                                font-weight: 700;
                                color: color(base, Green);
                            }
                        }
                    }
                }

                &__totals {
                    padding: 15px 0;
                    border-top: 1px solid color(Gray, Gray-350);

                    li {
                        padding: 5px 0;
                        border-bottom-width: 0;

                        &:not(:last-of-type) {
                            border-bottom: 0;
                        }

                        &.--grand {
                            font-weight: 700;
                        }

                        .--free {
                            font-weight: 700;
                            color: color(base, Green);
                        }
                    }
                }
            }

            .address {
                padding: 15px;

                li {
                    line-height: 20px;
                }
            }

        }
    }

    .checkout__login {
        p, span {
            @include font-size(12px);
            line-height: 18px;
            margin-bottom: 10px;
        }

        span {
            cursor: pointer;
            color: color(base, Secondary);
            padding: .6rem 0;
        }
    }

    .checkout-footer {
        .field__actions {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            gap: 20px;
            @include breakpoint(medium) {
                justify-content: space-between;
            }

            a {
                color: color(Gray, Gray-400);
                @include breakpoint(small only) {
                    order: 2;
                }

                &:hover {
                    color: color(Gray, Gray-500);
                }
            }

            .--button {
                @include breakpoint(small only) {
                    flex: 0 0 100%;
                    justify-content: center;
                    margin-left: 0;
                }
            }
        }
    }

    .--shake {
        animation: shake-input 0.7s .8 linear;
    }

    .checkout-success {
        .checkout-summary {
            margin-left: -2.6rem;
            margin-right: -2.6rem;

            .cell {
                flex-direction: column;
                gap: 1rem;
            }
        }

        &.checkout-account-form {
            padding: 0 2rem 4rem;
            margin: 0 -2rem;

            form {
                max-width: 100%;
            }

            input {
                &[disabled] {
                    background-color: color(Gray, Gray-50);

                }
            }

        }

        .summary-container {
            max-width: 100%;
        }

    }
}

.checkout-checkout_body {
    footer {
        text-align: center;
        line-height: 2em;
        color: color(Gray, Gray-600);

        a {
            color: color(base, Primary);

            &:hover {
                color: color(base, PrimaryHover);
                text-decoration: none;
            }
        }
    }
}
